import React, { createContext } from 'react'
import { Provider as MobXProvider } from 'mobx-react';
import { HistoryAdapter } from 'mobx-state-router'
import RootStore from './stores/RootStore'
import { createBrowserHistory } from 'history'

export const store = new RootStore()
const browserHistory = createBrowserHistory()// { basename: config.root }

// Observe history changes
const historyAdapter = new HistoryAdapter(store.routing, browserHistory)
historyAdapter.observeRouterStateChanges()



export const StoreContext = createContext(store);

const StoreProvider: React.FC = ({ children }) => (
    <MobXProvider store={store}>
        <StoreContext.Provider value={store}>
            {children}
        </StoreContext.Provider>
    </MobXProvider>
);

export default StoreProvider

export const useStore = () => {
    const store = React.useContext(StoreContext)
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('You have forgot to use StoreProvider, shame on you.')
    }
    return store
}