/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface DriverStatsSummaryDTO
 */
export interface DriverStatsSummaryDTO {
    /**
     * 
     * @type {number}
     * @memberof DriverStatsSummaryDTO
     */
    averageActivityCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverStatsSummaryDTO
     */
    totalActivityCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverStatsSummaryDTO
     */
    avgDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverStatsSummaryDTO
     */
    totalDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverStatsSummaryDTO
     */
    avgDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverStatsSummaryDTO
     */
    totalDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverStatsSummaryDTO
     */
    avgSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverStatsSummaryDTO
     */
    maxSpeed?: number;
}

export function DriverStatsSummaryDTOFromJSON(json: any): DriverStatsSummaryDTO {
    return DriverStatsSummaryDTOFromJSONTyped(json, false);
}

export function DriverStatsSummaryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverStatsSummaryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'averageActivityCount': !exists(json, 'averageActivityCount') ? undefined : json['averageActivityCount'],
        'totalActivityCount': !exists(json, 'totalActivityCount') ? undefined : json['totalActivityCount'],
        'avgDistance': !exists(json, 'avgDistance') ? undefined : json['avgDistance'],
        'totalDistance': !exists(json, 'totalDistance') ? undefined : json['totalDistance'],
        'avgDuration': !exists(json, 'avgDuration') ? undefined : json['avgDuration'],
        'totalDuration': !exists(json, 'totalDuration') ? undefined : json['totalDuration'],
        'avgSpeed': !exists(json, 'avgSpeed') ? undefined : json['avgSpeed'],
        'maxSpeed': !exists(json, 'maxSpeed') ? undefined : json['maxSpeed'],
    };
}

export function DriverStatsSummaryDTOToJSON(value?: DriverStatsSummaryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'averageActivityCount': value.averageActivityCount,
        'totalActivityCount': value.totalActivityCount,
        'avgDistance': value.avgDistance,
        'totalDistance': value.totalDistance,
        'avgDuration': value.avgDuration,
        'totalDuration': value.totalDuration,
        'avgSpeed': value.avgSpeed,
        'maxSpeed': value.maxSpeed,
    };
}


