/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface ActionCountDTO
 */
export interface ActionCountDTO {
    /**
     * 
     * @type {number}
     * @memberof ActionCountDTO
     */
    type?: number;
    /**
     * 
     * @type {number}
     * @memberof ActionCountDTO
     */
    count?: number;
}

export function ActionCountDTOFromJSON(json: any): ActionCountDTO {
    return ActionCountDTOFromJSONTyped(json, false);
}

export function ActionCountDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionCountDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function ActionCountDTOToJSON(value?: ActionCountDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'count': value.count,
    };
}


