/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * SDSRestApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://sds.devtakip.com/api".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *  
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration!: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name!: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface Action
 */
export interface Action {
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    driverName?: string;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    actionId?: number;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    unitId?: number;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    activityId?: number;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    startDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    endDateTime?: string;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    type?: number;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    threshold?: number;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    violationValue?: number;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    lon?: number;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    polyline?: string;
}

/**
 * 
 * @export
 * @interface ActionData
 */
export interface ActionData {
    /**
     * 
     * @type {number}
     * @memberof ActionData
     */
    readingId?: number;
    /**
     * 
     * @type {number}
     * @memberof ActionData
     */
    actionId?: number;
    /**
     * 
     * @type {number}
     * @memberof ActionData
     */
    activityId?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionData
     */
    readingTime?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionData
     */
    lateralGForce?: number;
    /**
     * 
     * @type {number}
     * @memberof ActionData
     */
    longitudinalGForce?: number;
    /**
     * 
     * @type {number}
     * @memberof ActionData
     */
    speed?: number;
}

/**
 * 
 * @export
 * @interface Actioncount
 */
export interface Actioncount {
    /**
     * 
     * @type {number}
     * @memberof Actioncount
     */
    type?: number;
    /**
     * 
     * @type {number}
     * @memberof Actioncount
     */
    count?: number;
}

/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    driverName?: string;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    test?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    totalGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    activityId?: number;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    plate?: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    startDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    endDateTime?: string;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    startId?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    endId?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    type?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    unitId?: number;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    startAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    endAddress?: string;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    distance?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    maxSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    avgSpeed?: number;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    idleTime?: string;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    safetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    ecoGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    suddenAccelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    suddenBrakeGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    hardTurnGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    speedingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    drowsinessGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    accelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    brakingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    idlingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    speedGrade?: number;
}

/**
 * 
 * @export
 * @interface ActivitySummary
 */
export interface ActivitySummary {
    /**
     * 
     * @type {string}
     * @memberof ActivitySummary
     */
    driverName?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummary
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummary
     */
    totalActivity?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummary
     */
    totalDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivitySummary
     */
    totalTime?: number;
}

/**
 * 
 * @export
 * @interface CalibrateResult
 */
export interface CalibrateResult {
    /**
     * 
     * @type {number}
     * @memberof CalibrateResult
     */
    activityId: number;
    /**
     * 
     * @type {string}
     * @memberof CalibrateResult
     */
    result: string;
}

/**
 * 
 * @export
 * @interface CheckTokenResponse
 */
export interface CheckTokenResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckTokenResponse
     */
    auth?: boolean;
}

/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    customerId: number;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    parentId?: number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    customerName?: string;
    /**
     * 
     * @type {Array<Department>}
     * @memberof Customer
     */
    departments?: Array<Department>;
}

/**
 * 
 * @export
 * @interface CustomerTree
 */
export interface CustomerTree {
    /**
     * 
     * @type {Array<Customer>}
     * @memberof CustomerTree
     */
    customers: Array<Customer>;
    /**
     * 
     * @type {Array<Driver>}
     * @memberof CustomerTree
     */
    drivers: Array<Driver>;
    /**
     * 
     * @type {Array<Department>}
     * @memberof CustomerTree
     */
    departments: Array<Department>;
}

/**
 * 
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    departmentId: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    name?: string;
    /**
     * 
     * @type {Array<Driver>}
     * @memberof Department
     */
    drivers: Array<Driver>;
}

/**
 * 
 * @export
 * @interface Driver
 */
export interface Driver {
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    customerId?: number;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    departmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    name: string;
}

/**
 * 
 * @export
 * @interface DriverLeagueEco
 */
export interface DriverLeagueEco {
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueEco
     */
    driverName?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueEco
     */
    driverId?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueEco
     */
    ecoGradeTrendRate?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueEco
     */
    ecoGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueEco
     */
    accelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueEco
     */
    brakingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueEco
     */
    idlingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueEco
     */
    speedGrade?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueEco
     */
    interval?: string;
}

/**
 * 
 * @export
 * @interface DriverLeagueGeneral
 */
export interface DriverLeagueGeneral {
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueGeneral
     */
    driverName?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueGeneral
     */
    driverId?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueGeneral
     */
    ecoGradeTrendRate?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueGeneral
     */
    totalDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueGeneral
     */
    totalDuration?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueGeneral
     */
    safetyGradeTrendRate?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueGeneral
     */
    totalGradeTrendRate?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueGeneral
     */
    ecoGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueGeneral
     */
    safetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueGeneral
     */
    totalGrade?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueGeneral
     */
    interval?: string;
}

/**
 * 
 * @export
 * @interface DriverLeagueSafety
 */
export interface DriverLeagueSafety {
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueSafety
     */
    driverName?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafety
     */
    driverId?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueSafety
     */
    safetyGradeTrendRate?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafety
     */
    safetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafety
     */
    suddenAccelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafety
     */
    suddenBrakeGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafety
     */
    speedingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafety
     */
    drowsinessGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafety
     */
    hardTurnGrade?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueSafety
     */
    interval?: string;
}

/**
 * 
 * @export
 * @interface DriverLeagueSafetyTest
 */
export interface DriverLeagueSafetyTest {
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueSafetyTest
     */
    driverName?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    driverId?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueSafetyTest
     */
    safetyGradeTrendRate?: string;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    safetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    suddenAccelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    suddenBrakeGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    speedingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    drowsinessGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    hardTurnGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    safetyGradeO?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    suddenAccelGradeO?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    suddenBrakeGradeO?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    speedingGradeO?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    drowsinessGradeO?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    hardTurnGradeO?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyTest
     */
    idlingGrade?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueSafetyTest
     */
    interval?: string;
}

/**
 * 
 * @export
 * @interface DriverReport
 */
export interface DriverReport {
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    driverId: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    totalDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgActivity?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    activityCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    maxSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    totalIdleTime?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgIdleTime?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgSafetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgEcoGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgTotalGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgSuddenAccelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgSuddenBrakeGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgHardTurnGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgSpeedingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgDrowsinessGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgAccelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgBrakingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgIdlingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgSpeedGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    totalTime?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReport
     */
    avgTime?: number;
}

/**
 * 
 * @export
 * @interface DriverTrend
 */
export interface DriverTrend {
    /**
     * 
     * @type {number}
     * @memberof DriverTrend
     */
    avgEcoGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverTrend
     */
    avgSafetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverTrend
     */
    avgTotalGrade?: number;
    /**
     * 
     * @type {Date}
     * @memberof DriverTrend
     */
    startDateTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof DriverTrend
     */
    driverId?: number;
}

/**
 * 
 * @export
 * @interface DriverTrendReport
 */
export interface DriverTrendReport {
    /**
     * 
     * @type {number}
     * @memberof DriverTrendReport
     */
    driverId: number;
    /**
     * 
     * @type {string}
     * @memberof DriverTrendReport
     */
    interval: string;
    /**
     * 
     * @type {number}
     * @memberof DriverTrendReport
     */
    ecoGradeTrendRate?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverTrendReport
     */
    safetyGradeTrendRate?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverTrendReport
     */
    totalGradeTrendRate?: number;
    /**
     * 
     * @type {Array<DriverTrend>}
     * @memberof DriverTrendReport
     */
    trendList?: Array<DriverTrend>;
}

/**
 * 
 * @export
 * @interface FleetReport
 */
export interface FleetReport {
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    totalDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgActivity?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    activityCount?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    maxSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    totalIdleTime?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgIdleTime?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgSafetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgEcoGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgTotalGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgSuddenAccelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgSuddenBrakeGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgHardTurnGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgSpeedingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgDrowsinessGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgAccelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgBrakingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgIdlingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgSpeedGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    totalTime?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetReport
     */
    avgTime?: number;
}

/**
 * 
 * @export
 * @interface Grade
 */
export interface Grade {
    /**
     * 
     * @type {number}
     * @memberof Grade
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof Grade
     */
    avgGrade?: number;
    /**
     * 
     * @type {string}
     * @memberof Grade
     */
    startDateTime?: string;
}

/**
 * 
 * @export
 * @interface GradeByDep
 */
export interface GradeByDep {
    /**
     * 
     * @type {number}
     * @memberof GradeByDep
     */
    customerId?: number;
    /**
     * 
     * @type {number}
     * @memberof GradeByDep
     */
    departmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof GradeByDep
     */
    avgGrade?: number;
}

/**
 * 
 * @export
 * @interface GradeReport
 */
export interface GradeReport {
    /**
     * 
     * @type {Array<number>}
     * @memberof GradeReport
     */
    driverId: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof GradeReport
     */
    startDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GradeReport
     */
    endDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof GradeReport
     */
    gradeType: string;
    /**
     * 
     * @type {number}
     * @memberof GradeReport
     */
    avgGrade?: number;
}

/**
 * 
 * @export
 * @interface GradeTrend
 */
export interface GradeTrend {
    /**
     * 
     * @type {number}
     * @memberof GradeTrend
     */
    avgGrade?: number;
    /**
     * 
     * @type {Date}
     * @memberof GradeTrend
     */
    startDateTime?: Date;
}

/**
 * 
 * @export
 * @interface GradeTrendReport
 */
export interface GradeTrendReport {
    /**
     * 
     * @type {Array<number>}
     * @memberof GradeTrendReport
     */
    driverId: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof GradeTrendReport
     */
    interval: string;
    /**
     * 
     * @type {string}
     * @memberof GradeTrendReport
     */
    gradeType: string;
    /**
     * 
     * @type {Array<GradeTrend>}
     * @memberof GradeTrendReport
     */
    gradeTrendList?: Array<GradeTrend>;
}

/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    lon?: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    dataDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    speed?: number;
}

/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof Report
     */
    reportScheduleId: number;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    format: string;
}

/**
 * 
 * @export
 * @interface ReportMailResult
 */
export interface ReportMailResult {
    /**
     * 
     * @type {string}
     * @memberof ReportMailResult
     */
    meesage: string;
    /**
     * 
     * @type {string}
     * @memberof ReportMailResult
     */
    result: string;
}

/**
 * 
 * @export
 * @interface ReportSchedule
 */
export interface ReportSchedule {
    /**
     * 
     * @type {number}
     * @memberof ReportSchedule
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ReportSchedule
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    customerName: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    recipients: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    interval: string;
    /**
     * 
     * @type {number}
     * @memberof ReportSchedule
     */
    date: number;
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    format: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportSchedule
     */
    departmentIds: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportSchedule
     */
    types: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportSchedule
     */
    customerIds: Array<number>;
}

/**
 * 
 * @export
 * @interface RiskDegreeReport
 */
export interface RiskDegreeReport {
    /**
     * 
     * @type {Array<number>}
     * @memberof RiskDegreeReport
     */
    driverId: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof RiskDegreeReport
     */
    startDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof RiskDegreeReport
     */
    gradeType: string;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeReport
     */
    totalActivity?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeReport
     */
    riskDegreeLow?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeReport
     */
    riskDegreeNone?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeReport
     */
    riskDegreeMedium?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeReport
     */
    riskDegreeHigh?: number;
}

/**
 * 
 * @export
 * @interface RiskDegreeTrend
 */
export interface RiskDegreeTrend {
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeTrend
     */
    totalActivity?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeTrend
     */
    riskDegreeLow?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeTrend
     */
    riskDegreeNone?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeTrend
     */
    riskDegreeMedium?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeTrend
     */
    riskDegreeHigh?: number;
    /**
     * 
     * @type {Date}
     * @memberof RiskDegreeTrend
     */
    startDateTime?: Date;
}

/**
 * 
 * @export
 * @interface RiskDegreeTrendReport
 */
export interface RiskDegreeTrendReport {
    /**
     * 
     * @type {Array<number>}
     * @memberof RiskDegreeTrendReport
     */
    driverId: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof RiskDegreeTrendReport
     */
    interval: string;
    /**
     * 
     * @type {string}
     * @memberof RiskDegreeTrendReport
     */
    gradeType: string;
    /**
     * 
     * @type {Array<RiskDegreeTrend>}
     * @memberof RiskDegreeTrendReport
     */
    riskDegreeTrendList?: Array<RiskDegreeTrend>;
}

/**
 * 
 * @export
 * @interface Speed
 */
export interface Speed {
    /**
     * 
     * @type {string}
     * @memberof Speed
     */
    gsensorDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Speed
     */
    activityId?: number;
    /**
     * 
     * @type {number}
     * @memberof Speed
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof Speed
     */
    y?: number;
    /**
     * 
     * @type {number}
     * @memberof Speed
     */
    z?: number;
    /**
     * 
     * @type {number}
     * @memberof Speed
     */
    speed?: number;
}

/**
 * 
 * @export
 * @interface Violation
 */
export interface Violation {
    /**
     * 
     * @type {string}
     * @memberof Violation
     */
    driverName?: string;
    /**
     * 
     * @type {number}
     * @memberof Violation
     */
    type?: number;
    /**
     * 
     * @type {number}
     * @memberof Violation
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof Violation
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof Violation
     */
    totalTime?: number;
}


/**
 * DriverApi - fetch parameter creator
 * @export
 */
export const DriverApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calibrate(activityId: number, options: any = {}): FetchArgs {
            // verify required parameter 'activityId' is not null or undefined
            if (activityId === null || activityId === undefined) {
                throw new RequiredError('activityId', 'Required parameter activityId was null or undefined when calling calibrate.');
            }
            const localVarPath = `/driver/v1/calibrate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (activityId !== undefined) {
                localVarFormParams.set('activityId', activityId as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkToken(token: string, options: any = {}): FetchArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token', 'Required parameter token was null or undefined when calling checkToken.');
            }
            const localVarPath = `/driver/v1/checkTokenByString`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportschedule(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteReportschedule.');
            }
            const localVarPath = `/driver/v1/reportschedule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} actionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionData(actionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'actionId' is not null or undefined
            if (actionId === null || actionId === undefined) {
                throw new RequiredError('actionId', 'Required parameter actionId was null or undefined when calling getActionData.');
            }
            const localVarPath = `/driver/v1/chartRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (actionId !== undefined) {
                localVarQueryParameter['actionId'] = actionId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActioncounts(driverId: Array<number>, start: string, end: string, options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getActioncounts.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getActioncounts.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling getActioncounts.');
            }
            const localVarPath = `/driver/v1/actioncounts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerTree(options: any = {}): FetchArgs {
            const localVarPath = `/driver/v1/getCustomerTree`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverLeagueEco(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getDriverLeagueEco.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getDriverLeagueEco.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling getDriverLeagueEco.');
            }
            // verify required parameter 'interval' is not null or undefined
            if (interval === null || interval === undefined) {
                throw new RequiredError('interval', 'Required parameter interval was null or undefined when calling getDriverLeagueEco.');
            }
            const localVarPath = `/driver/v1/driverLeagueEco`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverLeagueGeneral(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getDriverLeagueGeneral.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getDriverLeagueGeneral.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling getDriverLeagueGeneral.');
            }
            // verify required parameter 'interval' is not null or undefined
            if (interval === null || interval === undefined) {
                throw new RequiredError('interval', 'Required parameter interval was null or undefined when calling getDriverLeagueGeneral.');
            }
            const localVarPath = `/driver/v1/driverLeagueGeneral`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverLeagueSafety(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getDriverLeagueSafety.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getDriverLeagueSafety.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling getDriverLeagueSafety.');
            }
            // verify required parameter 'interval' is not null or undefined
            if (interval === null || interval === undefined) {
                throw new RequiredError('interval', 'Required parameter interval was null or undefined when calling getDriverLeagueSafety.');
            }
            const localVarPath = `/driver/v1/driverLeagueSafety`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverLeagueSafetyTest(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getDriverLeagueSafetyTest.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getDriverLeagueSafetyTest.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling getDriverLeagueSafetyTest.');
            }
            // verify required parameter 'interval' is not null or undefined
            if (interval === null || interval === undefined) {
                throw new RequiredError('interval', 'Required parameter interval was null or undefined when calling getDriverLeagueSafetyTest.');
            }
            const localVarPath = `/driver/v1/driverLeagueSafetyTest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverReport(driverId: Array<number>, start?: string, end?: string, options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getDriverReport.');
            }
            const localVarPath = `/driver/v1/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverTrendReport(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getDriverTrendReport.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getDriverTrendReport.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling getDriverTrendReport.');
            }
            // verify required parameter 'interval' is not null or undefined
            if (interval === null || interval === undefined) {
                throw new RequiredError('interval', 'Required parameter interval was null or undefined when calling getDriverTrendReport.');
            }
            const localVarPath = `/driver/v1/trend`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFleetReport(driverId: Array<number>, start?: string, end?: string, options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getFleetReport.');
            }
            const localVarPath = `/driver/v1/fleetReport`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFleetTrendReport(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getFleetTrendReport.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getFleetTrendReport.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling getFleetTrendReport.');
            }
            // verify required parameter 'interval' is not null or undefined
            if (interval === null || interval === undefined) {
                throw new RequiredError('interval', 'Required parameter interval was null or undefined when calling getFleetTrendReport.');
            }
            const localVarPath = `/driver/v1/fleetTrend`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {string} gradeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeBest(driverId: Array<number>, start: string, end: string, gradeType: string, options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getGradeBest.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getGradeBest.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling getGradeBest.');
            }
            // verify required parameter 'gradeType' is not null or undefined
            if (gradeType === null || gradeType === undefined) {
                throw new RequiredError('gradeType', 'Required parameter gradeType was null or undefined when calling getGradeBest.');
            }
            const localVarPath = `/driver/v1/gradeBest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (gradeType !== undefined) {
                localVarQueryParameter['gradeType'] = gradeType;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} gradeType 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeReport(driverId: Array<number>, gradeType: string, start?: string, end?: string, options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getGradeReport.');
            }
            // verify required parameter 'gradeType' is not null or undefined
            if (gradeType === null || gradeType === undefined) {
                throw new RequiredError('gradeType', 'Required parameter gradeType was null or undefined when calling getGradeReport.');
            }
            const localVarPath = `/driver/v1/grade`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (gradeType !== undefined) {
                localVarQueryParameter['gradeType'] = gradeType;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeTotal(driverId: Array<number>, start: string, end: string, type: string, options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getGradeTotal.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getGradeTotal.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling getGradeTotal.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type', 'Required parameter type was null or undefined when calling getGradeTotal.');
            }
            const localVarPath = `/driver/v1/gradeTotal`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeTotalByDep(start: string, end: string, type: string, options: any = {}): FetchArgs {
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getGradeTotalByDep.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling getGradeTotalByDep.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type', 'Required parameter type was null or undefined when calling getGradeTotalByDep.');
            }
            const localVarPath = `/driver/v1/gradeTotalByDep`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} gradeType 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeTrend(driverId: Array<number>, start: string, gradeType: string, interval: 'day' | 'week' | 'month', options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getGradeTrend.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getGradeTrend.');
            }
            // verify required parameter 'gradeType' is not null or undefined
            if (gradeType === null || gradeType === undefined) {
                throw new RequiredError('gradeType', 'Required parameter gradeType was null or undefined when calling getGradeTrend.');
            }
            // verify required parameter 'interval' is not null or undefined
            if (interval === null || interval === undefined) {
                throw new RequiredError('interval', 'Required parameter interval was null or undefined when calling getGradeTrend.');
            }
            const localVarPath = `/driver/v1/gradeTrend`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (gradeType !== undefined) {
                localVarQueryParameter['gradeType'] = gradeType;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {string} gradeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeWorst(driverId: Array<number>, start: string, end: string, gradeType: string, options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getGradeWorst.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getGradeWorst.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling getGradeWorst.');
            }
            // verify required parameter 'gradeType' is not null or undefined
            if (gradeType === null || gradeType === undefined) {
                throw new RequiredError('gradeType', 'Required parameter gradeType was null or undefined when calling getGradeWorst.');
            }
            const localVarPath = `/driver/v1/gradeWorst`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (gradeType !== undefined) {
                localVarQueryParameter['gradeType'] = gradeType;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {string} unit 
         * @param {string} email 
         * @param {string} reportType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportPDF(driverId: Array<number>, start: string, end: string, unit: string, email: string, reportType: string, options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getReportPDF.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getReportPDF.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling getReportPDF.');
            }
            // verify required parameter 'unit' is not null or undefined
            if (unit === null || unit === undefined) {
                throw new RequiredError('unit', 'Required parameter unit was null or undefined when calling getReportPDF.');
            }
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email', 'Required parameter email was null or undefined when calling getReportPDF.');
            }
            // verify required parameter 'reportType' is not null or undefined
            if (reportType === null || reportType === undefined) {
                throw new RequiredError('reportType', 'Required parameter reportType was null or undefined when calling getReportPDF.');
            }
            const localVarPath = `/driver/v1/reportPDF`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            if (start !== undefined) {
                localVarFormParams.set('start', start as any);
            }

            if (end !== undefined) {
                localVarFormParams.set('end', end as any);
            }

            if (unit !== undefined) {
                localVarFormParams.set('unit', unit as any);
            }

            if (email !== undefined) {
                localVarFormParams.set('email', email as any);
            }

            if (reportType !== undefined) {
                localVarFormParams.set('reportType', reportType as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportschedule(options: any = {}): FetchArgs {
            const localVarPath = `/driver/v1/reportschedule`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportscheduleReports(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getReportscheduleReports.');
            }
            const localVarPath = `/driver/v1/reportschedule/{id}/list`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} gradeType 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskDegreeReport(driverId: Array<number>, gradeType: string, start?: string, end?: string, options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getRiskDegreeReport.');
            }
            // verify required parameter 'gradeType' is not null or undefined
            if (gradeType === null || gradeType === undefined) {
                throw new RequiredError('gradeType', 'Required parameter gradeType was null or undefined when calling getRiskDegreeReport.');
            }
            const localVarPath = `/driver/v1/riskDegree`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (gradeType !== undefined) {
                localVarQueryParameter['gradeType'] = gradeType;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} gradeType 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskDegreeTrend(driverId: Array<number>, start: string, gradeType: string, interval: 'day' | 'week' | 'month', options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getRiskDegreeTrend.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling getRiskDegreeTrend.');
            }
            // verify required parameter 'gradeType' is not null or undefined
            if (gradeType === null || gradeType === undefined) {
                throw new RequiredError('gradeType', 'Required parameter gradeType was null or undefined when calling getRiskDegreeTrend.');
            }
            // verify required parameter 'interval' is not null or undefined
            if (interval === null || interval === undefined) {
                throw new RequiredError('interval', 'Required parameter interval was null or undefined when calling getRiskDegreeTrend.');
            }
            const localVarPath = `/driver/v1/riskDegreeTrend`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (gradeType !== undefined) {
                localVarQueryParameter['gradeType'] = gradeType;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRouteByActivityId(activityId: number, options: any = {}): FetchArgs {
            // verify required parameter 'activityId' is not null or undefined
            if (activityId === null || activityId === undefined) {
                throw new RequiredError('activityId', 'Required parameter activityId was null or undefined when calling getRouteByActivityId.');
            }
            const localVarPath = `/driver/v1/getRouteByActivityId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (activityId !== undefined) {
                localVarQueryParameter['activityId'] = activityId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeedByActivityId(activityId: number, options: any = {}): FetchArgs {
            // verify required parameter 'activityId' is not null or undefined
            if (activityId === null || activityId === undefined) {
                throw new RequiredError('activityId', 'Required parameter activityId was null or undefined when calling getSpeedByActivityId.');
            }
            const localVarPath = `/driver/v1/getSpeedByActivityId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (activityId !== undefined) {
                localVarQueryParameter['activityId'] = activityId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationReport(driverId: Array<number>, start?: string, end?: string, options: any = {}): FetchArgs {
            // verify required parameter 'driverId' is not null or undefined
            if (driverId === null || driverId === undefined) {
                throw new RequiredError('driverId', 'Required parameter driverId was null or undefined when calling getViolationReport.');
            }
            const localVarPath = `/driver/v1/violation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [driverId] 
         * @param {string} [startDateTime] 
         * @param {string} [endDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAction(driverId?: Array<number>, startDateTime?: string, endDateTime?: string, options: any = {}): FetchArgs {
            const localVarPath = `/driver/v1/listAction`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (startDateTime !== undefined) {
                localVarQueryParameter['startDateTime'] = startDateTime;
            }

            if (endDateTime !== undefined) {
                localVarQueryParameter['endDateTime'] = endDateTime;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActionByActivityId(activityId: number, options: any = {}): FetchArgs {
            // verify required parameter 'activityId' is not null or undefined
            if (activityId === null || activityId === undefined) {
                throw new RequiredError('activityId', 'Required parameter activityId was null or undefined when calling listActionByActivityId.');
            }
            const localVarPath = `/driver/v1/listActionByActivityId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (activityId !== undefined) {
                localVarQueryParameter['activityId'] = activityId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [unitId] 
         * @param {Array<number>} [driverId] 
         * @param {string} [startDateTime] 
         * @param {string} [endDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivity(unitId?: number, driverId?: Array<number>, startDateTime?: string, endDateTime?: string, options: any = {}): FetchArgs {
            const localVarPath = `/driver/v1/listActivity`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (unitId !== undefined) {
                localVarQueryParameter['unitId'] = unitId;
            }

            if (startDateTime !== undefined) {
                localVarQueryParameter['startDateTime'] = startDateTime;
            }

            if (endDateTime !== undefined) {
                localVarQueryParameter['endDateTime'] = endDateTime;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [unitId] 
         * @param {Array<number>} [driverId] 
         * @param {string} [startDateTime] 
         * @param {string} [endDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivitySummary(unitId?: number, driverId?: Array<number>, startDateTime?: string, endDateTime?: string, options: any = {}): FetchArgs {
            const localVarPath = `/driver/v1/listActivitySummary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (unitId !== undefined) {
                localVarQueryParameter['unitId'] = unitId;
            }

            if (startDateTime !== undefined) {
                localVarQueryParameter['startDateTime'] = startDateTime;
            }

            if (endDateTime !== undefined) {
                localVarQueryParameter['endDateTime'] = endDateTime;
            }

            if (driverId) {
                localVarFormParams.set('driverId', driverId.join(COLLECTION_FORMATS["csv"]));
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReportschedule(id: number, data: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling postReportschedule.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data', 'Required parameter data was null or undefined when calling postReportschedule.');
            }
            const localVarPath = `/driver/v1/reportschedule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (data !== undefined) {
                localVarFormParams.set('data', data as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDownload(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling reportDownload.');
            }
            const localVarPath = `/driver/v1/report/download/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportType 
         * @param {string} type 
         * @param {Array<number>} driverIds 
         * @param {string} start 
         * @param {string} end 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportExcel(reportType: string, type: string, driverIds: Array<number>, start: string, end: string, interval: string, options: any = {}): FetchArgs {
            // verify required parameter 'reportType' is not null or undefined
            if (reportType === null || reportType === undefined) {
                throw new RequiredError('reportType', 'Required parameter reportType was null or undefined when calling reportExcel.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type', 'Required parameter type was null or undefined when calling reportExcel.');
            }
            // verify required parameter 'driverIds' is not null or undefined
            if (driverIds === null || driverIds === undefined) {
                throw new RequiredError('driverIds', 'Required parameter driverIds was null or undefined when calling reportExcel.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling reportExcel.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling reportExcel.');
            }
            // verify required parameter 'interval' is not null or undefined
            if (interval === null || interval === undefined) {
                throw new RequiredError('interval', 'Required parameter interval was null or undefined when calling reportExcel.');
            }
            const localVarPath = `/driver/v1/report/{reportType}`
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (type !== undefined) {
                localVarFormParams.set('type', type as any);
            }

            if (driverIds) {
                localVarFormParams.set('driverIds', driverIds.join(COLLECTION_FORMATS["csv"]));
            }

            if (start !== undefined) {
                localVarFormParams.set('start', start as any);
            }

            if (end !== undefined) {
                localVarFormParams.set('end', end as any);
            }

            if (interval !== undefined) {
                localVarFormParams.set('interval', interval as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportType 
         * @param {string} type 
         * @param {Array<number>} driverIds 
         * @param {string} start 
         * @param {string} end 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLeague(reportType: string, type: string, driverIds: Array<number>, start: string, end: string, interval: string, options: any = {}): FetchArgs {
            // verify required parameter 'reportType' is not null or undefined
            if (reportType === null || reportType === undefined) {
                throw new RequiredError('reportType', 'Required parameter reportType was null or undefined when calling reportLeague.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type', 'Required parameter type was null or undefined when calling reportLeague.');
            }
            // verify required parameter 'driverIds' is not null or undefined
            if (driverIds === null || driverIds === undefined) {
                throw new RequiredError('driverIds', 'Required parameter driverIds was null or undefined when calling reportLeague.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling reportLeague.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling reportLeague.');
            }
            // verify required parameter 'interval' is not null or undefined
            if (interval === null || interval === undefined) {
                throw new RequiredError('interval', 'Required parameter interval was null or undefined when calling reportLeague.');
            }
            const localVarPath = `/driver/v1/report/league/{reportType}`
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (type !== undefined) {
                localVarFormParams.set('type', type as any);
            }

            if (driverIds) {
                localVarFormParams.set('driverIds', driverIds.join(COLLECTION_FORMATS["csv"]));
            }

            if (start !== undefined) {
                localVarFormParams.set('start', start as any);
            }

            if (end !== undefined) {
                localVarFormParams.set('end', end as any);
            }

            if (interval !== undefined) {
                localVarFormParams.set('interval', interval as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportType 
         * @param {string} type 
         * @param {string} mail 
         * @param {Array<number>} driverIds 
         * @param {string} start 
         * @param {string} end 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportmailExcel(reportType: string, type: string, mail: string, driverIds: Array<number>, start: string, end: string, interval: string, options: any = {}): FetchArgs {
            // verify required parameter 'reportType' is not null or undefined
            if (reportType === null || reportType === undefined) {
                throw new RequiredError('reportType', 'Required parameter reportType was null or undefined when calling reportmailExcel.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type', 'Required parameter type was null or undefined when calling reportmailExcel.');
            }
            // verify required parameter 'mail' is not null or undefined
            if (mail === null || mail === undefined) {
                throw new RequiredError('mail', 'Required parameter mail was null or undefined when calling reportmailExcel.');
            }
            // verify required parameter 'driverIds' is not null or undefined
            if (driverIds === null || driverIds === undefined) {
                throw new RequiredError('driverIds', 'Required parameter driverIds was null or undefined when calling reportmailExcel.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling reportmailExcel.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling reportmailExcel.');
            }
            // verify required parameter 'interval' is not null or undefined
            if (interval === null || interval === undefined) {
                throw new RequiredError('interval', 'Required parameter interval was null or undefined when calling reportmailExcel.');
            }
            const localVarPath = `/driver/v1/reportmail/{reportType}`
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (type !== undefined) {
                localVarFormParams.set('type', type as any);
            }

            if (mail !== undefined) {
                localVarFormParams.set('mail', mail as any);
            }

            if (driverIds) {
                localVarFormParams.set('driverIds', driverIds.join(COLLECTION_FORMATS["csv"]));
            }

            if (start !== undefined) {
                localVarFormParams.set('start', start as any);
            }

            if (end !== undefined) {
                localVarFormParams.set('end', end as any);
            }

            if (interval !== undefined) {
                localVarFormParams.set('interval', interval as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportType 
         * @param {string} type 
         * @param {string} mail 
         * @param {Array<number>} driverIds 
         * @param {string} start 
         * @param {string} end 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportmailLeague(reportType: string, type: string, mail: string, driverIds: Array<number>, start: string, end: string, interval: string, options: any = {}): FetchArgs {
            // verify required parameter 'reportType' is not null or undefined
            if (reportType === null || reportType === undefined) {
                throw new RequiredError('reportType', 'Required parameter reportType was null or undefined when calling reportmailLeague.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type', 'Required parameter type was null or undefined when calling reportmailLeague.');
            }
            // verify required parameter 'mail' is not null or undefined
            if (mail === null || mail === undefined) {
                throw new RequiredError('mail', 'Required parameter mail was null or undefined when calling reportmailLeague.');
            }
            // verify required parameter 'driverIds' is not null or undefined
            if (driverIds === null || driverIds === undefined) {
                throw new RequiredError('driverIds', 'Required parameter driverIds was null or undefined when calling reportmailLeague.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start', 'Required parameter start was null or undefined when calling reportmailLeague.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end', 'Required parameter end was null or undefined when calling reportmailLeague.');
            }
            // verify required parameter 'interval' is not null or undefined
            if (interval === null || interval === undefined) {
                throw new RequiredError('interval', 'Required parameter interval was null or undefined when calling reportmailLeague.');
            }
            const localVarPath = `/driver/v1/reportmail/league/{reportType}`
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication Oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("Oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (type !== undefined) {
                localVarFormParams.set('type', type as any);
            }

            if (mail !== undefined) {
                localVarFormParams.set('mail', mail as any);
            }

            if (driverIds) {
                localVarFormParams.set('driverIds', driverIds.join(COLLECTION_FORMATS["csv"]));
            }

            if (start !== undefined) {
                localVarFormParams.set('start', start as any);
            }

            if (end !== undefined) {
                localVarFormParams.set('end', end as any);
            }

            if (interval !== undefined) {
                localVarFormParams.set('interval', interval as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DriverApi - functional programming interface
 * @export
 */
export const DriverApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calibrate(activityId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CalibrateResult> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).calibrate(activityId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkToken(token: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).checkToken(token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportschedule(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).deleteReportschedule(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} actionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionData(actionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ActionData>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getActionData(actionId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActioncounts(driverId: Array<number>, start: string, end: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Actioncount>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getActioncounts(driverId, start, end, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerTree(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerTree> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getCustomerTree(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverLeagueEco(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DriverLeagueEco>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getDriverLeagueEco(driverId, start, end, interval, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverLeagueGeneral(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DriverLeagueGeneral>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getDriverLeagueGeneral(driverId, start, end, interval, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverLeagueSafety(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DriverLeagueSafety>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getDriverLeagueSafety(driverId, start, end, interval, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverLeagueSafetyTest(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DriverLeagueSafetyTest>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getDriverLeagueSafetyTest(driverId, start, end, interval, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverReport(driverId: Array<number>, start?: string, end?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DriverReport>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getDriverReport(driverId, start, end, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverTrendReport(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DriverTrendReport>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getDriverTrendReport(driverId, start, end, interval, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFleetReport(driverId: Array<number>, start?: string, end?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FleetReport>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getFleetReport(driverId, start, end, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFleetTrendReport(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DriverTrendReport> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getFleetTrendReport(driverId, start, end, interval, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {string} gradeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeBest(driverId: Array<number>, start: string, end: string, gradeType: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Grade>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getGradeBest(driverId, start, end, gradeType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} gradeType 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeReport(driverId: Array<number>, gradeType: string, start?: string, end?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GradeReport> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getGradeReport(driverId, gradeType, start, end, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeTotal(driverId: Array<number>, start: string, end: string, type: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Grade>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getGradeTotal(driverId, start, end, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeTotalByDep(start: string, end: string, type: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GradeByDep>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getGradeTotalByDep(start, end, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} gradeType 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeTrend(driverId: Array<number>, start: string, gradeType: string, interval: 'day' | 'week' | 'month', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GradeTrendReport> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getGradeTrend(driverId, start, gradeType, interval, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {string} gradeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeWorst(driverId: Array<number>, start: string, end: string, gradeType: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Grade>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getGradeWorst(driverId, start, end, gradeType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {string} unit 
         * @param {string} email 
         * @param {string} reportType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportPDF(driverId: Array<number>, start: string, end: string, unit: string, email: string, reportType: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getReportPDF(driverId, start, end, unit, email, reportType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportschedule(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ReportSchedule>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getReportschedule(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportscheduleReports(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Report>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getReportscheduleReports(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} gradeType 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskDegreeReport(driverId: Array<number>, gradeType: string, start?: string, end?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RiskDegreeReport> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getRiskDegreeReport(driverId, gradeType, start, end, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} gradeType 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskDegreeTrend(driverId: Array<number>, start: string, gradeType: string, interval: 'day' | 'week' | 'month', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RiskDegreeTrendReport> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getRiskDegreeTrend(driverId, start, gradeType, interval, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRouteByActivityId(activityId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Location>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getRouteByActivityId(activityId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeedByActivityId(activityId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Speed>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getSpeedByActivityId(activityId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationReport(driverId: Array<number>, start?: string, end?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Violation>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).getViolationReport(driverId, start, end, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} [driverId] 
         * @param {string} [startDateTime] 
         * @param {string} [endDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAction(driverId?: Array<number>, startDateTime?: string, endDateTime?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Action>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).listAction(driverId, startDateTime, endDateTime, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActionByActivityId(activityId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Action>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).listActionByActivityId(activityId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [unitId] 
         * @param {Array<number>} [driverId] 
         * @param {string} [startDateTime] 
         * @param {string} [endDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivity(unitId?: number, driverId?: Array<number>, startDateTime?: string, endDateTime?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Activity>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).listActivity(unitId, driverId, startDateTime, endDateTime, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [unitId] 
         * @param {Array<number>} [driverId] 
         * @param {string} [startDateTime] 
         * @param {string} [endDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivitySummary(unitId?: number, driverId?: Array<number>, startDateTime?: string, endDateTime?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ActivitySummary>> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).listActivitySummary(unitId, driverId, startDateTime, endDateTime, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReportschedule(id: number, data: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).postReportschedule(id, data, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDownload(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).reportDownload(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.blob();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} reportType 
         * @param {string} type 
         * @param {Array<number>} driverIds 
         * @param {string} start 
         * @param {string} end 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportExcel(reportType: string, type: string, driverIds: Array<number>, start: string, end: string, interval: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).reportExcel(reportType, type, driverIds, start, end, interval, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.blob();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} reportType 
         * @param {string} type 
         * @param {Array<number>} driverIds 
         * @param {string} start 
         * @param {string} end 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLeague(reportType: string, type: string, driverIds: Array<number>, start: string, end: string, interval: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).reportLeague(reportType, type, driverIds, start, end, interval, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.blob();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} reportType 
         * @param {string} type 
         * @param {string} mail 
         * @param {Array<number>} driverIds 
         * @param {string} start 
         * @param {string} end 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportmailExcel(reportType: string, type: string, mail: string, driverIds: Array<number>, start: string, end: string, interval: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CalibrateResult> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).reportmailExcel(reportType, type, mail, driverIds, start, end, interval, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} reportType 
         * @param {string} type 
         * @param {string} mail 
         * @param {Array<number>} driverIds 
         * @param {string} start 
         * @param {string} end 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportmailLeague(reportType: string, type: string, mail: string, driverIds: Array<number>, start: string, end: string, interval: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CalibrateResult> {
            const localVarFetchArgs = DriverApiFetchParamCreator(configuration).reportmailLeague(reportType, type, mail, driverIds, start, end, interval, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DriverApi - factory interface
 * @export
 */
export const DriverApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calibrate(activityId: number, options?: any) {
            return DriverApiFp(configuration).calibrate(activityId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkToken(token: string, options?: any) {
            return DriverApiFp(configuration).checkToken(token, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportschedule(id: number, options?: any) {
            return DriverApiFp(configuration).deleteReportschedule(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} actionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionData(actionId: string, options?: any) {
            return DriverApiFp(configuration).getActionData(actionId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActioncounts(driverId: Array<number>, start: string, end: string, options?: any) {
            return DriverApiFp(configuration).getActioncounts(driverId, start, end, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerTree(options?: any) {
            return DriverApiFp(configuration).getCustomerTree(options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverLeagueEco(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any) {
            return DriverApiFp(configuration).getDriverLeagueEco(driverId, start, end, interval, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverLeagueGeneral(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any) {
            return DriverApiFp(configuration).getDriverLeagueGeneral(driverId, start, end, interval, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverLeagueSafety(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any) {
            return DriverApiFp(configuration).getDriverLeagueSafety(driverId, start, end, interval, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverLeagueSafetyTest(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any) {
            return DriverApiFp(configuration).getDriverLeagueSafetyTest(driverId, start, end, interval, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverReport(driverId: Array<number>, start?: string, end?: string, options?: any) {
            return DriverApiFp(configuration).getDriverReport(driverId, start, end, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDriverTrendReport(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any) {
            return DriverApiFp(configuration).getDriverTrendReport(driverId, start, end, interval, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFleetReport(driverId: Array<number>, start?: string, end?: string, options?: any) {
            return DriverApiFp(configuration).getFleetReport(driverId, start, end, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFleetTrendReport(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any) {
            return DriverApiFp(configuration).getFleetTrendReport(driverId, start, end, interval, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {string} gradeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeBest(driverId: Array<number>, start: string, end: string, gradeType: string, options?: any) {
            return DriverApiFp(configuration).getGradeBest(driverId, start, end, gradeType, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} gradeType 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeReport(driverId: Array<number>, gradeType: string, start?: string, end?: string, options?: any) {
            return DriverApiFp(configuration).getGradeReport(driverId, gradeType, start, end, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeTotal(driverId: Array<number>, start: string, end: string, type: string, options?: any) {
            return DriverApiFp(configuration).getGradeTotal(driverId, start, end, type, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeTotalByDep(start: string, end: string, type: string, options?: any) {
            return DriverApiFp(configuration).getGradeTotalByDep(start, end, type, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} gradeType 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeTrend(driverId: Array<number>, start: string, gradeType: string, interval: 'day' | 'week' | 'month', options?: any) {
            return DriverApiFp(configuration).getGradeTrend(driverId, start, gradeType, interval, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {string} gradeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGradeWorst(driverId: Array<number>, start: string, end: string, gradeType: string, options?: any) {
            return DriverApiFp(configuration).getGradeWorst(driverId, start, end, gradeType, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} end 
         * @param {string} unit 
         * @param {string} email 
         * @param {string} reportType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportPDF(driverId: Array<number>, start: string, end: string, unit: string, email: string, reportType: string, options?: any) {
            return DriverApiFp(configuration).getReportPDF(driverId, start, end, unit, email, reportType, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportschedule(options?: any) {
            return DriverApiFp(configuration).getReportschedule(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportscheduleReports(id: number, options?: any) {
            return DriverApiFp(configuration).getReportscheduleReports(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} gradeType 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskDegreeReport(driverId: Array<number>, gradeType: string, start?: string, end?: string, options?: any) {
            return DriverApiFp(configuration).getRiskDegreeReport(driverId, gradeType, start, end, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} start 
         * @param {string} gradeType 
         * @param {'day' | 'week' | 'month'} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskDegreeTrend(driverId: Array<number>, start: string, gradeType: string, interval: 'day' | 'week' | 'month', options?: any) {
            return DriverApiFp(configuration).getRiskDegreeTrend(driverId, start, gradeType, interval, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRouteByActivityId(activityId: number, options?: any) {
            return DriverApiFp(configuration).getRouteByActivityId(activityId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeedByActivityId(activityId: number, options?: any) {
            return DriverApiFp(configuration).getSpeedByActivityId(activityId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} driverId 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViolationReport(driverId: Array<number>, start?: string, end?: string, options?: any) {
            return DriverApiFp(configuration).getViolationReport(driverId, start, end, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} [driverId] 
         * @param {string} [startDateTime] 
         * @param {string} [endDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAction(driverId?: Array<number>, startDateTime?: string, endDateTime?: string, options?: any) {
            return DriverApiFp(configuration).listAction(driverId, startDateTime, endDateTime, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActionByActivityId(activityId: number, options?: any) {
            return DriverApiFp(configuration).listActionByActivityId(activityId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [unitId] 
         * @param {Array<number>} [driverId] 
         * @param {string} [startDateTime] 
         * @param {string} [endDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivity(unitId?: number, driverId?: Array<number>, startDateTime?: string, endDateTime?: string, options?: any) {
            return DriverApiFp(configuration).listActivity(unitId, driverId, startDateTime, endDateTime, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [unitId] 
         * @param {Array<number>} [driverId] 
         * @param {string} [startDateTime] 
         * @param {string} [endDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivitySummary(unitId?: number, driverId?: Array<number>, startDateTime?: string, endDateTime?: string, options?: any) {
            return DriverApiFp(configuration).listActivitySummary(unitId, driverId, startDateTime, endDateTime, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReportschedule(id: number, data: string, options?: any) {
            return DriverApiFp(configuration).postReportschedule(id, data, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDownload(id: string, options?: any) {
            return DriverApiFp(configuration).reportDownload(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} reportType 
         * @param {string} type 
         * @param {Array<number>} driverIds 
         * @param {string} start 
         * @param {string} end 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportExcel(reportType: string, type: string, driverIds: Array<number>, start: string, end: string, interval: string, options?: any) {
            return DriverApiFp(configuration).reportExcel(reportType, type, driverIds, start, end, interval, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} reportType 
         * @param {string} type 
         * @param {Array<number>} driverIds 
         * @param {string} start 
         * @param {string} end 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLeague(reportType: string, type: string, driverIds: Array<number>, start: string, end: string, interval: string, options?: any) {
            return DriverApiFp(configuration).reportLeague(reportType, type, driverIds, start, end, interval, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} reportType 
         * @param {string} type 
         * @param {string} mail 
         * @param {Array<number>} driverIds 
         * @param {string} start 
         * @param {string} end 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportmailExcel(reportType: string, type: string, mail: string, driverIds: Array<number>, start: string, end: string, interval: string, options?: any) {
            return DriverApiFp(configuration).reportmailExcel(reportType, type, mail, driverIds, start, end, interval, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} reportType 
         * @param {string} type 
         * @param {string} mail 
         * @param {Array<number>} driverIds 
         * @param {string} start 
         * @param {string} end 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportmailLeague(reportType: string, type: string, mail: string, driverIds: Array<number>, start: string, end: string, interval: string, options?: any) {
            return DriverApiFp(configuration).reportmailLeague(reportType, type, mail, driverIds, start, end, interval, options)(fetch, basePath);
        },
    };
};

/**
 * DriverApi - object-oriented interface
 * @export
 * @class DriverApi
 * @extends {BaseAPI}
 */
export class DriverApi extends BaseAPI {
    /**
     * 
     * @param {number} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public calibrate(activityId: number, options?: any) {
        return DriverApiFp(this.configuration).calibrate(activityId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public checkToken(token: string, options?: any) {
        return DriverApiFp(this.configuration).checkToken(token, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public deleteReportschedule(id: number, options?: any) {
        return DriverApiFp(this.configuration).deleteReportschedule(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} actionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getActionData(actionId: string, options?: any) {
        return DriverApiFp(this.configuration).getActionData(actionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} start 
     * @param {string} end 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getActioncounts(driverId: Array<number>, start: string, end: string, options?: any) {
        return DriverApiFp(this.configuration).getActioncounts(driverId, start, end, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getCustomerTree(options?: any) {
        return DriverApiFp(this.configuration).getCustomerTree(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} start 
     * @param {string} end 
     * @param {'day' | 'week' | 'month'} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getDriverLeagueEco(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any) {
        return DriverApiFp(this.configuration).getDriverLeagueEco(driverId, start, end, interval, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} start 
     * @param {string} end 
     * @param {'day' | 'week' | 'month'} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getDriverLeagueGeneral(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any) {
        return DriverApiFp(this.configuration).getDriverLeagueGeneral(driverId, start, end, interval, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} start 
     * @param {string} end 
     * @param {'day' | 'week' | 'month'} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getDriverLeagueSafety(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any) {
        return DriverApiFp(this.configuration).getDriverLeagueSafety(driverId, start, end, interval, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} start 
     * @param {string} end 
     * @param {'day' | 'week' | 'month'} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getDriverLeagueSafetyTest(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any) {
        return DriverApiFp(this.configuration).getDriverLeagueSafetyTest(driverId, start, end, interval, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getDriverReport(driverId: Array<number>, start?: string, end?: string, options?: any) {
        return DriverApiFp(this.configuration).getDriverReport(driverId, start, end, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} start 
     * @param {string} end 
     * @param {'day' | 'week' | 'month'} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getDriverTrendReport(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any) {
        return DriverApiFp(this.configuration).getDriverTrendReport(driverId, start, end, interval, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getFleetReport(driverId: Array<number>, start?: string, end?: string, options?: any) {
        return DriverApiFp(this.configuration).getFleetReport(driverId, start, end, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} start 
     * @param {string} end 
     * @param {'day' | 'week' | 'month'} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getFleetTrendReport(driverId: Array<number>, start: string, end: string, interval: 'day' | 'week' | 'month', options?: any) {
        return DriverApiFp(this.configuration).getFleetTrendReport(driverId, start, end, interval, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} start 
     * @param {string} end 
     * @param {string} gradeType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getGradeBest(driverId: Array<number>, start: string, end: string, gradeType: string, options?: any) {
        return DriverApiFp(this.configuration).getGradeBest(driverId, start, end, gradeType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} gradeType 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getGradeReport(driverId: Array<number>, gradeType: string, start?: string, end?: string, options?: any) {
        return DriverApiFp(this.configuration).getGradeReport(driverId, gradeType, start, end, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} start 
     * @param {string} end 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getGradeTotal(driverId: Array<number>, start: string, end: string, type: string, options?: any) {
        return DriverApiFp(this.configuration).getGradeTotal(driverId, start, end, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} start 
     * @param {string} end 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getGradeTotalByDep(start: string, end: string, type: string, options?: any) {
        return DriverApiFp(this.configuration).getGradeTotalByDep(start, end, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} start 
     * @param {string} gradeType 
     * @param {'day' | 'week' | 'month'} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getGradeTrend(driverId: Array<number>, start: string, gradeType: string, interval: 'day' | 'week' | 'month', options?: any) {
        return DriverApiFp(this.configuration).getGradeTrend(driverId, start, gradeType, interval, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} start 
     * @param {string} end 
     * @param {string} gradeType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getGradeWorst(driverId: Array<number>, start: string, end: string, gradeType: string, options?: any) {
        return DriverApiFp(this.configuration).getGradeWorst(driverId, start, end, gradeType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} start 
     * @param {string} end 
     * @param {string} unit 
     * @param {string} email 
     * @param {string} reportType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getReportPDF(driverId: Array<number>, start: string, end: string, unit: string, email: string, reportType: string, options?: any) {
        return DriverApiFp(this.configuration).getReportPDF(driverId, start, end, unit, email, reportType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getReportschedule(options?: any) {
        return DriverApiFp(this.configuration).getReportschedule(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getReportscheduleReports(id: number, options?: any) {
        return DriverApiFp(this.configuration).getReportscheduleReports(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} gradeType 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getRiskDegreeReport(driverId: Array<number>, gradeType: string, start?: string, end?: string, options?: any) {
        return DriverApiFp(this.configuration).getRiskDegreeReport(driverId, gradeType, start, end, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} start 
     * @param {string} gradeType 
     * @param {'day' | 'week' | 'month'} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getRiskDegreeTrend(driverId: Array<number>, start: string, gradeType: string, interval: 'day' | 'week' | 'month', options?: any) {
        return DriverApiFp(this.configuration).getRiskDegreeTrend(driverId, start, gradeType, interval, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getRouteByActivityId(activityId: number, options?: any) {
        return DriverApiFp(this.configuration).getRouteByActivityId(activityId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getSpeedByActivityId(activityId: number, options?: any) {
        return DriverApiFp(this.configuration).getSpeedByActivityId(activityId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} driverId 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public getViolationReport(driverId: Array<number>, start?: string, end?: string, options?: any) {
        return DriverApiFp(this.configuration).getViolationReport(driverId, start, end, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} [driverId] 
     * @param {string} [startDateTime] 
     * @param {string} [endDateTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public listAction(driverId?: Array<number>, startDateTime?: string, endDateTime?: string, options?: any) {
        return DriverApiFp(this.configuration).listAction(driverId, startDateTime, endDateTime, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public listActionByActivityId(activityId: number, options?: any) {
        return DriverApiFp(this.configuration).listActionByActivityId(activityId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [unitId] 
     * @param {Array<number>} [driverId] 
     * @param {string} [startDateTime] 
     * @param {string} [endDateTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public listActivity(unitId?: number, driverId?: Array<number>, startDateTime?: string, endDateTime?: string, options?: any) {
        return DriverApiFp(this.configuration).listActivity(unitId, driverId, startDateTime, endDateTime, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [unitId] 
     * @param {Array<number>} [driverId] 
     * @param {string} [startDateTime] 
     * @param {string} [endDateTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public listActivitySummary(unitId?: number, driverId?: Array<number>, startDateTime?: string, endDateTime?: string, options?: any) {
        return DriverApiFp(this.configuration).listActivitySummary(unitId, driverId, startDateTime, endDateTime, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public postReportschedule(id: number, data: string, options?: any) {
        return DriverApiFp(this.configuration).postReportschedule(id, data, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public reportDownload(id: string, options?: any) {
        return DriverApiFp(this.configuration).reportDownload(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} reportType 
     * @param {string} type 
     * @param {Array<number>} driverIds 
     * @param {string} start 
     * @param {string} end 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public reportExcel(reportType: string, type: string, driverIds: Array<number>, start: string, end: string, interval: string, options?: any) {
        return DriverApiFp(this.configuration).reportExcel(reportType, type, driverIds, start, end, interval, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} reportType 
     * @param {string} type 
     * @param {Array<number>} driverIds 
     * @param {string} start 
     * @param {string} end 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public reportLeague(reportType: string, type: string, driverIds: Array<number>, start: string, end: string, interval: string, options?: any) {
        return DriverApiFp(this.configuration).reportLeague(reportType, type, driverIds, start, end, interval, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} reportType 
     * @param {string} type 
     * @param {string} mail 
     * @param {Array<number>} driverIds 
     * @param {string} start 
     * @param {string} end 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public reportmailExcel(reportType: string, type: string, mail: string, driverIds: Array<number>, start: string, end: string, interval: string, options?: any) {
        return DriverApiFp(this.configuration).reportmailExcel(reportType, type, mail, driverIds, start, end, interval, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} reportType 
     * @param {string} type 
     * @param {string} mail 
     * @param {Array<number>} driverIds 
     * @param {string} start 
     * @param {string} end 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public reportmailLeague(reportType: string, type: string, mail: string, driverIds: Array<number>, start: string, end: string, interval: string, options?: any) {
        return DriverApiFp(this.configuration).reportmailLeague(reportType, type, mail, driverIds, start, end, interval, options)(this.fetch, this.basePath);
    }

}

