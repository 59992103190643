import L from 'leaflet'
import "beautifymarker"
import "beautifymarker/leaflet-beautify-marker-icon.css"

// @ts-ignore
const iconA = new L.BeautifyIcon.icon({
    isAlphaNumericIcon: true,
    text: "A",
    iconShape: 'marker',
    borderColor: '#00ABDC',
    textColor: '#00ABDC'
})

// @ts-ignore
const iconB = new L.BeautifyIcon.icon({
    isAlphaNumericIcon: true,
    text: "B",
    iconShape: 'marker',
    borderColor: '#00ABDC',
    textColor: '#00ABDC'
})

// @ts-ignore
const iconAccel = new L.BeautifyIcon.icon({
    icon: "rocket",
    iconShape: 'marker',
    borderColor: 'rgba(248, 203, 0, 0.50)',
    textColor: '#f86c6b'

})

// @ts-ignore
const iconBrake = new L.BeautifyIcon.icon({
    icon: "hand-paper-o",
    iconShape: 'marker',
    borderColor: 'rgba(0, 0, 0, 0.50)',
    textColor: '#f86c6b'

})

// @ts-ignore
const iconHardLeft = new L.BeautifyIcon.icon({
    icon: "rotate-left",
    iconShape: 'marker',
    borderColor: 'rgba(0, 255, 255, 0.50)',
    textColor: '#f86c6b'

})

// @ts-ignore
const iconHardRight = new L.BeautifyIcon.icon({
    icon: "rotate-right",
    iconShape: 'marker',
    borderColor: 'rgba(0, 0, 255, 0.50)',
    textColor: '#f86c6b'

})

// @ts-ignore
const iconSpeeding = new L.BeautifyIcon.icon({
    icon: "exclamation-triangle",
    iconShape: 'marker',
    borderColor: 'rgba(255, 0, 0, 0.50)',
    textColor: '#f86c6b'

})

export { iconA, iconB, iconAccel, iconBrake, iconHardLeft, iconHardRight, iconSpeeding }