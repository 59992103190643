import moment from "../../utils/moment"
import {
    observable, action, computed,
} from 'mobx'

export class DatePickerData {
    name: string
    @observable end: moment.Moment
    @observable start: moment.Moment
    unit: moment.DurationInputArg2 | "custom"


    constructor(unit: moment.DurationInputArg2 | "custom",
        end: moment.Moment,
        start: moment.Moment,
        name?: string) {
        if (name) {
            this.name = name
        }
        this.start = start.clone()

        this.unit = unit

        this.end = end.clone()
    }


    @action next = () => {
        if (this.unit !== "custom") {
            this.start = moment(this.start).add(1, this.unit)
            this.end = moment(this.end).add(1, this.unit)
        }
    }

    @action previous = () => {
        if (this.unit !== "custom") {
            this.start = moment(this.start).subtract(1, this.unit)
            this.end = moment(this.end).subtract(1, this.unit)
        }
    }

    @computed get format() {
        if (this.unit) {
            if (this.unit === "day") {
                return this.end.format("ll")
            } else if (this.unit === "week") {
                return `${this.start.format("D")}-${this.end.format("D MMM YYYY")}`
            } else if (this.unit === "month") {
                return this.end.format("MMM YYYY")
            } else {
                return this.start.format("L") + "-" + this.end.format("L")
            }
        }
        else { return "" }
    }

    @computed get asJson() {
        return {
            unit: this.unit,
            start: this.start && this.start.toISOString(true),
            end: this.end && this.end.toISOString(true)
        }
    }

    @computed get asApi2() {
        return {
            startDate: this.start && this.start.utc(),
            endDate: this.end && this.end.utc(),
            interval: this.unit
        }
    }
}





export const initPresets = () => {
    return [
        new DatePickerData("day", moment().subtract(1, 'days').endOf('day'), moment().subtract(1, 'days').startOf('day'), "yesterday"),
        new DatePickerData("week", moment().endOf('week'), moment().startOf('week'), "thisWeek"),
        new DatePickerData("week", moment().subtract(1, 'week').endOf('week'), moment().subtract(1, 'week').startOf('week'), "lastWeek"),
        new DatePickerData("month", moment().endOf('month'), moment().startOf('month'), "thisMonth"),
        new DatePickerData("month", moment().subtract(1, 'month').endOf('month'), moment().subtract(1, 'month').startOf('month'), "lastMonth"),
        new DatePickerData("custom", moment(), moment(), "custom"),

    ]
}