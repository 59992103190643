import React, { Component } from 'react'
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    Col,
    Progress,
    Row,
} from 'reactstrap'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { IBaseProps } from "../../types"
import { reaction } from 'mobx'
import durationToString from "../../utils/durationToString"
import DoughnutGrades from "../../components/Chart/DoughnutGrades"
import BarGrades from "../../components/Chart/BarGrades"
import EmptyPrompt from "../../components/EmptyPrompt/EmptyPrompt"
import LaddaButton, { ZOOM_IN } from 'react-ladda'
import Spinner from '../../components/Spinner/Spinner'
import BestWorst from '../../components/Chart/BestWorst'
import ActionCounts from '../../components/Chart/ActionCounts'
import { ReportFormats } from '../../api2'
import Loading from '../../components/Loading'
import FleetChart from '../../components/Chart/FleetChart'


const formatDate = (date, interval: string) => {

    if (interval === "week") {
        return date.format("wo") + " hafta " + date.format("YY")
    } else if (interval === "month") {
        return date.format("MMM YYYY")
    } else {
        return date.format("ll")
    }

}



class Dashboard extends Component<IBaseProps> {

    reaction
    reaction2


    constructor(props: any) {
        super(props)
        const store = this.props.store!

        this.reaction = reaction(
            () => [store.ui.datePicker.asJson, store.ui.allDriverIds],
            () => {
                if (store.ui.allDriverIds && store.ui.allDriverIds.length > 0) {
                    store.report.getFleetReportList(store.ui.allDriverIds)
                    store.report.getFleetTrendReportList(store.ui.allDriverIds)

                }

            }, { fireImmediately: true, delay: 100 }
        )

        this.reaction2 = reaction(
            () => [store.ui.datePicker.asJson, store.ui.allDriverIds, store.report.fleetGraphRange],
            () => {
                if (store.ui.allDriverIds && store.ui.allDriverIds.length > 0) {
                    store.report.getFleetTGraph(store.ui.allDriverIds, store.report.fleetGraphRange)

                }

            }, { fireImmediately: true, delay: 100 }
        )
    }

    componentWillUnmount() {
        this.reaction()
        this.reaction2()

    }

    goToGradeReport = (reportKey) => {
        let routeName = ""

        switch (reportKey) {
            case "drowsinessGrade":
                routeName = "safetyGradeReport"
                break
            case "speedingGrade":
            case "suddenAccelGrade":
            case "suddenBrakeGrade":
            case "hardTurnGrade":
                routeName = "driverViolations"
                break

            case "accelGrade":
            case "brakingGrade":
            case "idlingGrade":
            case "speedGrade":
                routeName = "ecoGradeReport"
                break

            default:
                return
        }
        const store = this.props.store!
        let queryParams = Object.assign({ tab: reportKey }, store.routing.routerState.queryParams) as any
        store.ui.resetDriver()
        this.props.store!.routing.goTo(routeName, store.routing.routerState.params, queryParams)
    }

    renderTable() {
        return <FleetChart />
    }


    render() {
        const t = this.props.t!
        const store = this.props.store!
        const currentReport = store.report.fleetReportList!
        const currentTrendReport = store.report.fleetTrendReportList!
        if (!currentReport) {
            if (store.report.fleetReportListReady) {
                return <EmptyPrompt prompt="noDataInDateInterval" iconName="fa-table" />
            } else {
                return <div className="animated fadeIn screen-height-limit">
                    <Spinner />
                </div>

            }
        }

        const avgSafetyGrade = currentReport.avgSafetyGrade!
        const avgEcoGrade = currentReport.avgEcoGrade!
        const avgGrade = currentReport.avgTotalGrade!

        return (
            <div className="animated fadeIn screen-height-limit">
                <Row>
                    <Col xs="12" sm="6" lg="3">
                        <div className="report-box green">
                            <span className="title">Sürüş</span>
                            <ul>
                                <li>
                                    <strong>{currentReport.avgActivity}</strong>
                                    <span>ortalama (günlük)</span>
                                </li>
                                <li>
                                    <strong>{currentReport.activityCount}</strong>
                                    <span>toplam</span>
                                </li>
                            </ul>
                        </div>
                    </Col>

                    <Col xs="12" sm="6" lg="3">
                        <div className="report-box blue">
                            <span className="title">Mesafe<span className="small"> (km)</span></span>
                            <ul>
                                <li>
                                    <strong>{currentReport.avgDistance}</strong>
                                    <span>ortalama (sürüş)</span>
                                </li>
                                <li>
                                    <strong>{currentReport.totalDistance}</strong>
                                    <span>toplam</span>
                                </li>
                            </ul>
                        </div>
                    </Col>


                    <Col xs="12" sm="6" lg="3">
                        <div className="report-box danger">
                            <span className="title">Süre</span>
                            <ul>
                                <li>
                                    <strong>{durationToString(currentReport.avgTime! * 1000)}</strong>
                                    <span>ortalama (sürüş)</span>
                                </li>
                                <li>
                                    <strong>{durationToString(currentReport.totalTime! * 1000)}</strong>
                                    <span>toplam</span>
                                </li>
                            </ul>
                        </div>
                    </Col>

                    <Col xs="12" sm="6" lg="3">
                        <div className="report-box purple">
                            <span className="title">Hız<span className="small"> (km/sa)</span></span>
                            <ul>
                                <li>
                                    <strong>{currentReport.avgSpeed}</strong>
                                    <span>ortalama (sürüş)</span>
                                </li>
                                <li>
                                    <strong>{currentReport.maxSpeed}</strong>
                                    <span>max</span>
                                </li>
                            </ul>
                        </div>
                    </Col>



                </Row>

                <Row>


                    <Col>
                        <Card>
                            <CardHeader>
                                <span className="h4 mr-3">{store.ui.rootCustomer && store.ui.rootCustomer.customerName} - {store.ui.datePicker.format} - {store.ui.allDriverIds && store.ui.allDriverIds.length} Sürücü</span>


                                <LaddaButton
                                    loading={store.report.excelLoading}
                                    className="btn btn-secondary float-right mr-2"
                                    data-style={ZOOM_IN}
                                    data-spinner-color="#151b1e"
                                    onClick={() => {
                                        store.report.downloadReport2("fleetReport_2", ReportFormats.PDF, store.ui.allDriverIds, store.ui.datePicker.start, store.ui.datePicker.end, store.ui.datePicker.asJson.unit)
                                    }}><i className="fa fa-file-pdf-o" aria-hidden="true" /></LaddaButton>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <div className="d-flex flex-column justify-content-around align-items-center col-12 col-md-6 col-xl-4">
                                        <DoughnutGrades avgGrade={avgGrade} avgSafetyGrade={avgSafetyGrade} avgEcoGrade={avgEcoGrade} type="general" />
                                    </div>

                                    <div className="col-12 col-md-6 col-xl-4">
                                        <BarGrades
                                            avgDrowsinessGrade={currentReport.avgDrowsinessGrade!}
                                            avgSpeedingGrade={currentReport.avgSpeedingGrade!}
                                            avgSuddenAccelGrade={currentReport.avgSuddenAccelGrade!}
                                            avgSuddenBrakeGrade={currentReport.avgSuddenBrakeGrade!}
                                            avgHardTurnGrade={currentReport.avgHardTurnGrade!}
                                            avgAccelGrade={currentReport.avgAccelGrade!}
                                            avgBrakingGrade={currentReport.avgBrakingGrade!}
                                            avgIdlingGrade={currentReport.avgIdlingGrade!}
                                            avgSpeedGrade={currentReport.avgSpeedGrade!}
                                            goToGradeReport={this.goToGradeReport}
                                        />
                                    </div>

                                    <Col xs="12" md="6" xl="4">

                                        {currentTrendReport &&
                                            <React.Fragment>
                                                <ul className="horizontal-bars">
                                                    {currentTrendReport && currentTrendReport.trendList && currentTrendReport.trendList!.map((t, i) => <li key={i}>
                                                        <div className="title">
                                                            {formatDate(t.startDateTime, currentTrendReport!.interval!)}
                                                        </div>

                                                        <div className="bars">
                                                            <Progress className="progress" color="success" value={t.avgSafetyGrade! * 10}>{t.avgSafetyGrade!}</Progress>
                                                            <Progress className="progress" color="warning" value={t.avgEcoGrade! * 10}>{t.avgEcoGrade!}</Progress>
                                                        </div>
                                                    </li>)}



                                                    <li className="legend">
                                                        <Badge pill={true} color="success"></Badge>
                                                        <small>{" " + t("trendHeader", { name: t("safety") })}</small>
                                                        &nbsp; <Badge pill={true} color="warning"></Badge>
                                                        <small>{" " + t("trendHeader", { name: t("eco") })}</small>
                                                    </li>
                                                </ul>
                                                <hr className="mt-0" />
                                                <Row>
                                                    <Col sm="6">
                                                        <div className="callout callout-success">
                                                            <small className="text-muted">{t("trendHeader", { name: t("safetyGrade") })}</small>
                                                            <br />
                                                            <strong className="h4">{this.renderArrows(currentTrendReport.safetyGradeTrendRate)}{currentTrendReport.safetyGradeTrendRate}%</strong>
                                                        </div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <div className="callout callout-warning">
                                                            <small className="text-muted">{t("trendHeader", { name: t("ecoGrade") })}</small>
                                                            <br />
                                                            <strong className="h4">{this.renderArrows(currentTrendReport.ecoGradeTrendRate)}{currentTrendReport.ecoGradeTrendRate}%</strong>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        }

                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <span className="h4 mr-3">Trend</span>


                            </CardHeader>
                            <CardBody>
                                <Loading ready={store.report.fleetGraphReady}
                                    icon="fa-table"
                                    list={store.report.fleetGraph as any[]}
                                    emptyPromt="noDataInDateInterval" height="30vh">
                                    {this.renderTable()}
                                </Loading>


                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <BestWorst gradetype={"total"} type="best" allDrivers />

                    </Col>
                    <Col md={6}>
                        <BestWorst gradetype={"total"} type="worst" allDrivers />
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <ActionCounts allDrivers />

                    </Col>
                </Row>
            </div >
        )
    }

    renderArrows = (val) => {
        return <>
            {val < 0 && <i className="fa fa-angle-double-down text-red  mr-1" aria-hidden="true" />}
            {val > 0 && <i className="fa fa-angle-double-up text-blue  mr-1" aria-hidden="true" />}
            {val === 0 && <i className="fa fa-minus text-gray-200  mr-1" aria-hidden="true" />}
        </>

    }
}

export default inject("store")(withTranslation("main")(observer(Dashboard)))
