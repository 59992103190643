import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import EmptyPrompt from "../components/EmptyPrompt/EmptyPrompt"
import { IBaseProps } from '../types'

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

const checkSelectedDrivers = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    const CheckSelectedDriver = inject("store")(observer(class CheckSelectedDriver extends Component<P & IBaseProps> {
        render() {
            const store = (this.props as IBaseProps).store!
            return <>
                {store.ui.currentDriverIds.length > 0 ?
                    <WrappedComponent {...this.props} /> :
                    <EmptyPrompt prompt="pleaseChooseDrivers" iconName="fa-users" />
                }

            </>
        }
    }))
    // @ts-ignore
    CheckSelectedDriver.displayName = `CheckSelectedDriver(${getDisplayName(WrappedComponent)})`
    return CheckSelectedDriver
}

export default checkSelectedDrivers