import RootStore from "./stores/RootStore"
import { RouterState } from 'mobx-state-router'
import { action, toJS } from "mobx"
import moment from "./utils/moment"
import isequal from "lodash.isequal"
import CustomerModel from "./stores/Models/CustomerModel"
import { CustomerTree } from "./api"
import config from "./config"



const setdate = action("setdate", (ui, date) => {
    ui.datePicker.unit = date.unit
    ui.datePicker.end = moment(date.end)
    ui.datePicker.start = moment(date.start)
})
const updateDriverIds = action("updateDriverIds", (routerStore: { rootStore: RootStore }, query) => {
    try {
        const {
            rootStore: { ui }
        } = routerStore

        if (query) {
            if (query.d) {
                let d: number[] = []
                if (typeof query.d === "string") {
                    d = [Number.parseInt(query.d, 10)]
                }
                else {
                    d = query.d.map((id) => Number.parseInt(id, 10))
                }

                ui.setDrivers(d)
            }

            if (query.date) {
                let date = JSON.parse(query.date)
                if (date.unit && date.start && date.end) {
                    if (!isequal(date, toJS(ui.datePicker.asJson))) {
                        /*
                        console.log("date up")
                        console.log("date", date)
                        console.log("datepicker", toJS(ui.datePicker.asJson))
                        */
                        setdate(ui, date)

                    }
                }
            }
        }
    }
    catch (error) {
        console.log("error", error)
        Promise.reject()
    }
})

const checkForDepList = action("checkForDepList", (fromState, toState, routerStore: { rootStore: RootStore }) => {
    try {
        const { rootStore: { ui } } = routerStore
        if (!routerStore.rootStore.auth) {
            routerStore.rootStore.setRedirect(toState)
            return Promise.reject(new RouterState('login'))
        } else {
            if (ui.drivers.length <= 0) {
                ui.treeKey++
                ui.rootStore.api.getCustomerTree().then(action("setTreeInRoutes", (response: CustomerTree) => {
                    if (response) {

                        ui.drivers = []
                        ui.departments = []
                        ui.customers = []
                        ui.rootCustomer = new CustomerModel(response.customers[0], ui.rootStore)
                        ui.parseCustomer(response, ui.rootCustomer)
                        ui.treeReady = true
                    }
                })).catch(response => { routerStore.rootStore.check401(response) }).catch(response => console.error(response))
                return Promise.resolve()
            }
            return Promise.resolve()
        }
    }
    catch (error) {
        console.log("error", error)
        return Promise.reject()
    }
})


const routes = [
    {
        name: 'logout',
        pattern: '/logout',
        beforeEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            const { rootStore } = routerStore
            rootStore.logout()
            return Promise.resolve()
        },
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            return Promise.reject(new RouterState('login'))

        }
    },
    {
        name: 'login',
        pattern: '/',
        beforeEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            const { rootStore } = routerStore


            if (toState.queryParams && toState.queryParams.token) {
                return rootStore.login(toState.queryParams.token) as unknown as Promise<void>
            } else {
                try {
                    if (localStorage.token) {
                        return rootStore.login(localStorage.token) as unknown as Promise<void>
                    }
                } catch {
                    return Promise.resolve()
                }
            }

            return Promise.resolve()
        },
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            const {
                rootStore
            } = routerStore
            if (rootStore.auth) {
                if (rootStore.redirect) {
                    return Promise.reject(rootStore.redirect)
                }
                return Promise.reject(new RouterState(config.homepage))
            }
            return Promise.resolve()
        }
    },
    {
        name: 'driver',
        pattern: '/driver',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            const {
                rootStore: { report }
            } = routerStore
            updateDriverIds(routerStore, toState.queryParams)
            report.getReportList()
            return Promise.resolve()
        }
    },
    {
        name: 'driverViolations',
        pattern: '/driver/violations',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            updateDriverIds(routerStore, toState.queryParams)
            return Promise.resolve()
        }
    },
    {
        name: 'driverDetails',
        pattern: '/driver/details',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            updateDriverIds(routerStore, toState.queryParams)
            if (toState.queryParams && toState.queryParams.driverId && toState.queryParams.driverName) {
                const {
                    rootStore: { activity }
                } = routerStore
                activity.setFocusDriver({ driverId: toState.queryParams.driverId, driverName: toState.queryParams.driverName })
                activity.setFocusActivity(toState.queryParams.activityId)
            }
            return Promise.resolve()
        }
    },
    {
        name: 'leagueTest',
        pattern: '/driver/leaguetest',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            updateDriverIds(routerStore, toState.queryParams)
            return Promise.resolve()
        }
    },
    {
        name: 'league',
        pattern: '/driver/league',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            updateDriverIds(routerStore, toState.queryParams)
            return Promise.resolve()
        }
    },
    {
        name: 'riskLeague',
        pattern: '/driver/riskLeague',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            updateDriverIds(routerStore, toState.queryParams)
            return Promise.resolve()
        }
    },
    {
        name: 'worstdriverleague',
        pattern: '/driver/worstdriverleague',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            updateDriverIds(routerStore, toState.queryParams)
            return Promise.resolve()
        }
    },
    {
        name: 'ecoGradeReport',
        pattern: '/report/eco',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            updateDriverIds(routerStore, toState.queryParams)
            const {
                rootStore: { report }
            } = routerStore
            if (toState.queryParams && toState.queryParams.tab) {
                report.tab = toState.queryParams.tab
            } else {
                report.setTab("ecoGradeReport", "ecoGrade")
            }
            return Promise.resolve()
        }
    },
    {
        name: 'safetyGradeReport',
        pattern: '/report/safety',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            updateDriverIds(routerStore, toState.queryParams)
            const {
                rootStore: { report }
            } = routerStore
            if (toState.queryParams && toState.queryParams.tab) {
                report.tab = toState.queryParams.tab
            } else {
                report.setTab("safetyGradeReport", "safetyGrade")
            }
            return Promise.resolve()
        }
    },
    {
        name: 'tiredReport',
        pattern: '/report/tired',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            updateDriverIds(routerStore, toState.queryParams)
            const {
                rootStore: { report }
            } = routerStore
            if (toState.queryParams && toState.queryParams.tab) {
                report.tab = toState.queryParams.tab
            } else {
                report.setTab("tiredReport", "tiredTime")
            }
            return Promise.resolve()
        }
    },
    {
        name: 'fleet',
        pattern: '/fleet',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            const {
                rootStore: { report }
            } = routerStore
            updateDriverIds(routerStore, toState.queryParams)
            return Promise.resolve()
        }
    },
    {
        name: 'dashboard',
        pattern: '/dashboard',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            const {
                rootStore: { report }
            } = routerStore
            return Promise.resolve()
        }
    },
    {
        name: 'reportSettings',
        pattern: '/reportsettings',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            const {
                rootStore: { report }
            } = routerStore
            updateDriverIds(routerStore, toState.queryParams)
            return Promise.resolve()
        }
    },
    {
        name: 'summary',
        pattern: '/summary',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            const {
                rootStore: { report }
            } = routerStore
            return Promise.resolve()
        }
    },
    {
        name: 'bestdrivers',
        pattern: '/summary/bestdrivers',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            const {
                rootStore: { report }
            } = routerStore
            return Promise.resolve()
        }
    },
    {
        name: 'bestdepartments',
        pattern: '/summary/bestdepartments',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            const {
                rootStore: { report }
            } = routerStore
            return Promise.resolve()
        }
    },
    {
        name: 'violations',
        pattern: '/summary/violations',
        beforeEnter: checkForDepList,
        onEnter: (fromState, toState, routerStore: { rootStore: RootStore }) => {
            const {
                rootStore: { report }
            } = routerStore
            return Promise.resolve()
        }
    },
    {
        name: '404',
        pattern: '/404',
    }
]

export default routes