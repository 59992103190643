/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface DriverRiskLeagueGeneralDTO
 */
export interface DriverRiskLeagueGeneralDTO {
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueGeneralDTO
     */
    ecoGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueGeneralDTO
     */
    safetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueGeneralDTO
     */
    totalGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueGeneralDTO
     */
    distance?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueGeneralDTO
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueGeneralDTO
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueGeneralDTO
     */
    activityId?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverRiskLeagueGeneralDTO
     */
    ecoGradeTrendRate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DriverRiskLeagueGeneralDTO
     */
    totalGradeTrendRate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DriverRiskLeagueGeneralDTO
     */
    safetyGradeTrendRate?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DriverRiskLeagueGeneralDTO
     */
    startDateTime?: moment.Moment;
    /**
     * 
     * @type {string}
     * @memberof DriverRiskLeagueGeneralDTO
     */
    driverName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueGeneralDTO
     */
    totalDistance?: number;
}

export function DriverRiskLeagueGeneralDTOFromJSON(json: any): DriverRiskLeagueGeneralDTO {
    return DriverRiskLeagueGeneralDTOFromJSONTyped(json, false);
}

export function DriverRiskLeagueGeneralDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverRiskLeagueGeneralDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ecoGrade': !exists(json, 'ecoGrade') ? undefined : json['ecoGrade'],
        'safetyGrade': !exists(json, 'safetyGrade') ? undefined : json['safetyGrade'],
        'totalGrade': !exists(json, 'totalGrade') ? undefined : json['totalGrade'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'activityId': !exists(json, 'activityId') ? undefined : json['activityId'],
        'ecoGradeTrendRate': !exists(json, 'ecoGradeTrendRate') ? undefined : json['ecoGradeTrendRate'],
        'totalGradeTrendRate': !exists(json, 'totalGradeTrendRate') ? undefined : json['totalGradeTrendRate'],
        'safetyGradeTrendRate': !exists(json, 'safetyGradeTrendRate') ? undefined : json['safetyGradeTrendRate'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : moment(json['startDateTime']),
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'totalDistance': !exists(json, 'totalDistance') ? undefined : json['totalDistance'],
    };
}

export function DriverRiskLeagueGeneralDTOToJSON(value?: DriverRiskLeagueGeneralDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ecoGrade': value.ecoGrade,
        'safetyGrade': value.safetyGrade,
        'totalGrade': value.totalGrade,
        'distance': value.distance,
        'duration': value.duration,
        'driverId': value.driverId,
        'activityId': value.activityId,
        'ecoGradeTrendRate': value.ecoGradeTrendRate,
        'totalGradeTrendRate': value.totalGradeTrendRate,
        'safetyGradeTrendRate': value.safetyGradeTrendRate,
        'startDateTime': value.startDateTime === undefined ? undefined : (value.startDateTime.toISOString()),
        'driverName': value.driverName,
        'totalDistance': value.totalDistance,
    };
}


