/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface RiskDegree
 */
export interface RiskDegree {
    /**
     * 
     * @type {number}
     * @memberof RiskDegree
     */
    totalActivity?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegree
     */
    riskDegreeNone?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegree
     */
    riskDegreeLow?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegree
     */
    riskDegreeMedium?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegree
     */
    riskDegreeHigh?: number;
    /**
     * 
     * @type {Date}
     * @memberof RiskDegree
     */
    startDateTime?: moment.Moment;
}

export function RiskDegreeFromJSON(json: any): RiskDegree {
    return RiskDegreeFromJSONTyped(json, false);
}

export function RiskDegreeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskDegree {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalActivity': !exists(json, 'totalActivity') ? undefined : json['totalActivity'],
        'riskDegreeNone': !exists(json, 'riskDegreeNone') ? undefined : json['riskDegreeNone'],
        'riskDegreeLow': !exists(json, 'riskDegreeLow') ? undefined : json['riskDegreeLow'],
        'riskDegreeMedium': !exists(json, 'riskDegreeMedium') ? undefined : json['riskDegreeMedium'],
        'riskDegreeHigh': !exists(json, 'riskDegreeHigh') ? undefined : json['riskDegreeHigh'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : moment(json['startDateTime']),
    };
}

export function RiskDegreeToJSON(value?: RiskDegree | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalActivity': value.totalActivity,
        'riskDegreeNone': value.riskDegreeNone,
        'riskDegreeLow': value.riskDegreeLow,
        'riskDegreeMedium': value.riskDegreeMedium,
        'riskDegreeHigh': value.riskDegreeHigh,
        'startDateTime': value.startDateTime === undefined ? undefined : (value.startDateTime.toISOString()),
    };
}


