/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface ActivityDTO
 */
export interface ActivityDTO {
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    activityId?: number;
    /**
     * 
     * @type {Date}
     * @memberof ActivityDTO
     */
    startDateTime?: moment.Moment;
    /**
     * 
     * @type {Date}
     * @memberof ActivityDTO
     */
    endDateTime?: moment.Moment;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    startId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    endId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    type?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    driverId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    unitId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityDTO
     */
    startAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityDTO
     */
    endAddress?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    distance?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    maxSpeed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    avgSpeed?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ActivityDTO
     */
    idleTime?: moment.Moment;
    /**
     * 
     * @type {Date}
     * @memberof ActivityDTO
     */
    tiredTime?: moment.Moment;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    processed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    test?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    dataTooSparse?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    calibrationErrorTooHigh?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    safetyGrade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    ecoGrade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    suddenAccelGrade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    suddenBrakeGrade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    hardTurnGrade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    speedingGrade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    drowsinessGrade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    accelGrade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    brakingGrade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    idlingGrade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    speedGrade?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ActivityDTO
     */
    createdAt?: moment.Moment;
    /**
     * 
     * @type {Date}
     * @memberof ActivityDTO
     */
    updatedAt?: moment.Moment;
    /**
     * 
     * @type {number}
     * @memberof ActivityDTO
     */
    totalGrade?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityDTO
     */
    driverName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityDTO
     */
    plate?: string | null;
}

export function ActivityDTOFromJSON(json: any): ActivityDTO {
    return ActivityDTOFromJSONTyped(json, false);
}

export function ActivityDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activityId': !exists(json, 'activityId') ? undefined : json['activityId'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : moment(json['startDateTime']),
        'endDateTime': !exists(json, 'endDateTime') ? undefined : moment(json['endDateTime']),
        'startId': !exists(json, 'startId') ? undefined : json['startId'],
        'endId': !exists(json, 'endId') ? undefined : json['endId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'unitId': !exists(json, 'unitId') ? undefined : json['unitId'],
        'startAddress': !exists(json, 'startAddress') ? undefined : json['startAddress'],
        'endAddress': !exists(json, 'endAddress') ? undefined : json['endAddress'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'maxSpeed': !exists(json, 'maxSpeed') ? undefined : json['maxSpeed'],
        'avgSpeed': !exists(json, 'avgSpeed') ? undefined : json['avgSpeed'],
        'idleTime': !exists(json, 'idleTime') ? undefined : moment.utc(json['idleTime'], moment.HTML5_FMT.TIME_SECONDS).local(),
        'tiredTime': !exists(json, 'tiredTime') ? undefined : moment.utc(json['tiredTime'], moment.HTML5_FMT.TIME_SECONDS).local(),
        'processed': !exists(json, 'processed') ? undefined : json['processed'],
        'test': !exists(json, 'test') ? undefined : json['test'],
        'dataTooSparse': !exists(json, 'dataTooSparse') ? undefined : json['dataTooSparse'],
        'calibrationErrorTooHigh': !exists(json, 'calibrationErrorTooHigh') ? undefined : json['calibrationErrorTooHigh'],
        'safetyGrade': !exists(json, 'safetyGrade') ? undefined : json['safetyGrade'],
        'ecoGrade': !exists(json, 'ecoGrade') ? undefined : json['ecoGrade'],
        'suddenAccelGrade': !exists(json, 'suddenAccelGrade') ? undefined : json['suddenAccelGrade'],
        'suddenBrakeGrade': !exists(json, 'suddenBrakeGrade') ? undefined : json['suddenBrakeGrade'],
        'hardTurnGrade': !exists(json, 'hardTurnGrade') ? undefined : json['hardTurnGrade'],
        'speedingGrade': !exists(json, 'speedingGrade') ? undefined : json['speedingGrade'],
        'drowsinessGrade': !exists(json, 'drowsinessGrade') ? undefined : json['drowsinessGrade'],
        'accelGrade': !exists(json, 'accelGrade') ? undefined : json['accelGrade'],
        'brakingGrade': !exists(json, 'brakingGrade') ? undefined : json['brakingGrade'],
        'idlingGrade': !exists(json, 'idlingGrade') ? undefined : json['idlingGrade'],
        'speedGrade': !exists(json, 'speedGrade') ? undefined : json['speedGrade'],
        'createdAt': !exists(json, 'createdAt') ? undefined : moment(json['createdAt']),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : moment(json['updatedAt']),
        'totalGrade': !exists(json, 'totalGrade') ? undefined : json['totalGrade'],
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'plate': !exists(json, 'plate') ? undefined : json['plate'],
    };
}

export function ActivityDTOToJSON(value?: ActivityDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activityId': value.activityId,
        'startDateTime': value.startDateTime === undefined ? undefined : (value.startDateTime === null ? null : value.startDateTime.toISOString()),
        'endDateTime': value.endDateTime === undefined ? undefined : (value.endDateTime === null ? null : value.endDateTime.toISOString()),
        'startId': value.startId,
        'endId': value.endId,
        'type': value.type,
        'driverId': value.driverId,
        'unitId': value.unitId,
        'startAddress': value.startAddress,
        'endAddress': value.endAddress,
        'distance': value.distance,
        'maxSpeed': value.maxSpeed,
        'avgSpeed': value.avgSpeed,
        'idleTime': value.idleTime === undefined ? undefined : (value.idleTime === null ? null : value.idleTime.utc().format("HH:mm:00")),
        'tiredTime': value.tiredTime === undefined ? undefined : (value.tiredTime === null ? null : value.tiredTime.utc().format("HH:mm:00")),
        'processed': value.processed,
        'test': value.test,
        'dataTooSparse': value.dataTooSparse,
        'calibrationErrorTooHigh': value.calibrationErrorTooHigh,
        'safetyGrade': value.safetyGrade,
        'ecoGrade': value.ecoGrade,
        'suddenAccelGrade': value.suddenAccelGrade,
        'suddenBrakeGrade': value.suddenBrakeGrade,
        'hardTurnGrade': value.hardTurnGrade,
        'speedingGrade': value.speedingGrade,
        'drowsinessGrade': value.drowsinessGrade,
        'accelGrade': value.accelGrade,
        'brakingGrade': value.brakingGrade,
        'idlingGrade': value.idlingGrade,
        'speedGrade': value.speedGrade,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'totalGrade': value.totalGrade,
        'driverName': value.driverName,
        'plate': value.plate,
    };
}


