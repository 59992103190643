import React, { Component, useEffect, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { computed, reaction } from "mobx"
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { IBaseProps } from "../../types"
import { Charts, ChartContainer, ChartRow, YAxis, LineChart, TimeRangeMarker, styler, ScatterChart, Legend, BarChart } from "react-timeseries-charts"
import { TimeRange } from "pondjs"
import { format } from "d3-format"
import Logger from '../../utils/Logger'
import { timeFormatDefaultLocale } from "d3-time-format"
import locale from "d3-time-format/locale/tr-TR.json"
import moment from "../../utils/moment"
import CD from "react-container-dimensions"
import Loading from '../Loading'
import compose from '../../utils/compose'
import colors from '../../utils/colors'

const ContainerDimensions = observer(CD)
//@ts-ignore
timeFormatDefaultLocale(locale)

const BlockPageScroll = ({ children }) => {
    const scrollRef = useRef<any>(null)
    useEffect(() => {
        const scrollEl = scrollRef.current!
        scrollEl.addEventListener('wheel', stopScroll)
    }, [])
    const stopScroll = e => e.preventDefault()
    return (
        <div ref={scrollRef}>
            {children}
        </div>
    )
}

class CrossHairs extends React.Component<any> {
    render() {
        const { x, y } = this.props;
        const style = { pointerEvents: "none", stroke: "#ccc" } as any;
        if (x && y) {
            return (
                <g>
                    <line style={style} x1={0} y1={y} x2={this.props.width} y2={y} />
                    <line style={style} x1={x} y1={0} x2={x} y2={this.props.height} />
                </g>
            );
        } else {
            return <g />;
        }
    }
}

class FleetChart extends Component<IBaseProps & { height: number, width: number }, { timerange: any, tracker: any, x: any, y: any }> {



    styleLegend = [
        { key: "total", label: this.props.t!("total") },
        { key: "eco", label: this.props.t!("eco") },
        { key: "safety", label: this.props.t!("safety") },
        { key: "count", label: this.props.t!("count") },
    ]

    style = styler([
        { key: "total", color: colors.blue, width: 2 },
        { key: "eco", color: '#ffc107', width: 2 },
        { key: "safety", color: '#4dbd74', width: 2 },
        { key: "count", color: "steelblue", width: 1 },
    ])


    constructor(props: IBaseProps & { height: number, width: number }) {
        super(props)
        const store = this.props.store!
        this.state = {
            tracker: null,
            timerange: store.report.fleetGraphTS.range(),
            x: null,
            y: null
        };
    }

    componentWillUnmount() {
        const store = this.props.store!
        Logger.info("Chart unmount", "Chart")
        store.chart.reset()
    }


    handleTrackerChanged = tracker => {
        if (!tracker) {
            this.setState({ tracker, x: null, y: null });
        } else {
            this.setState({ tracker });
        }
    };

    handleTimeRangeChange = timerange => {
        this.setState({ timerange });
    };

    handleMouseMove = (x, y) => {
        this.setState({ x, y });
    };

    handleTimeSelect = (time: "1month" | "6month" | "1year") => {
        const store = this.props.store!

        store.report.fleetGraphRange = time;

    };

    render = () => {
        const t = this.props.t!
        const store = this.props.store!
        const f = format(",.1f");



        var timerange = this.state.timerange

        const croppedSeries = store.report.fleetGraphTS.crop(timerange);
        const croppedVolumeSeries = store.report.fleetGraphTSCounts.crop(timerange);

        let totalValue, ecoValue, safetyValue, countValue
        if (this.state.tracker) {
            const index = croppedSeries.bisect(this.state.tracker);
            const trackerEvent = croppedSeries.at(index);
            totalValue = `${f(trackerEvent.get("total"))}`;
            ecoValue = `${f(trackerEvent.get("eco"))}`;
            safetyValue = `${f(trackerEvent.get("safety"))}`;

            const index2 = croppedVolumeSeries.bisect(this.state.tracker);
            const trackerEvent2 = croppedVolumeSeries.at(index2);
            countValue = `${trackerEvent2.get("count")}`;

        }

        const trackerInfoValues = [
            { key: "total", label: this.props.t!("total"), value: totalValue },
            { key: "eco", label: this.props.t!("eco"), value: ecoValue },
            { key: "safety", label: this.props.t!("safety"), value: safetyValue },
            { key: "count", label: this.props.t!("count"), value: countValue },
        ]
        const selected = store.report.fleetGraphRange

        return (
            <React.Fragment>
                <BlockPageScroll>
                    <Row className="mt-2 chart-container" style={{ maxWidth: this.props.width - 30 }}>
                        <ContainerDimensions>
                            {({ height, width }) =>
                                <ChartContainer
                                    width={width - 30}
                                    timeRange={timerange}
                                    trackerShowTime
                                    enablePanZoom
                                    onTimeRangeChanged={this.handleTimeRangeChange}
                                    trackerPosition={this.state.tracker}
                                    minDuration={86400000}
                                    transition={86400000}
                                    onTrackerChanged={this.handleTrackerChanged}

                                >
                                    <ChartRow
                                        height={250}
                                        trackerInfoValues={trackerInfoValues}
                                        trackerInfoHeight={10 + trackerInfoValues.length * 16}
                                        trackerInfoWidth={140}
                                    >
                                        <Charts>
                                            <LineChart
                                                style={this.style}
                                                axis="y"
                                                columns={["total"]}
                                                series={croppedSeries}
                                                interpolation="curveBasis"
                                            />
                                            <LineChart
                                                axis="y"
                                                columns={["eco"]}
                                                series={croppedSeries}
                                                interpolation="curveBasis"
                                                style={this.style}
                                            />
                                            <LineChart
                                                axis="y"
                                                columns={["safety"]}
                                                series={croppedSeries}
                                                interpolation="curveBasis"
                                                style={this.style}
                                            />

                                        </Charts>
                                        <YAxis
                                            id="y"
                                            label="Puan"
                                            min={Math.min(croppedSeries.min("safety"), croppedSeries.min("eco"), croppedSeries.min("total"))}
                                            max={Math.max(croppedSeries.max("safety"), croppedSeries.max("eco"), croppedSeries.max("total"))}
                                            format=",.1f"
                                            width="60"
                                            type="linear"
                                        />
                                    </ChartRow>
                                    <ChartRow height="70" axisMargin={0}>
                                        <Charts>
                                            <BarChart
                                                axis="y"
                                                columns={["count"]}
                                                style={{ count: { normal: { stroke: "steelblue" } } }}

                                                series={croppedVolumeSeries}
                                            />
                                        </Charts>
                                        <YAxis
                                            id="y"
                                            label="Sürüş sayısı"
                                            min={croppedVolumeSeries.min("count")}
                                            max={croppedVolumeSeries.max("count")}
                                            width="60"
                                        />
                                    </ChartRow>

                                </ChartContainer>
                            }
                        </ContainerDimensions>

                        <Col sm="6" className="pt-2" style={{ fontSize: "80%" }}>
                            <Legend
                                type="swatch"
                                style={this.style}
                                categories={this.styleLegend}

                            />
                        </Col>
                        <Col sm="6" className="pt-2" style={{ fontSize: "80%" }}>
                            <Form>
                                <FormGroup tag="fieldset" >
                                    <Col>
                                        <FormGroup check inline>
                                            <Input checked={selected === "1month"} type="radio" name="radio2" id="radio2-option1" onChange={() => this.handleTimeSelect("1month")} />
                                            <Label check for="radio2-option1">
                                                1 Ay
                                        </Label>
                                        </FormGroup>

                                        <FormGroup check inline>
                                            <Input checked={selected === "6month"} type="radio" name="radio2" id="radio2-option2" onChange={() => this.handleTimeSelect("6month")} />
                                            <Label check for="radio2-option2">
                                                6 Ay
                                        </Label>
                                        </FormGroup>

                                        <FormGroup check inline>
                                            <Input checked={selected === "1year"} type="radio" name="radio2" id="radio2-option3" onChange={() => this.handleTimeSelect("1year")} />
                                            <Label check for="radio2-option3">
                                                1 yıl
                                        </Label>
                                        </FormGroup>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </BlockPageScroll>
            </React.Fragment>
        )
    }


}
export default compose(
    inject("store"),
    withTranslation("main"),
    observer,
)(FleetChart)