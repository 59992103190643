import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import { IBaseProps } from "../../types"

import {
    ModalHeader,
    ModalBody,
    Row
} from 'reactstrap'
import ActionList from '../../views/Driver/ActionList'
import Loading from '../Loading'
import Modal from '../Modal'

class ActionListModal extends Component<IBaseProps> {

    render() {
        const t = this.props.t!
        const store = this.props.store!
        const toggle = store.modal.toggleActionListModal
        return (
            <Modal
                isOpen={store.modal.isActionListModalVisible}
                toggle={toggle}
                size="xl"
            >
                <ModalHeader toggle={toggle}>{t("actionListModal")}</ModalHeader>
                <ModalBody>

                    {store.modal.isActionListModalVisible &&
                        <Loading ready={store.report.actionListReady} icon="fa-table" list={store.report.actionList} emptyPromt="noDataInActivity" height="70vh">
                            <Row>
                                <ActionList disableReaction driverId={store.modal.actionListModalDriverId ? [store.modal.actionListModalDriverId] : undefined} />
                            </Row>
                        </Loading>
                    }
                </ModalBody>
            </Modal >
        )
    }
}

export default inject("store")(withTranslation("main")(observer(ActionListModal)))