/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    Action,
    ActionFromJSON,
    ActionToJSON,
    ActionCountDTO,
    ActionCountDTOFromJSON,
    ActionCountDTOToJSON,
    ActionListDTO,
    ActionListDTOFromJSON,
    ActionListDTOToJSON,
    ActionWithDriverNameDTO,
    ActionWithDriverNameDTOFromJSON,
    ActionWithDriverNameDTOToJSON,
    Activity,
    ActivityFromJSON,
    ActivityToJSON,
    ActivityDTO,
    ActivityDTOFromJSON,
    ActivityDTOToJSON,
    BestDriversDTO,
    BestDriversDTOFromJSON,
    BestDriversDTOToJSON,
    DriverIdsDTO,
    DriverIdsDTOFromJSON,
    DriverIdsDTOToJSON,
    DriverLeagueEcoDTO,
    DriverLeagueEcoDTOFromJSON,
    DriverLeagueEcoDTOToJSON,
    DriverLeagueGeneralDTO,
    DriverLeagueGeneralDTOFromJSON,
    DriverLeagueGeneralDTOToJSON,
    DriverLeagueSafetyDTO,
    DriverLeagueSafetyDTOFromJSON,
    DriverLeagueSafetyDTOToJSON,
    DriverPerformanceResponseDTO,
    DriverPerformanceResponseDTOFromJSON,
    DriverPerformanceResponseDTOToJSON,
    DriverRankingDTO,
    DriverRankingDTOFromJSON,
    DriverRankingDTOToJSON,
    DriverReportDTO,
    DriverReportDTOFromJSON,
    DriverReportDTOToJSON,
    DriverRiskLeagueEcoDTO,
    DriverRiskLeagueEcoDTOFromJSON,
    DriverRiskLeagueEcoDTOToJSON,
    DriverRiskLeagueGeneralDTO,
    DriverRiskLeagueGeneralDTOFromJSON,
    DriverRiskLeagueGeneralDTOToJSON,
    DriverRiskLeagueSafetyDTO,
    DriverRiskLeagueSafetyDTOFromJSON,
    DriverRiskLeagueSafetyDTOToJSON,
    DriverStatsSummaryDTO,
    DriverStatsSummaryDTOFromJSON,
    DriverStatsSummaryDTOToJSON,
    DriverTrendResponseDTO,
    DriverTrendResponseDTOFromJSON,
    DriverTrendResponseDTOToJSON,
    FleetTrendDTO,
    FleetTrendDTOFromJSON,
    FleetTrendDTOToJSON,
    FleetTrendResponseDTO,
    FleetTrendResponseDTOFromJSON,
    FleetTrendResponseDTOToJSON,
    GetRouteByActivityIdDTO,
    GetRouteByActivityIdDTOFromJSON,
    GetRouteByActivityIdDTOToJSON,
    GetSpeedByActivityIdDTO,
    GetSpeedByActivityIdDTOFromJSON,
    GetSpeedByActivityIdDTOToJSON,
    GradeCategoryByDriverDTO,
    GradeCategoryByDriverDTOFromJSON,
    GradeCategoryByDriverDTOToJSON,
    GradeDTO,
    GradeDTOFromJSON,
    GradeDTOToJSON,
    GradeSummaryByCustomerDTO,
    GradeSummaryByCustomerDTOFromJSON,
    GradeSummaryByCustomerDTOToJSON,
    GradeSummaryByDepartmentDTO,
    GradeSummaryByDepartmentDTOFromJSON,
    GradeSummaryByDepartmentDTOToJSON,
    GradeSummaryByDriverDTO,
    GradeSummaryByDriverDTOFromJSON,
    GradeSummaryByDriverDTOToJSON,
    GradeTotalBadDriversDTO,
    GradeTotalBadDriversDTOFromJSON,
    GradeTotalBadDriversDTOToJSON,
    GradeTotalDTO,
    GradeTotalDTOFromJSON,
    GradeTotalDTOToJSON,
    ReportDTO,
    ReportDTOFromJSON,
    ReportDTOToJSON,
    ReportFleetDTO,
    ReportFleetDTOFromJSON,
    ReportFleetDTOToJSON,
    ReportFormats,
    ReportFormatsFromJSON,
    ReportFormatsToJSON,
    RiskDegreeDTO,
    RiskDegreeDTOFromJSON,
    RiskDegreeDTOToJSON,
    RiskDegreeTrendDTO,
    RiskDegreeTrendDTOFromJSON,
    RiskDegreeTrendDTOToJSON,
    UserDTO,
    UserDTOFromJSON,
    UserDTOToJSON,
    ViolationDTO,
    ViolationDTOFromJSON,
    ViolationDTOToJSON,
    WorstDriverLeagueGeneralDTO,
    WorstDriverLeagueGeneralDTOFromJSON,
    WorstDriverLeagueGeneralDTOToJSON,
} from '../models';

export interface ActionsCountsRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    driverIdsDTO?: DriverIdsDTO;
}

export interface ActivityDetectionReportRequest {
    format?: ReportFormats;
    customerIds?: Array<number> | null;
    date?: moment.Moment;
    statsDate?: moment.Moment;
}

export interface ActivityEvaluationReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    driverIdsDTO?: DriverIdsDTO;
}

export interface CheckTokenRequest {
    token?: string | null;
}

export interface DriverEvaluationReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    gradeType?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverLeagueAllReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverLeagueEcoRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverLeagueEcoReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverLeagueGeneralRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverLeagueGeneralReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverLeagueSafetyRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverLeagueSafetyReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverPerformanceReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverReportRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverReport0Request {
    format?: ReportFormats;
    driverId?: number;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
}

export interface DriverRiskLeagueEcoRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverRiskLeagueEcoReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverRiskLeagueGeneralRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverRiskLeagueGeneralReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverRiskLeagueSafetyRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverRiskLeagueSafetyReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface DriverTrendRequest {
    start?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface FleetGraphRequest {
    start?: moment.Moment;
    end?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface FleetReportRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    driverIdsDTO?: DriverIdsDTO;
}

export interface FleetReport0Request {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface FleetTrendRequest {
    start?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface GetActionRequest {
    id: number;
}

export interface GetActivityRequest {
    id: number;
}

export interface GetBestDriversRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    gradeType?: string | null;
    type?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface GetDriverPerformanceRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    driverIdsDTO?: DriverIdsDTO;
}

export interface GetDriverStatsSummaryRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    driverIdsDTO?: DriverIdsDTO;
}

export interface GetDriversRankingsRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    criteria?: string | null;
    type?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface GetRouteByActivityIdRequest {
    activityId?: string | null;
}

export interface GetSpeedByActivityIdRequest {
    activityId?: string | null;
}

export interface GradeCategoryByDriverRequest {
    customerId?: number | null;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    type?: string | null;
    departmentId?: number | null;
    withChildCustomers?: boolean;
}

export interface GradeHistogramRequest {
    customerId?: number | null;
    departmentId?: number | null;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    type?: string | null;
    bucketSize?: number | null;
}

export interface GradeSummaryByCustomerRequest {
    customerId?: number;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    withChildCustomers?: boolean;
}

export interface GradeSummaryByDepartmentRequest {
    departmentId?: number;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
}

export interface GradeSummaryByDriverRequest {
    driverId?: number;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
}

export interface GradeTotalRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    type?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface GradeTotalBadDriversRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    departmentId?: number | null;
    threshold?: number;
    customerId?: number | null;
}

export interface GradeTotalTop10Request {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    type?: string | null;
    departmentId?: number | null;
    customerId?: number | null;
}

export interface ListActionByActivityIdRequest {
    activityId?: number;
}

export interface ListActionByDriverIdByDateRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    driverIdsDTO?: DriverIdsDTO;
}

export interface ListActivityRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    driverIdsDTO?: DriverIdsDTO;
}

export interface ReportRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    driverIdsDTO?: DriverIdsDTO;
}

export interface RiskDegreeRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    gradeType?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface RiskDegreeTrendRequest {
    startDate?: moment.Moment;
    gradeType?: string | null;
    interval?: string | null;
    utcOffset?: number;
    driverIdsDTO?: DriverIdsDTO;
}

export interface TrendReportRequest {
    format?: ReportFormats;
    date?: moment.Moment;
    interval?: string | null;
    driverIdsDTO?: DriverIdsDTO;
}

export interface VehicleDocumentReportRequest {
    format?: ReportFormats;
}

export interface ViolationRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    driverIdsDTO?: DriverIdsDTO;
}

export interface ViolationDetailReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    driverIdsDTO?: DriverIdsDTO;
}

export interface ViolationReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    driverIdsDTO?: DriverIdsDTO;
}

export interface WorstDriverLeagueGeneralRequest {
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
}

export interface WorstDriverLeagueGeneralReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    interval?: string | null;
}

/**
 * no description
 */
export class SDSApiApi extends runtime.BaseAPI {

    /**
     */
    async actionsCountsRaw(requestParameters: ActionsCountsRequest): Promise<runtime.ApiResponse<Array<ActionCountDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Actions/ActionsCounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionCountDTOFromJSON));
    }

    /**
     */
    async actionsCounts(requestParameters: ActionsCountsRequest): Promise<Array<ActionCountDTO>> {
        const response = await this.actionsCountsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async activityDetectionReportRaw(requestParameters: ActivityDetectionReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.customerIds) {
            queryParameters['customerIds'] = requestParameters.customerIds;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        if (requestParameters.statsDate !== undefined) {
            queryParameters['statsDate'] = (requestParameters.statsDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/ActivityDetectionReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async activityDetectionReport(requestParameters: ActivityDetectionReportRequest): Promise<File> {
        const response = await this.activityDetectionReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async activityEvaluationReportRaw(requestParameters: ActivityEvaluationReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/ActivityEvaluationReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async activityEvaluationReport(requestParameters: ActivityEvaluationReportRequest): Promise<File> {
        const response = await this.activityEvaluationReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async checkTokenRaw(requestParameters: CheckTokenRequest): Promise<runtime.ApiResponse<UserDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Auth/CheckToken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     */
    async checkToken(requestParameters: CheckTokenRequest): Promise<UserDTO> {
        const response = await this.checkTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverEvaluationReportRaw(requestParameters: DriverEvaluationReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.gradeType !== undefined) {
            queryParameters['gradeType'] = requestParameters.gradeType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/DriverEvaluationReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async driverEvaluationReport(requestParameters: DriverEvaluationReportRequest): Promise<File> {
        const response = await this.driverEvaluationReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverLeagueAllReportRaw(requestParameters: DriverLeagueAllReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/DriverLeagueAllReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async driverLeagueAllReport(requestParameters: DriverLeagueAllReportRequest): Promise<File> {
        const response = await this.driverLeagueAllReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverLeagueEcoRaw(requestParameters: DriverLeagueEcoRequest): Promise<runtime.ApiResponse<Array<DriverLeagueEcoDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/League/DriverLeagueEco`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverLeagueEcoDTOFromJSON));
    }

    /**
     */
    async driverLeagueEco(requestParameters: DriverLeagueEcoRequest): Promise<Array<DriverLeagueEcoDTO>> {
        const response = await this.driverLeagueEcoRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverLeagueEcoReportRaw(requestParameters: DriverLeagueEcoReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/DriverLeagueEcoReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async driverLeagueEcoReport(requestParameters: DriverLeagueEcoReportRequest): Promise<File> {
        const response = await this.driverLeagueEcoReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverLeagueGeneralRaw(requestParameters: DriverLeagueGeneralRequest): Promise<runtime.ApiResponse<Array<DriverLeagueGeneralDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/League/DriverLeagueGeneral`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverLeagueGeneralDTOFromJSON));
    }

    /**
     */
    async driverLeagueGeneral(requestParameters: DriverLeagueGeneralRequest): Promise<Array<DriverLeagueGeneralDTO>> {
        const response = await this.driverLeagueGeneralRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverLeagueGeneralReportRaw(requestParameters: DriverLeagueGeneralReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/DriverLeagueGeneralReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async driverLeagueGeneralReport(requestParameters: DriverLeagueGeneralReportRequest): Promise<File> {
        const response = await this.driverLeagueGeneralReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverLeagueSafetyRaw(requestParameters: DriverLeagueSafetyRequest): Promise<runtime.ApiResponse<Array<DriverLeagueSafetyDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/League/DriverLeagueSafety`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverLeagueSafetyDTOFromJSON));
    }

    /**
     */
    async driverLeagueSafety(requestParameters: DriverLeagueSafetyRequest): Promise<Array<DriverLeagueSafetyDTO>> {
        const response = await this.driverLeagueSafetyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverLeagueSafetyReportRaw(requestParameters: DriverLeagueSafetyReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/DriverLeagueSafetyReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async driverLeagueSafetyReport(requestParameters: DriverLeagueSafetyReportRequest): Promise<File> {
        const response = await this.driverLeagueSafetyReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverPerformanceReportRaw(requestParameters: DriverPerformanceReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/DriverPerformanceReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async driverPerformanceReport(requestParameters: DriverPerformanceReportRequest): Promise<File> {
        const response = await this.driverPerformanceReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverReportRaw(requestParameters: DriverReportRequest): Promise<runtime.ApiResponse<Array<DriverReportDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reports/DriverReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverReportDTOFromJSON));
    }

    /**
     */
    async driverReport(requestParameters: DriverReportRequest): Promise<Array<DriverReportDTO>> {
        const response = await this.driverReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverReport_1Raw(requestParameters: DriverReport0Request): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.driverId !== undefined) {
            queryParameters['driverId'] = requestParameters.driverId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/DriverReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async driverReport_1(requestParameters: DriverReport0Request): Promise<File> {
        const response = await this.driverReport_1Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverRiskLeagueEcoRaw(requestParameters: DriverRiskLeagueEcoRequest): Promise<runtime.ApiResponse<Array<DriverRiskLeagueEcoDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/League/DriverRiskLeagueEco`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverRiskLeagueEcoDTOFromJSON));
    }

    /**
     */
    async driverRiskLeagueEco(requestParameters: DriverRiskLeagueEcoRequest): Promise<Array<DriverRiskLeagueEcoDTO>> {
        const response = await this.driverRiskLeagueEcoRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverRiskLeagueEcoReportRaw(requestParameters: DriverRiskLeagueEcoReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/DriverRiskLeagueEcoReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async driverRiskLeagueEcoReport(requestParameters: DriverRiskLeagueEcoReportRequest): Promise<File> {
        const response = await this.driverRiskLeagueEcoReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverRiskLeagueGeneralRaw(requestParameters: DriverRiskLeagueGeneralRequest): Promise<runtime.ApiResponse<Array<DriverRiskLeagueGeneralDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/League/DriverRiskLeagueGeneral`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverRiskLeagueGeneralDTOFromJSON));
    }

    /**
     */
    async driverRiskLeagueGeneral(requestParameters: DriverRiskLeagueGeneralRequest): Promise<Array<DriverRiskLeagueGeneralDTO>> {
        const response = await this.driverRiskLeagueGeneralRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverRiskLeagueGeneralReportRaw(requestParameters: DriverRiskLeagueGeneralReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/DriverRiskLeagueGeneralReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async driverRiskLeagueGeneralReport(requestParameters: DriverRiskLeagueGeneralReportRequest): Promise<File> {
        const response = await this.driverRiskLeagueGeneralReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverRiskLeagueSafetyRaw(requestParameters: DriverRiskLeagueSafetyRequest): Promise<runtime.ApiResponse<Array<DriverRiskLeagueSafetyDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/League/DriverRiskLeagueSafety`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverRiskLeagueSafetyDTOFromJSON));
    }

    /**
     */
    async driverRiskLeagueSafety(requestParameters: DriverRiskLeagueSafetyRequest): Promise<Array<DriverRiskLeagueSafetyDTO>> {
        const response = await this.driverRiskLeagueSafetyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverRiskLeagueSafetyReportRaw(requestParameters: DriverRiskLeagueSafetyReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/DriverRiskLeagueSafetyReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async driverRiskLeagueSafetyReport(requestParameters: DriverRiskLeagueSafetyReportRequest): Promise<File> {
        const response = await this.driverRiskLeagueSafetyReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async driverTrendRaw(requestParameters: DriverTrendRequest): Promise<runtime.ApiResponse<Array<DriverTrendResponseDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = (requestParameters.start as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reports/DriverTrend`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverTrendResponseDTOFromJSON));
    }

    /**
     */
    async driverTrend(requestParameters: DriverTrendRequest): Promise<Array<DriverTrendResponseDTO>> {
        const response = await this.driverTrendRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async fleetGraphRaw(requestParameters: FleetGraphRequest): Promise<runtime.ApiResponse<Array<FleetTrendDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = (requestParameters.start as any).toISOString();
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = (requestParameters.end as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reports/FleetGraph`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FleetTrendDTOFromJSON));
    }

    /**
     */
    async fleetGraph(requestParameters: FleetGraphRequest): Promise<Array<FleetTrendDTO>> {
        const response = await this.fleetGraphRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async fleetReportRaw(requestParameters: FleetReportRequest): Promise<runtime.ApiResponse<Array<ReportFleetDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reports/FleetReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportFleetDTOFromJSON));
    }

    /**
     */
    async fleetReport(requestParameters: FleetReportRequest): Promise<Array<ReportFleetDTO>> {
        const response = await this.fleetReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async fleetReport_2Raw(requestParameters: FleetReport0Request): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/FleetReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async fleetReport_2(requestParameters: FleetReport0Request): Promise<File> {
        const response = await this.fleetReport_2Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async fleetTrendRaw(requestParameters: FleetTrendRequest): Promise<runtime.ApiResponse<FleetTrendResponseDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = (requestParameters.start as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reports/FleetTrend`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FleetTrendResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async fleetTrend(requestParameters: FleetTrendRequest): Promise<FleetTrendResponseDTO> {
        const response = await this.fleetTrendRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getActionRaw(requestParameters: GetActionRequest): Promise<runtime.ApiResponse<Action>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getAction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Actions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionFromJSON(jsonValue));
    }

    /**
     */
    async getAction(requestParameters: GetActionRequest): Promise<Action> {
        const response = await this.getActionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getActionsRaw(): Promise<runtime.ApiResponse<Array<Action>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Actions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
    }

    /**
     */
    async getActions(): Promise<Array<Action>> {
        const response = await this.getActionsRaw();
        return await response.value();
    }

    /**
     */
    async getActivityRaw(requestParameters: GetActivityRequest): Promise<runtime.ApiResponse<Activity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getActivity.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Activities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityFromJSON(jsonValue));
    }

    /**
     */
    async getActivity(requestParameters: GetActivityRequest): Promise<Activity> {
        const response = await this.getActivityRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getBestDriversRaw(requestParameters: GetBestDriversRequest): Promise<runtime.ApiResponse<Array<BestDriversDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.gradeType !== undefined) {
            queryParameters['gradeType'] = requestParameters.gradeType;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Grade/GetBestDrivers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BestDriversDTOFromJSON));
    }

    /**
     */
    async getBestDrivers(requestParameters: GetBestDriversRequest): Promise<Array<BestDriversDTO>> {
        const response = await this.getBestDriversRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDriverPerformanceRaw(requestParameters: GetDriverPerformanceRequest): Promise<runtime.ApiResponse<Array<DriverPerformanceResponseDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reports/DriverPerformance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverPerformanceResponseDTOFromJSON));
    }

    /**
     */
    async getDriverPerformance(requestParameters: GetDriverPerformanceRequest): Promise<Array<DriverPerformanceResponseDTO>> {
        const response = await this.getDriverPerformanceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDriverStatsSummaryRaw(requestParameters: GetDriverStatsSummaryRequest): Promise<runtime.ApiResponse<DriverStatsSummaryDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reports/DriverStatsSummary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverStatsSummaryDTOFromJSON(jsonValue));
    }

    /**
     */
    async getDriverStatsSummary(requestParameters: GetDriverStatsSummaryRequest): Promise<DriverStatsSummaryDTO> {
        const response = await this.getDriverStatsSummaryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDriversRankingsRaw(requestParameters: GetDriversRankingsRequest): Promise<runtime.ApiResponse<Array<DriverRankingDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.criteria !== undefined) {
            queryParameters['criteria'] = requestParameters.criteria;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reports/DriversRankings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverRankingDTOFromJSON));
    }

    /**
     */
    async getDriversRankings(requestParameters: GetDriversRankingsRequest): Promise<Array<DriverRankingDTO>> {
        const response = await this.getDriversRankingsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getRouteByActivityIdRaw(requestParameters: GetRouteByActivityIdRequest): Promise<runtime.ApiResponse<Array<GetRouteByActivityIdDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.activityId !== undefined) {
            queryParameters['activity_id'] = requestParameters.activityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Route/GetRouteByActivityId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetRouteByActivityIdDTOFromJSON));
    }

    /**
     */
    async getRouteByActivityId(requestParameters: GetRouteByActivityIdRequest): Promise<Array<GetRouteByActivityIdDTO>> {
        const response = await this.getRouteByActivityIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getSpeedByActivityIdRaw(requestParameters: GetSpeedByActivityIdRequest): Promise<runtime.ApiResponse<Array<GetSpeedByActivityIdDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.activityId !== undefined) {
            queryParameters['activity_id'] = requestParameters.activityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Speed/GetSpeedByActivityId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSpeedByActivityIdDTOFromJSON));
    }

    /**
     */
    async getSpeedByActivityId(requestParameters: GetSpeedByActivityIdRequest): Promise<Array<GetSpeedByActivityIdDTO>> {
        const response = await this.getSpeedByActivityIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getUserRaw(): Promise<runtime.ApiResponse<UserDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Auth/GetUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     */
    async getUser(): Promise<UserDTO> {
        const response = await this.getUserRaw();
        return await response.value();
    }

    /**
     */
    async gradeCategoryByDriverRaw(requestParameters: GradeCategoryByDriverRequest): Promise<runtime.ApiResponse<GradeCategoryByDriverDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.departmentId !== undefined) {
            queryParameters['departmentId'] = requestParameters.departmentId;
        }

        if (requestParameters.withChildCustomers !== undefined) {
            queryParameters['withChildCustomers'] = requestParameters.withChildCustomers;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Grade/GradeCategoryByDriver`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GradeCategoryByDriverDTOFromJSON(jsonValue));
    }

    /**
     */
    async gradeCategoryByDriver(requestParameters: GradeCategoryByDriverRequest): Promise<GradeCategoryByDriverDTO> {
        const response = await this.gradeCategoryByDriverRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async gradeHistogramRaw(requestParameters: GradeHistogramRequest): Promise<runtime.ApiResponse<Array<GradeDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        if (requestParameters.departmentId !== undefined) {
            queryParameters['departmentId'] = requestParameters.departmentId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.bucketSize !== undefined) {
            queryParameters['bucketSize'] = requestParameters.bucketSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Grade/GradeHistogram`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GradeDTOFromJSON));
    }

    /**
     */
    async gradeHistogram(requestParameters: GradeHistogramRequest): Promise<Array<GradeDTO>> {
        const response = await this.gradeHistogramRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async gradeSummaryByCustomerRaw(requestParameters: GradeSummaryByCustomerRequest): Promise<runtime.ApiResponse<GradeSummaryByCustomerDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.withChildCustomers !== undefined) {
            queryParameters['withChildCustomers'] = requestParameters.withChildCustomers;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Grade/GradeSummaryByCustomer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GradeSummaryByCustomerDTOFromJSON(jsonValue));
    }

    /**
     */
    async gradeSummaryByCustomer(requestParameters: GradeSummaryByCustomerRequest): Promise<GradeSummaryByCustomerDTO> {
        const response = await this.gradeSummaryByCustomerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async gradeSummaryByDepartmentRaw(requestParameters: GradeSummaryByDepartmentRequest): Promise<runtime.ApiResponse<GradeSummaryByDepartmentDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.departmentId !== undefined) {
            queryParameters['departmentId'] = requestParameters.departmentId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Grade/GradeSummaryByDepartment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GradeSummaryByDepartmentDTOFromJSON(jsonValue));
    }

    /**
     */
    async gradeSummaryByDepartment(requestParameters: GradeSummaryByDepartmentRequest): Promise<GradeSummaryByDepartmentDTO> {
        const response = await this.gradeSummaryByDepartmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async gradeSummaryByDriverRaw(requestParameters: GradeSummaryByDriverRequest): Promise<runtime.ApiResponse<GradeSummaryByDriverDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.driverId !== undefined) {
            queryParameters['driverId'] = requestParameters.driverId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Grade/GradeSummaryByDriver`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GradeSummaryByDriverDTOFromJSON(jsonValue));
    }

    /**
     */
    async gradeSummaryByDriver(requestParameters: GradeSummaryByDriverRequest): Promise<GradeSummaryByDriverDTO> {
        const response = await this.gradeSummaryByDriverRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async gradeTotalRaw(requestParameters: GradeTotalRequest): Promise<runtime.ApiResponse<Array<GradeTotalDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Grade/GradeTotal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GradeTotalDTOFromJSON));
    }

    /**
     */
    async gradeTotal(requestParameters: GradeTotalRequest): Promise<Array<GradeTotalDTO>> {
        const response = await this.gradeTotalRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async gradeTotalBadDriversRaw(requestParameters: GradeTotalBadDriversRequest): Promise<runtime.ApiResponse<Array<GradeTotalBadDriversDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.departmentId !== undefined) {
            queryParameters['departmentId'] = requestParameters.departmentId;
        }

        if (requestParameters.threshold !== undefined) {
            queryParameters['threshold'] = requestParameters.threshold;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Grade/GradeTotalBadDrivers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GradeTotalBadDriversDTOFromJSON));
    }

    /**
     */
    async gradeTotalBadDrivers(requestParameters: GradeTotalBadDriversRequest): Promise<Array<GradeTotalBadDriversDTO>> {
        const response = await this.gradeTotalBadDriversRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async gradeTotalTop10Raw(requestParameters: GradeTotalTop10Request): Promise<runtime.ApiResponse<Array<GradeTotalBadDriversDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.departmentId !== undefined) {
            queryParameters['departmentId'] = requestParameters.departmentId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Grade/GradeTotalTop10`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GradeTotalBadDriversDTOFromJSON));
    }

    /**
     */
    async gradeTotalTop10(requestParameters: GradeTotalTop10Request): Promise<Array<GradeTotalBadDriversDTO>> {
        const response = await this.gradeTotalTop10Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listActionByActivityIdRaw(requestParameters: ListActionByActivityIdRequest): Promise<runtime.ApiResponse<Array<ActionWithDriverNameDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.activityId !== undefined) {
            queryParameters['activityId'] = requestParameters.activityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Actions/ListActionByActivityId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionWithDriverNameDTOFromJSON));
    }

    /**
     */
    async listActionByActivityId(requestParameters: ListActionByActivityIdRequest): Promise<Array<ActionWithDriverNameDTO>> {
        const response = await this.listActionByActivityIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listActionByDriverIdByDateRaw(requestParameters: ListActionByDriverIdByDateRequest): Promise<runtime.ApiResponse<Array<ActionListDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Actions/ListActionByDriverIdByDate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionListDTOFromJSON));
    }

    /**
     */
    async listActionByDriverIdByDate(requestParameters: ListActionByDriverIdByDateRequest): Promise<Array<ActionListDTO>> {
        const response = await this.listActionByDriverIdByDateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listActivityRaw(requestParameters: ListActivityRequest): Promise<runtime.ApiResponse<Array<ActivityDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Activities/ListActivity`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActivityDTOFromJSON));
    }

    /**
     */
    async listActivity(requestParameters: ListActivityRequest): Promise<Array<ActivityDTO>> {
        const response = await this.listActivityRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async reportRaw(requestParameters: ReportRequest): Promise<runtime.ApiResponse<Array<ReportDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Reports/Report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportDTOFromJSON));
    }

    /**
     */
    async report(requestParameters: ReportRequest): Promise<Array<ReportDTO>> {
        const response = await this.reportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async riskDegreeRaw(requestParameters: RiskDegreeRequest): Promise<runtime.ApiResponse<RiskDegreeDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.gradeType !== undefined) {
            queryParameters['gradeType'] = requestParameters.gradeType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Risk/RiskDegree`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskDegreeDTOFromJSON(jsonValue));
    }

    /**
     */
    async riskDegree(requestParameters: RiskDegreeRequest): Promise<RiskDegreeDTO> {
        const response = await this.riskDegreeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async riskDegreeTrendRaw(requestParameters: RiskDegreeTrendRequest): Promise<runtime.ApiResponse<RiskDegreeTrendDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.gradeType !== undefined) {
            queryParameters['gradeType'] = requestParameters.gradeType;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        if (requestParameters.utcOffset !== undefined) {
            queryParameters['utcOffset'] = requestParameters.utcOffset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Risk/RiskDegreeTrend`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskDegreeTrendDTOFromJSON(jsonValue));
    }

    /**
     */
    async riskDegreeTrend(requestParameters: RiskDegreeTrendRequest): Promise<RiskDegreeTrendDTO> {
        const response = await this.riskDegreeTrendRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async trendReportRaw(requestParameters: TrendReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/TrendReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async trendReport(requestParameters: TrendReportRequest): Promise<File> {
        const response = await this.trendReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async vehicleDocumentReportRaw(requestParameters: VehicleDocumentReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/VehicleDocumentReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async vehicleDocumentReport(requestParameters: VehicleDocumentReportRequest): Promise<File> {
        const response = await this.vehicleDocumentReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async violationRaw(requestParameters: ViolationRequest): Promise<runtime.ApiResponse<Array<ViolationDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Actions/Violation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ViolationDTOFromJSON));
    }

    /**
     */
    async violation(requestParameters: ViolationRequest): Promise<Array<ViolationDTO>> {
        const response = await this.violationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async violationDetailReportRaw(requestParameters: ViolationDetailReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/ViolationDetailReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async violationDetailReport(requestParameters: ViolationDetailReportRequest): Promise<File> {
        const response = await this.violationDetailReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async violationReportRaw(requestParameters: ViolationReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/ViolationReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverIdsDTOToJSON(requestParameters.driverIdsDTO),
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async violationReport(requestParameters: ViolationReportRequest): Promise<File> {
        const response = await this.violationReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async worstDriverLeagueGeneralRaw(requestParameters: WorstDriverLeagueGeneralRequest): Promise<runtime.ApiResponse<Array<WorstDriverLeagueGeneralDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/League/WorstDriverLeagueGeneral`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorstDriverLeagueGeneralDTOFromJSON));
    }

    /**
     */
    async worstDriverLeagueGeneral(requestParameters: WorstDriverLeagueGeneralRequest): Promise<Array<WorstDriverLeagueGeneralDTO>> {
        const response = await this.worstDriverLeagueGeneralRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async worstDriverLeagueGeneralReportRaw(requestParameters: WorstDriverLeagueGeneralReportRequest): Promise<runtime.ApiResponse<File>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ReportExport/WorstDriverLeagueGeneralReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async worstDriverLeagueGeneralReport(requestParameters: WorstDriverLeagueGeneralReportRequest): Promise<File> {
        const response = await this.worstDriverLeagueGeneralReportRaw(requestParameters);
        return await response.value();
    }

}
