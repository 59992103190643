import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
    Row,
    Button,
} from 'reactstrap'
import { IBaseProps } from "../../types"
import { initPresets, DatePickerData } from "../../stores/Models/DatePickerData"
import { action } from 'mobx'
import DateRangePickerWrapper from './DateRangePicker'


class Datepicker extends Component<IBaseProps, { dropdownOpen: boolean }> {
    datePresets: DatePickerData[]

    constructor(props: any) {
        super(props)

        this.datePresets = initPresets()

        this.toggle = this.toggle.bind(this)
        this.state = {
            dropdownOpen: false,
        }
        const store = this.props.store!
        store.ui.tempDatePicker = new DatePickerData(store.ui.datePicker.unit, store.ui.datePicker.end, store.ui.datePicker.start, store.ui.datePicker.name)
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        })
    }



    dropDate() {
        const t = this.props.t!
        const store = this.props.store!
        return (
            <Row>
                {store.ui.tempDatePicker.unit === "custom" ?
                    <>
                        <div className="col-auto align-self-center">
                            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                <DropdownToggle outline className="text-dark">
                                    <span>{t(store.ui.tempDatePicker.unit)}</span>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-lg">
                                    {this.datePresets.map((preset) => (
                                        <DropdownItem key={preset.name} onClick={action(() => { store.ui.tempDatePicker = (preset) })}>
                                            <span>{t(preset.name)}</span>
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>

                        <DateRangePickerWrapper
                            showClearDates
                            startDatePlaceholderText="Başlangıç"
                            endDatePlaceholderText="Bitiş"
                            monthFormat="YYYY MMMM"
                            initialStartDate={store.ui.tempDatePicker.start}
                            initialEndDate={store.ui.tempDatePicker.end}
                            onChange={action("dateChange", (start, end) => {
                                console.log(start)
                                console.log(end, "end")
                                store.ui.tempDatePicker.start = start
                                store.ui.tempDatePicker.end = end
                            })}

                        />

                    </>
                    :
                    <>
                        <Button outline className="mr-1 text-dark" onClick={() => { store.ui.tempDatePicker.previous() }}><i className="fa fa-chevron-left" aria-hidden="true"></i></Button>
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle outline className="text-dark">
                                <span>{store.ui.tempDatePicker.format}</span>
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-lg">
                                {this.datePresets.map((preset) => (
                                    <DropdownItem key={preset.name} onClick={action(() => { store.ui.tempDatePicker = (preset) })}>
                                        <span>{t(preset.name)}</span>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Button outline className="ml-1 text-dark" onClick={() => { store.ui.tempDatePicker.next() }}><i className="fa fa-chevron-right" aria-hidden="true"></i></Button>
                    </>
                }
            </Row>
        )
    }

    render() {
        return (
            this.dropDate()
        )
    }
}


export default inject("store")(withTranslation("main")(observer(Datepicker)))
