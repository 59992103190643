/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface Action
 */
export interface Action {
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    actionId?: number;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    activityId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Action
     */
    startDateTime?: moment.Moment;
    /**
     * 
     * @type {Date}
     * @memberof Action
     */
    endDateTime?: moment.Moment;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    startId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    endId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    type?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    penalty?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    magnitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    threshold?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    violationValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    lat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    lon?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    polyline?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Action
     */
    createdAt?: moment.Moment;
}

export function ActionFromJSON(json: any): Action {
    return ActionFromJSONTyped(json, false);
}

export function ActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Action {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionId': !exists(json, 'actionId') ? undefined : json['actionId'],
        'activityId': !exists(json, 'activityId') ? undefined : json['activityId'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : moment(json['startDateTime']),
        'endDateTime': !exists(json, 'endDateTime') ? undefined : moment(json['endDateTime']),
        'startId': !exists(json, 'startId') ? undefined : json['startId'],
        'endId': !exists(json, 'endId') ? undefined : json['endId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'penalty': !exists(json, 'penalty') ? undefined : json['penalty'],
        'magnitude': !exists(json, 'magnitude') ? undefined : json['magnitude'],
        'threshold': !exists(json, 'threshold') ? undefined : json['threshold'],
        'violationValue': !exists(json, 'violationValue') ? undefined : json['violationValue'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'polyline': !exists(json, 'polyline') ? undefined : json['polyline'],
        'createdAt': !exists(json, 'createdAt') ? undefined : moment(json['createdAt']),
    };
}

export function ActionToJSON(value?: Action | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionId': value.actionId,
        'activityId': value.activityId,
        'startDateTime': value.startDateTime === undefined ? undefined : (value.startDateTime === null ? null : value.startDateTime.toISOString()),
        'endDateTime': value.endDateTime === undefined ? undefined : (value.endDateTime === null ? null : value.endDateTime.toISOString()),
        'startId': value.startId,
        'endId': value.endId,
        'type': value.type,
        'description': value.description,
        'penalty': value.penalty,
        'magnitude': value.magnitude,
        'threshold': value.threshold,
        'violationValue': value.violationValue,
        'lat': value.lat,
        'lon': value.lon,
        'polyline': value.polyline,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}


