import {
    observable, action, computed,
} from 'mobx'
import RootStore from "./RootStore"
import { Activity, FleetReport, DriverLeagueSafetyTest } from "../api"
import { RouterState } from 'mobx-state-router'
import sortBy from "lodash.sortby"
import saveAs from 'file-saver'
import i18n from '../i18n'
import moment from "../utils/moment"
import { Collection, TimeSeries, TimeEvent, IndexedEvent, TimeRange } from "pondjs";
import { ActionListDTO, DriverRiskLeagueEcoDTO, DriverRiskLeagueSafetyDTO, DriverRiskLeagueGeneralDTO, DriverReportDTO, DriverTrendResponseDTO, FleetTrendResponseDTO, ReportFormats, DriverLeagueSafetyDTO, DriverLeagueGeneralDTO, DriverLeagueEcoDTO, ViolationDTO, FleetTrendDTO } from '../api2'





class ReportStore {
    rootStore: RootStore

    freportKey: number = 1
    ftrendKey: number = 1
    actionKey: number = 1
    reportKey: number = 1
    trendKey: number = 1
    lgeneralKey: number = 1
    rlgeneralKey: number = 1
    lecoKey: number = 1
    rlecoKey: number = 1
    lsafetyKey: number = 1
    rlsafetyKey: number = 1
    vreportKey: number = 1

    @observable reportLoading: boolean = false
    @observable excelLoading: boolean = false


    @observable actionListReady: boolean = false
    actionList: ActionListDTO[] = []

    @observable reportListReady: boolean = false
    @observable reportList: DriverReportDTO[] = []

    @observable trendReportListReady: boolean = false
    @observable trendReportList: DriverTrendResponseDTO[] = []

    @observable fleetReportListReady: boolean = false
    @observable fleetReportList: FleetReport | null

    @observable fleetTrendReportListReady: boolean = false
    @observable fleetTrendReportList: FleetTrendResponseDTO | null

    @observable driverLeagueGeneralReady: boolean = false
    @observable driverLeagueGeneral: DriverLeagueGeneralDTO[] = []

    @observable driverRiskLeagueGeneralReady: boolean = false
    @observable driverRiskLeagueGeneral: DriverRiskLeagueGeneralDTO[] = []

    @observable driverLeagueEcoReady: boolean = false
    @observable driverLeagueEco: DriverLeagueEcoDTO[] = []

    @observable driverRiskLeagueEcoReady: boolean = false
    @observable driverRiskLeagueEco: DriverRiskLeagueEcoDTO[] = []

    @observable driverLeagueSafetyReady: boolean = false
    @observable driverLeagueSafety: DriverLeagueSafetyDTO[] = []

    @observable driverRiskLeagueSafetyReady: boolean = false
    @observable driverRiskLeagueSafety: DriverRiskLeagueSafetyDTO[] = []

    @observable violationReportReady: boolean = false
    @observable violationReportList: ViolationDTO[] = []

    @observable driverLeagueSafetyTestReady: boolean = false
    @observable driverLeagueSafetyTest: DriverLeagueSafetyTest[] = []

    @observable fleetGraphRange: "1month" | "6month" | "1year" = "1month"
    @observable fleetGraphReady: boolean = false
    @observable fleetGraph: FleetTrendDTO[] = []
    fgraphKey: number = 1


    @observable offset: number = 0
    @observable fleetOffset: number = 0

    @observable tab: string = "1"
    @observable vtab: string = "summary"
    @observable ltab: string = "total"


    @observable reportType: string = ""

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }


    @computed get currentDriver() {
        if (this.rootStore && this.rootStore.ui.currentDriverIds.length > 0) {
            let i = this.offset % this.rootStore.ui.currentDriverIds.length
            return this.rootStore.ui.getDriver(this.rootStore.ui.currentDriverIds[i])
        }
        return null
    }

    @action changeDriver = (t: "next" | "prev") => {
        if (this.rootStore && this.rootStore.ui.currentDriverIds.length > 0) {

            if (t === "next") {
                this.offset++
            } else {
                this.offset--
                if (this.offset < 0) {
                    this.offset = 0
                }
            }
            this.offset = this.offset % this.rootStore.ui.currentDriverIds.length

        }
    }

    @action getActionList = () => {
        if (this.rootStore && this.rootStore.ui.currentDriverIds.length > 0) {
            let start
            let end
            if (this.rootStore.ui.datePicker) {
                start = this.rootStore.ui.datePicker.start.utc()
                end = this.rootStore.ui.datePicker.end.utc()

            }
            this.actionKey++
            let key = this.actionKey
            this.actionListReady = false
            this.rootStore.api2.listActionByDriverIdByDate({ driverIdsDTO: { ids: this.rootStore.ui.currentDriverIds }, startDate: start, endDate: end }).then(action("listActionByDriverIdByDate",
                (data) => {
                    if (key === this.actionKey) {
                        this.actionList = data ? data : []
                        this.actionListReady = true
                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }

    @action openActionListModal = (driverId, actionType: number[]) => {
        if (this.rootStore) {
            this.rootStore.modal.actionListModalDriverId = driverId
            this.rootStore.modal.toggleActionListModal()
            let start
            let end
            if (this.rootStore.ui.datePicker) {
                start = this.rootStore.ui.datePicker.start.utc()
                end = this.rootStore.ui.datePicker.end.utc()

            }
            this.actionKey++
            let key = this.actionKey
            this.actionListReady = false

            this.rootStore.api2.listActionByDriverIdByDate({ driverIdsDTO: { ids: [driverId] }, startDate: start, endDate: end }).then(action("listActionByDriverIdByDate",
                (data) => {
                    if (key === this.actionKey) {
                        this.actionList = data ? data.filter(d => actionType.includes(d.type!)) : []
                        this.actionListReady = true
                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))

        }
    }

    @action openActionListModalByActivity = (activityId, actionType: number[]) => {
        if (this.rootStore) {
            this.rootStore.modal.toggleActionListModal()
            this.actionKey++
            let key = this.actionKey
            this.actionListReady = false
            this.rootStore.api.listActionByActivityId(activityId).then(action("listActionByActivityId",
                (data: Activity[]) => {
                    if (key === this.actionKey) {
                        // @ts-ignore
                        this.actionList = data ? data.filter(d => actionType.includes(d.type!)) : []
                        this.actionListReady = true
                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }

    @action getViolationReport = () => {
        if (this.rootStore && this.rootStore.ui.currentDriverIds.length > 0) {
            let start
            let end
            if (this.rootStore.ui.datePicker) {
                start = this.rootStore.ui.datePicker.start.utc()
                end = this.rootStore.ui.datePicker.end.utc()

            }
            this.vreportKey++
            let key = this.vreportKey
            this.violationReportReady = false
            this.rootStore.api2.violation({ endDate: end, startDate: start, driverIdsDTO: { ids: this.rootStore.ui.currentDriverIds } }).then(action("listAction",
                (data) => {
                    if (key === this.vreportKey) {
                        this.violationReportList = data ? data : []
                        this.violationReportReady = true
                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }

    @action getTrendReportList = () => {
        if (this.rootStore && this.rootStore.ui.currentDriverIds.length > 0) {
            let end
            let interval
            if (this.rootStore.ui.datePicker) {
                end = this.rootStore.ui.datePicker.end.utc()
                interval = this.rootStore.ui.datePicker.unit === "custom" ? "day" : this.rootStore.ui.datePicker.unit

            }
            this.trendKey++
            let key = this.trendKey
            this.trendReportListReady = false
            this.rootStore.api2.driverTrend({ interval, start: end, driverIdsDTO: { ids: this.rootStore.ui.currentDriverIds } }).then(action("getDriverTrendReport",
                (data) => {
                    if (key === this.trendKey) {
                        this.trendReportList = data ? data : []
                        this.trendReportListReady = true
                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }

    @action setTab = (route: string, tab: string) => {
        this.tab = tab
        let oldParams = Object.assign({}, this.rootStore.routing.routerState.queryParams)
        // @ts-ignore
        oldParams.tab = tab
        let toState: RouterState = new RouterState(route, this.rootStore.routing.routerState.params, oldParams)
        this.rootStore.routing.goTo(toState)
    }

    @action setVTab = (tab: string) => {
        this.vtab = tab
        let oldParams = Object.assign({}, this.rootStore.routing.routerState.queryParams)
        // @ts-ignore
        oldParams.tab = tab
        let toState: RouterState = new RouterState(this.rootStore.routing.getCurrentRoute().name, this.rootStore.routing.routerState.params, oldParams)
        this.rootStore.routing.goTo(toState)
    }
    @action setLTab = (tab: string) => {
        this.ltab = tab
        let oldParams = Object.assign({}, this.rootStore.routing.routerState.queryParams)
        // @ts-ignore
        oldParams.tab = tab
        let toState: RouterState = new RouterState(this.rootStore.routing.getCurrentRoute().name, this.rootStore.routing.routerState.params, oldParams)
        this.rootStore.routing.goTo(toState)
    }

    @action setReportType = (reportType: string) => {
        if (this.reportType !== reportType) {
            this.tab = "1"
            this.reportType = reportType
        }
    }


    @action getReportList = () => {
        if (this.rootStore && this.rootStore.ui.currentDriverIds.length > 0) {
            this.getTrendReportList()
            let start
            let end
            if (this.rootStore.ui.datePicker) {
                start = this.rootStore.ui.datePicker.start.utc()
                end = this.rootStore.ui.datePicker.end.utc()
            }
            this.reportKey++
            let key = this.reportKey
            this.reportListReady = false
            this.rootStore.api2.driverReport({ endDate: end, startDate: start, driverIdsDTO: { ids: this.rootStore.ui.currentDriverIds } }).then(action("getDriverReport",
                (data) => {
                    if (key === this.reportKey) {
                        this.reportList = data ? data : []
                        this.reportListReady = true
                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }

    @action getDriverLeagueGeneral = (callback?: (list: DriverLeagueGeneralDTO[]) => void) => {
        if (this.rootStore && this.rootStore.ui.currentDriverIds.length > 0) {
            let start
            let end
            let interval
            if (this.rootStore.ui.datePicker) {
                start = this.rootStore.ui.datePicker.start.utc()
                end = this.rootStore.ui.datePicker.end.utc()
                interval = this.rootStore.ui.datePicker.unit
            }
            this.lgeneralKey++
            let key = this.lgeneralKey
            this.driverLeagueGeneralReady = false
            this.rootStore.api2.driverLeagueGeneral({ endDate: end, startDate: start, interval, driverIdsDTO: { ids: this.rootStore.ui.currentDriverIds } }).then(action("getDriverLeagueGeneral",
                (data) => {
                    if (key === this.lgeneralKey) {
                        this.driverLeagueGeneral = data
                        this.driverLeagueGeneralReady = true
                        if (callback) {
                            callback(this.driverLeagueGeneral)
                        }
                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }


    @action getDriverRiskLeagueGeneral = (callback?: (list: DriverRiskLeagueGeneralDTO[]) => void) => {
        if (this.rootStore && this.rootStore.ui.currentDriverIds.length > 0) {
            let start
            let end
            let interval
            if (this.rootStore.ui.datePicker) {
                start = this.rootStore.ui.datePicker.start.utc()
                end = this.rootStore.ui.datePicker.end.utc()
                interval = this.rootStore.ui.datePicker.unit
            }
            this.rlgeneralKey++
            let key = this.rlgeneralKey
            this.driverRiskLeagueGeneralReady = false
            this.rootStore.api2.driverRiskLeagueGeneral({ endDate: end, startDate: start, interval, driverIdsDTO: { ids: this.rootStore.ui.currentDriverIds } }).then(action("getDriverRiskLeagueGeneral",
                (data: DriverRiskLeagueGeneralDTO[]) => {
                    if (key === this.rlgeneralKey) {
                        this.driverRiskLeagueGeneral = data
                        this.driverRiskLeagueGeneralReady = true
                        if (callback) {
                            callback(this.driverRiskLeagueGeneral)
                        }
                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }


    @action getDriverLeagueEco = (callback?: (list: DriverLeagueEcoDTO[]) => void) => {
        if (this.rootStore && this.rootStore.ui.currentDriverIds.length > 0) {
            let start
            let end
            let interval
            if (this.rootStore.ui.datePicker) {
                start = this.rootStore.ui.datePicker.start.utc()
                end = this.rootStore.ui.datePicker.end.utc()
                interval = this.rootStore.ui.datePicker.unit

            }
            this.lecoKey++
            let key = this.lecoKey
            this.driverLeagueEcoReady = false
            this.rootStore.api2.driverLeagueEco({ endDate: end, startDate: start, interval, driverIdsDTO: { ids: this.rootStore.ui.currentDriverIds } }).then(action("getDriverLeagueEco",
                (data) => {
                    if (key === this.lecoKey) {
                        this.driverLeagueEco = data
                        this.driverLeagueEcoReady = true
                        if (callback) {
                            callback(this.driverLeagueEco)
                        }

                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }




    @action getDriverRiskLeagueEco = (callback?: (list: DriverRiskLeagueEcoDTO[]) => void) => {
        if (this.rootStore && this.rootStore.ui.currentDriverIds.length > 0) {
            let start
            let end
            let interval
            if (this.rootStore.ui.datePicker) {
                start = this.rootStore.ui.datePicker.start.utc()
                end = this.rootStore.ui.datePicker.end.utc()
                interval = this.rootStore.ui.datePicker.unit

            }
            this.rlecoKey++
            let key = this.rlecoKey
            this.driverRiskLeagueEcoReady = false
            this.rootStore.api2.driverRiskLeagueEco({ endDate: end, startDate: start, interval, driverIdsDTO: { ids: this.rootStore.ui.currentDriverIds } }).then(action("getDriverRiskLeagueEco",
                (data: DriverRiskLeagueEcoDTO[]) => {
                    if (key === this.rlecoKey) {
                        this.driverRiskLeagueEco = data
                        this.driverRiskLeagueEcoReady = true
                        if (callback) {
                            callback(this.driverRiskLeagueEco)
                        }

                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }

    @action getDriverLeagueSafety = (callback?: (list: DriverLeagueSafetyDTO[]) => void) => {
        if (this.rootStore && this.rootStore.ui.currentDriverIds.length > 0) {
            let start
            let end
            let interval
            if (this.rootStore.ui.datePicker) {
                start = this.rootStore.ui.datePicker.start.utc()
                end = this.rootStore.ui.datePicker.end.utc()
                interval = this.rootStore.ui.datePicker.unit
            }
            this.lsafetyKey++
            let key = this.lsafetyKey
            this.driverLeagueSafetyReady = false
            this.rootStore.api2.driverLeagueSafety({ endDate: end, startDate: start, interval, driverIdsDTO: { ids: this.rootStore.ui.currentDriverIds } }).then(action("getDriverLeagueSafety",
                (data) => {
                    if (key === this.lsafetyKey) {
                        this.driverLeagueSafety = data
                        this.driverLeagueSafetyReady = true
                        if (callback) {
                            callback(this.driverLeagueSafety)
                        }

                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }

    @action getDriverRiskLeagueSafety = (callback?: (list: DriverRiskLeagueSafetyDTO[]) => void) => {
        if (this.rootStore && this.rootStore.ui.currentDriverIds.length > 0) {
            let start
            let end
            let interval
            if (this.rootStore.ui.datePicker) {
                start = this.rootStore.ui.datePicker.start.utc()
                end = this.rootStore.ui.datePicker.end.utc()
                interval = this.rootStore.ui.datePicker.unit

            }
            this.rlsafetyKey++
            let key = this.rlsafetyKey
            this.driverRiskLeagueSafetyReady = false
            this.rootStore.api2.driverRiskLeagueSafety({ endDate: end, startDate: start, interval, driverIdsDTO: { ids: this.rootStore.ui.currentDriverIds } }).then(action("getDriverRiskLeagueSafety",
                (data: DriverRiskLeagueSafetyDTO[]) => {
                    if (key === this.rlsafetyKey) {
                        this.driverRiskLeagueSafety = data
                        this.driverRiskLeagueSafetyReady = true
                        if (callback) {
                            callback(this.driverRiskLeagueSafety)
                        }

                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }


    @action getDriverLeagueSafetyTest = (callback?: (list: DriverLeagueSafetyTest[]) => void) => {
        if (this.rootStore && this.rootStore.ui.currentDriverIds.length > 0) {
            let start
            let end
            let interval
            if (this.rootStore.ui.datePicker) {
                start = this.rootStore.ui.datePicker.start.utc().format("YYYY-MM-DD HH:mm:ss")
                end = this.rootStore.ui.datePicker.end.utc().format("YYYY-MM-DD HH:mm:ss")
                interval = this.rootStore.ui.datePicker.unit === "custom" ? "day" : this.rootStore.ui.datePicker.unit

            }
            this.lsafetyKey++
            let key = this.lsafetyKey
            this.driverLeagueSafetyTestReady = false
            this.rootStore.api.getDriverLeagueSafetyTest(this.rootStore.ui.currentDriverIds, start, end, interval).then(action("getDriverLeagueSafetytest",
                (data: DriverLeagueSafetyTest[]) => {
                    if (key === this.lsafetyKey) {
                        this.driverLeagueSafetyTest = data ? sortBy(data, (o) => o.safetyGrade) : []
                        this.driverLeagueSafetyTestReady = true
                        if (callback) {
                            callback(this.driverLeagueSafetyTest)
                        }

                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }

    @action getFleetReportList = (drivers: number[]) => {
        if (this.rootStore) {
            let start
            let end
            if (this.rootStore.ui.datePicker) {
                start = this.rootStore.ui.datePicker.start.utc()
                end = this.rootStore.ui.datePicker.end.utc()

            }
            this.freportKey++
            let key = this.freportKey
            this.fleetReportListReady = false
            this.rootStore.api2.fleetReport({ startDate: start, endDate: end, driverIdsDTO: { ids: drivers } }).then(action("getFleetReport",
                (data: FleetReport[]) => {
                    if (key === this.freportKey) {
                        this.fleetReportList = data && data.length > 0 && data[0].activityCount! > 0 ? data[0] : null
                        this.fleetReportListReady = true
                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }
    @action getFleetTrendReportList = (drivers: number[]) => {
        if (this.rootStore) {
            let end
            let interval
            if (this.rootStore.ui.datePicker) {
                end = this.rootStore.ui.datePicker.end.utc()
                interval = this.rootStore.ui.datePicker.unit === "custom" ? "day" : this.rootStore.ui.datePicker.unit

            }
            this.ftrendKey++
            let key = this.ftrendKey
            this.fleetTrendReportListReady = false
            this.rootStore.api2.fleetTrend({ start: end, driverIdsDTO: { ids: drivers }, interval }).then(action("getFleetTrendReport",
                (data) => {
                    if (key === this.ftrendKey) {
                        this.fleetTrendReportList = data ? data : null
                        this.fleetTrendReportListReady = true
                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }

    @action getFleetTGraph = (drivers: number[], range: "1month" | "6month" | "1year" = "1month") => {
        if (this.rootStore) {
            let end, start, interval
            if (this.rootStore.ui.datePicker) {
                end = this.rootStore.ui.datePicker.end.utc()
                if (range === "1year") {
                    start = end.clone().subtract(1, 'years')

                } else if (range === "1month") {
                    start = end.clone().subtract(1, 'months')

                } else if (range === "6month") {
                    start = end.clone().subtract(6, 'months')

                }
                interval = this.rootStore.ui.datePicker.unit === "custom" ? "day" : this.rootStore.ui.datePicker.unit

            }
            this.fgraphKey++
            let key = this.fgraphKey
            this.fleetGraphReady = false
            this.rootStore.api2.fleetGraph({ start: start, end: end, driverIdsDTO: { ids: drivers }, interval }).then(action("getFleetGraph",
                (data) => {
                    if (key === this.fgraphKey) {
                        this.fleetGraph = data ? data : []
                        this.fleetGraphReady = true
                    }
                })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }

    @action downloadReport(reportType: string, type: string, currentDriverIds: number[], start: string, end: string, unit: string) {
        this.reportLoading = true
        this.rootStore.api.reportLeague(reportType, type, currentDriverIds, start, end, unit).then((data) => {
            if (data) {
                saveAs(data, reportType + " " + moment().format("LLL") + (type === "excel" ? ".xlsx" : ".pdf"))
            }
            this.reportLoading = false

        })
    }

    @action downloadReport2(reportType: string, type: ReportFormats, currentDriverIds: number[], start: moment.Moment, end: moment.Moment, interval?: string) {
        this.reportLoading = true
        this.rootStore.api2[reportType]({
            driverIdsDTO: { ids: currentDriverIds },
            endDate: end,
            startDate: start,
            format: type,
            interval
        }).then((data) => {
            if (data) {
                saveAs(data, i18n.t(reportType) + " " + moment().format("LLL") + (type === ReportFormats.EXCEL ? ".xlsx" : ".pdf"))
            }
            this.reportLoading = false

        })
    }

    @action downloadReport3(reportType: string, type: ReportFormats, currentDriverId: number, start: moment.Moment, end: moment.Moment, interval: string, reportname: string | null | undefined = undefined) {
        this.reportLoading = true
        this.rootStore.api2[reportType]({
            driverId: currentDriverId,
            endDate: end,
            startDate: start,
            format: type,
            interval
        }).then((data) => {
            if (data) {
                saveAs(data, (reportname ?? "") + " " + i18n.t(reportType) + " " + moment().format("LLL") + (type === ReportFormats.EXCEL ? ".xlsx" : ".pdf"))
            }
            this.reportLoading = false

        })
    }


    @action downloadExcel(reportType: string, type: string, currentDriverIds: number[], start: string, end: string, unit: string) {
        this.excelLoading = true
        this.rootStore.api.reportExcel(reportType, type, currentDriverIds, start, end, unit).then((data) => {
            if (data) {
                saveAs(data, i18n.t(reportType) + " " + moment().format("LLL") + (type === ReportFormats.EXCEL ? ".xlsx" : ".pdf"))
            }
            this.excelLoading = false
        })
    }

    @computed get fleetGraphTS() {
        if (this.fleetGraph && this.fleetGraph.length > 0) {
            let data = this.fleetGraph.map((r) => new TimeEvent(r.startDateTime?.toDate(), { total: r.avgTotalGrade, eco: r.avgEcoGrade, safety: r.avgSafetyGrade }))
            const collection = new Collection(data);
            const sortedCollection = collection.sortByTime();
            let ts = new TimeSeries({
                name: "points",
                utc: true,
                columns: ["total", "safety", "eco"],
                collection: sortedCollection
            })
            return ts
        }
        else {
            return new TimeSeries()
        }
    }

    @computed get fleetGraphTSCounts() {
        if (this.fleetGraph && this.fleetGraph.length > 0) {
            let data = this.fleetGraph.map((r) => new IndexedEvent(r.startDateTime?.toISOString(), { count: r.count }))
            const collection = new Collection(data);
            const sortedCollection = collection.sortByTime();
            let ts = new TimeSeries({
                name: "counts",
                utc: true,
                columns: ["count"],
                collection: sortedCollection
            })
            return ts
        }
        else {
            return new TimeSeries()
        }
    }

    @computed get currentReport() {
        if (this.currentDriver) {
            return this.reportList.filter(r => r.driverId === this.currentDriver!.id)[0]
        } else {
            return null
        }
    }

    @computed get currentTrendReport() {
        if (this.currentDriver) {
            return this.trendReportList.filter(r => r.driverId === this.currentDriver!.id)[0]
        } else {
            return null
        }
    }


}

export default ReportStore