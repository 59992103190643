/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface DriverReportDTO
 */
export interface DriverReportDTO {
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    activityCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgActivity?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    totalDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    maxSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    totalIdleTime?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgIdleTime?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgTotalGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgSafetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgEcoGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgSuddenAccelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgSuddenBrakeGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgHardTurnGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgSpeedingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgDrowsinessGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgAccelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgBrakingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgIdlingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgSpeedGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    totalTime?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    avgTime?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverReportDTO
     */
    driverName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DriverReportDTO
     */
    driverId?: number;
}

export function DriverReportDTOFromJSON(json: any): DriverReportDTO {
    return DriverReportDTOFromJSONTyped(json, false);
}

export function DriverReportDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverReportDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activityCount': !exists(json, 'activityCount') ? undefined : json['activityCount'],
        'avgActivity': !exists(json, 'avgActivity') ? undefined : json['avgActivity'],
        'avgDistance': !exists(json, 'avgDistance') ? undefined : json['avgDistance'],
        'totalDistance': !exists(json, 'totalDistance') ? undefined : json['totalDistance'],
        'maxSpeed': !exists(json, 'maxSpeed') ? undefined : json['maxSpeed'],
        'avgSpeed': !exists(json, 'avgSpeed') ? undefined : json['avgSpeed'],
        'totalIdleTime': !exists(json, 'totalIdleTime') ? undefined : json['totalIdleTime'],
        'avgIdleTime': !exists(json, 'avgIdleTime') ? undefined : json['avgIdleTime'],
        'avgTotalGrade': !exists(json, 'avgTotalGrade') ? undefined : json['avgTotalGrade'],
        'avgSafetyGrade': !exists(json, 'avgSafetyGrade') ? undefined : json['avgSafetyGrade'],
        'avgEcoGrade': !exists(json, 'avgEcoGrade') ? undefined : json['avgEcoGrade'],
        'avgSuddenAccelGrade': !exists(json, 'avgSuddenAccelGrade') ? undefined : json['avgSuddenAccelGrade'],
        'avgSuddenBrakeGrade': !exists(json, 'avgSuddenBrakeGrade') ? undefined : json['avgSuddenBrakeGrade'],
        'avgHardTurnGrade': !exists(json, 'avgHardTurnGrade') ? undefined : json['avgHardTurnGrade'],
        'avgSpeedingGrade': !exists(json, 'avgSpeedingGrade') ? undefined : json['avgSpeedingGrade'],
        'avgDrowsinessGrade': !exists(json, 'avgDrowsinessGrade') ? undefined : json['avgDrowsinessGrade'],
        'avgAccelGrade': !exists(json, 'avgAccelGrade') ? undefined : json['avgAccelGrade'],
        'avgBrakingGrade': !exists(json, 'avgBrakingGrade') ? undefined : json['avgBrakingGrade'],
        'avgIdlingGrade': !exists(json, 'avgIdlingGrade') ? undefined : json['avgIdlingGrade'],
        'avgSpeedGrade': !exists(json, 'avgSpeedGrade') ? undefined : json['avgSpeedGrade'],
        'totalTime': !exists(json, 'totalTime') ? undefined : json['totalTime'],
        'avgTime': !exists(json, 'avgTime') ? undefined : json['avgTime'],
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
    };
}

export function DriverReportDTOToJSON(value?: DriverReportDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activityCount': value.activityCount,
        'avgActivity': value.avgActivity,
        'avgDistance': value.avgDistance,
        'totalDistance': value.totalDistance,
        'maxSpeed': value.maxSpeed,
        'avgSpeed': value.avgSpeed,
        'totalIdleTime': value.totalIdleTime,
        'avgIdleTime': value.avgIdleTime,
        'avgTotalGrade': value.avgTotalGrade,
        'avgSafetyGrade': value.avgSafetyGrade,
        'avgEcoGrade': value.avgEcoGrade,
        'avgSuddenAccelGrade': value.avgSuddenAccelGrade,
        'avgSuddenBrakeGrade': value.avgSuddenBrakeGrade,
        'avgHardTurnGrade': value.avgHardTurnGrade,
        'avgSpeedingGrade': value.avgSpeedingGrade,
        'avgDrowsinessGrade': value.avgDrowsinessGrade,
        'avgAccelGrade': value.avgAccelGrade,
        'avgBrakingGrade': value.avgBrakingGrade,
        'avgIdlingGrade': value.avgIdlingGrade,
        'avgSpeedGrade': value.avgSpeedGrade,
        'totalTime': value.totalTime,
        'avgTime': value.avgTime,
        'driverName': value.driverName,
        'driverId': value.driverId,
    };
}


