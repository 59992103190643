/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface DriverLeagueGeneralDTO
 */
export interface DriverLeagueGeneralDTO {
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueGeneralDTO
     */
    ecoGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueGeneralDTO
     */
    safetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueGeneralDTO
     */
    totalGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueGeneralDTO
     */
    totalDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueGeneralDTO
     */
    totalDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueGeneralDTO
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueGeneralDTO
     */
    unitId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DriverLeagueGeneralDTO
     */
    isSingleUnit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueGeneralDTO
     */
    unitPlate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueGeneralDTO
     */
    ecoGradeTrendRate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueGeneralDTO
     */
    totalGradeTrendRate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueGeneralDTO
     */
    safetyGradeTrendRate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueGeneralDTO
     */
    driverName?: string | null;
}

export function DriverLeagueGeneralDTOFromJSON(json: any): DriverLeagueGeneralDTO {
    return DriverLeagueGeneralDTOFromJSONTyped(json, false);
}

export function DriverLeagueGeneralDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverLeagueGeneralDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ecoGrade': !exists(json, 'ecoGrade') ? undefined : json['ecoGrade'],
        'safetyGrade': !exists(json, 'safetyGrade') ? undefined : json['safetyGrade'],
        'totalGrade': !exists(json, 'totalGrade') ? undefined : json['totalGrade'],
        'totalDistance': !exists(json, 'totalDistance') ? undefined : json['totalDistance'],
        'totalDuration': !exists(json, 'totalDuration') ? undefined : json['totalDuration'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'unitId': !exists(json, 'unitId') ? undefined : json['unitId'],
        'isSingleUnit': !exists(json, 'isSingleUnit') ? undefined : json['isSingleUnit'],
        'unitPlate': !exists(json, 'unitPlate') ? undefined : json['unitPlate'],
        'ecoGradeTrendRate': !exists(json, 'ecoGradeTrendRate') ? undefined : json['ecoGradeTrendRate'],
        'totalGradeTrendRate': !exists(json, 'totalGradeTrendRate') ? undefined : json['totalGradeTrendRate'],
        'safetyGradeTrendRate': !exists(json, 'safetyGradeTrendRate') ? undefined : json['safetyGradeTrendRate'],
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
    };
}

export function DriverLeagueGeneralDTOToJSON(value?: DriverLeagueGeneralDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ecoGrade': value.ecoGrade,
        'safetyGrade': value.safetyGrade,
        'totalGrade': value.totalGrade,
        'totalDistance': value.totalDistance,
        'totalDuration': value.totalDuration,
        'driverId': value.driverId,
        'unitId': value.unitId,
        'isSingleUnit': value.isSingleUnit,
        'unitPlate': value.unitPlate,
        'ecoGradeTrendRate': value.ecoGradeTrendRate,
        'totalGradeTrendRate': value.totalGradeTrendRate,
        'safetyGradeTrendRate': value.safetyGradeTrendRate,
        'driverName': value.driverName,
    };
}


