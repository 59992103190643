import React, { Component } from 'react'
import {
    Nav,
    NavbarBrand,
    NavbarToggler
} from 'reactstrap'
import DriverName from './DriverName'

import { observer, inject } from 'mobx-react'
import { IBaseProps } from "../../types"
import compose from '../../utils/compose'



class Header extends Component<IBaseProps, {}> {

    constructor(props: any) {
        super(props)
    }

    sidebarToggle(e) {
        e.preventDefault()
        document.body.classList.toggle('sidebar-hidden')
    }

    sidebarMinimize(e) {
        e.preventDefault()
        document.body.classList.toggle('sidebar-minimized')
    }

    mobileSidebarToggle(e) {
        e.preventDefault()
        document.body.classList.toggle('sidebar-mobile-show')
    }

    asideToggle(e) {
        e.preventDefault()
        document.body.classList.toggle('aside-menu-hidden')
    }

    render() {
        const store = this.props.store!
        return (
            <header className="app-header navbar">
                <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
                    <span className="navbar-toggler-icon"></span>
                </NavbarToggler>
                <NavbarBrand href="#"></NavbarBrand>
                <NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>
                    <span className="navbar-toggler-icon"></span>
                </NavbarToggler>


                <Nav className="ml-auto" navbar>
                    {store.auth && <DriverName />}

                    {/*<HeaderDropdown accnt />*/}
                </Nav>
            </header>
        )
    }
}

export default compose(
    inject("store"),
    observer,
)(Header)
