import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import Spinner from '../../../components/Spinner/Spinner'
import { observer, inject } from 'mobx-react'
import { IBaseProps } from '../../../types'
import config from '../../../config'
import LoginRedirect from './LoginRedirect'
import LoginForm from './LoginForm'

/*http://sds.devtakip.com/?token=921153c3-0db0-4e81-a514-24cf3be98d56*/
class Login extends Component<IBaseProps> {
    render() {
        const store = this.props.store!
        return (
            <div className="animated fadeIn screen-height-limit ">

                <Row className="justify-content-center">

                    {
                        store.authReady
                            ?
                            <Col md="4">
                                <Card className="p-4">
                                    <CardBody className="text-center">
                                        <h1>Giriş</h1>
                                        {process.env.REACT_APP_SV === 'sds' || process.env.REACT_APP_SV === 'sds-test' ?
                                            // @ts-ignore 
                                            < LoginForm />
                                            :
                                            process.env.REACT_APP_SV === 'kp' ?
                                                <React.Fragment>
                                                    <p className="text-muted">Lütfen tekrar giriş yapın</p>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <p className="text-muted">Lütfen <a href={config.login_redirect}>{config.login_redirect}</a> üzerinden giriş yapın</p>
                                                    {/*
                                        // @ts-ignore */}
                                                    <LoginRedirect />
                                                </React.Fragment>

                                        }

                                    </CardBody>
                                </Card>
                            </Col>

                            :
                            <Spinner />
                    }

                </Row>
            </div>
        )
    }
}

export default inject("store")(observer(Login))
