import React from 'react'
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap'


interface IState { popoverOpen: boolean }


class HelpPopover extends React.Component<{ id?: string, title?: string, children: React.ReactNode }, IState> {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      popoverOpen: false
    }
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    })
  }

  render() {
    return (
      <div>
        <Button id={(this.props.id + "popover") || "popover"} onClick={this.toggle}>
          <i className="fa fa-question" aria-hidden="true" />
        </Button>
        <Popover placement="auto" isOpen={this.state.popoverOpen} target={(this.props.id + "popover") || "popover"} toggle={this.toggle}>
          {this.props.title && <PopoverHeader>{this.props.title}</PopoverHeader>}
          <PopoverBody>{this.props.children}</PopoverBody>
        </Popover>
      </div>
    )
  }
}

export default HelpPopover