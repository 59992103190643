import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import EmptyPrompt from "../components/EmptyPrompt/EmptyPrompt"
import { IBaseProps } from '../types'

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

const checkSuperUser = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    const CheckCrm = inject("store")(observer(class CheckSelectedDriver extends Component<P & IBaseProps> {
        render() {
            const store = (this.props as IBaseProps).store!
            return <>
                {store.superUser ?
                    <WrappedComponent {...this.props} /> :
                    <EmptyPrompt prompt="noPermission" iconName="fa-lock" />
                }

            </>
        }
    }))
    // @ts-ignore
    CheckCrm.displayName = `checkSuperUser(${getDisplayName(WrappedComponent)})`
    return CheckCrm
}

export default checkSuperUser