import { Component } from 'react'
import {
    Col,
    Row,
} from 'reactstrap'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { IBaseProps } from "../../types"

import Spinner from '../../components/Spinner/Spinner'
import BestWorst from '../../components/Chart/BestWorst'






class BestDrivers extends Component<IBaseProps> {

    reaction


    constructor(props: any) {
        super(props)

    }



    render() {
        const store = this.props.store!
        if (!store.ui.treeReady) {

            return <div className="animated fadeIn screen-height-limit">
                <Spinner />
            </div>

        }


        return (
            <div className="animated fadeIn screen-height-limit" >


                <Row>
                    <Col md={12}>
                        <BestWorst gradetype={"total"} type="best" allDrivers />

                    </Col>
                    <Col md={12}>
                        <BestWorst gradetype={"total"} type="worst" allDrivers />
                    </Col>
                </Row>

            </div >
        )
    }

}

export default inject("store")(withTranslation("main")(observer(BestDrivers)))
