/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface RiskDegreeDTO
 */
export interface RiskDegreeDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof RiskDegreeDTO
     */
    driverId?: Array<number> | null;
    /**
     * 
     * @type {Date}
     * @memberof RiskDegreeDTO
     */
    startDateTime?: moment.Moment;
    /**
     * 
     * @type {string}
     * @memberof RiskDegreeDTO
     */
    gradeType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeDTO
     */
    totalActivity?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeDTO
     */
    riskDegreeNone?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeDTO
     */
    riskDegreeLow?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeDTO
     */
    riskDegreeMedium?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskDegreeDTO
     */
    riskDegreeHigh?: number;
}

export function RiskDegreeDTOFromJSON(json: any): RiskDegreeDTO {
    return RiskDegreeDTOFromJSONTyped(json, false);
}

export function RiskDegreeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskDegreeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : moment(json['startDateTime']),
        'gradeType': !exists(json, 'gradeType') ? undefined : json['gradeType'],
        'totalActivity': !exists(json, 'totalActivity') ? undefined : json['totalActivity'],
        'riskDegreeNone': !exists(json, 'riskDegreeNone') ? undefined : json['riskDegreeNone'],
        'riskDegreeLow': !exists(json, 'riskDegreeLow') ? undefined : json['riskDegreeLow'],
        'riskDegreeMedium': !exists(json, 'riskDegreeMedium') ? undefined : json['riskDegreeMedium'],
        'riskDegreeHigh': !exists(json, 'riskDegreeHigh') ? undefined : json['riskDegreeHigh'],
    };
}

export function RiskDegreeDTOToJSON(value?: RiskDegreeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driverId': value.driverId,
        'startDateTime': value.startDateTime === undefined ? undefined : (value.startDateTime.toISOString()),
        'gradeType': value.gradeType,
        'totalActivity': value.totalActivity,
        'riskDegreeNone': value.riskDegreeNone,
        'riskDegreeLow': value.riskDegreeLow,
        'riskDegreeMedium': value.riskDegreeMedium,
        'riskDegreeHigh': value.riskDegreeHigh,
    };
}


