// import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
// Styles
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css'

// Containers
import Full from './containers/Full/Full'

// Views

import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

import ErrorBoundary from './ErrorBoundary'
import * as Sentry from "@sentry/react"
import StoreProvider from './StoreProvider'

// Import Main styles for this application
if (process.env.REACT_APP_SV === 'ptt') {
    // tslint:disable-next-line
    require('./scss/ptt.scss')
} else if (process.env.REACT_APP_SV === 'yt') {
    // tslint:disable-next-line
    require('./scss/yt.scss')
} else if (process.env.REACT_APP_SV === 'kp') {
    // tslint:disable-next-line
    require('./scss/kp.scss')
} else {
    // tslint:disable-next-line
    require('./scss/style.scss')
}



@observer
class App extends React.Component {
    public render() {
        return (
            <ErrorBoundary>
                <StoreProvider>
                    <I18nextProvider i18n={i18n}>
                        {/* 
                        // @ts-ignore */}
                        <Full />
                    </I18nextProvider>
                </StoreProvider>

            </ErrorBoundary>
        )
    }
}

export default Sentry.withProfiler(App)
