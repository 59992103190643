import { observable, action, computed } from "mobx"
import { Driver } from "../../api"
import RootStore from "../RootStore"

class DriverModel {
    customerId: number
    departmentId: number
    id: number
    name: string
    @observable selected: boolean = false
    rootStore: RootStore

    constructor(customer: Driver, rootStore: RootStore) {
        this.rootStore = rootStore
        this.id = customer.id!
        this.customerId = customer.customerId!
        this.departmentId = customer.departmentId!
        this.name = customer.name!
    }

    @action setSelected = (val: boolean) => {
        this.rootStore.ui.selectedDriverModels.filter(d => d.customerId !== this.customerId).forEach(action("setSelectedDriver", (d: DriverModel) => { d.selected = false }))
        this.selected = val
    }

    @action toggleSelected = () => {
        this.rootStore.ui.selectedDriverModels.filter(d => d.customerId !== this.customerId).forEach(action("toggleSelectedDriver", (d: DriverModel) => { d.selected = false }))
        this.selected = !this.selected
    }

    @computed get visiable() {
        if (this.rootStore.ui.driverSearchText === "") {
            return true
        } else {
            if (this.name.toLocaleLowerCase().includes(this.rootStore.ui.driverSearchText.toLocaleLowerCase())) {
                return true
            } else {
                return false
            }
        }
    }
}
export default DriverModel