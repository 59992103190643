/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportFormats {
    PDF = 'PDF',
    EXCEL = 'EXCEL',
    EXCELOPENXML = 'EXCELOPENXML'
}

export function ReportFormatsFromJSON(json: any): ReportFormats {
    return ReportFormatsFromJSONTyped(json, false);
}

export function ReportFormatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportFormats {
    return json as ReportFormats;
}

export function ReportFormatsToJSON(value?: ReportFormats | null): any {
    return value as any;
}

