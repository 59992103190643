import React from 'react'
import ReactDOM from 'react-dom'
import {
    canUseDOM
} from 'reactstrap/src/utils'


class Portal extends React.Component<any> {
    defaultNode: any
    componentWillUnmount() {
        if (this.defaultNode) {
            document.body.removeChild(this.defaultNode)
        }
        this.defaultNode = null
    }

    render() {
        if (!canUseDOM) {
            return null
        }

        if (!this.props.node && !this.defaultNode) {
            this.defaultNode = document.createElement('div')
            document.body.appendChild(this.defaultNode)
        }

        return ReactDOM.createPortal(
            this.props.children,
            this.props.node || this.defaultNode
        )
    }
}

export default Portal