import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import { IBaseProps } from "../../types"

import {
    ModalHeader,
    ModalBody
} from 'reactstrap'
import Map from "../../components/Map/Map"
import DataChart from "../../components/Chart/DataChart"
import Modal from '../Modal'

class ViolationModal extends Component<IBaseProps> {

    render() {
        const t = this.props.t!
        const store = this.props.store!
        const toggle = store.modal.toggleViolationModal
        const mode = store.modal.violationModalMode
        return (
            <Modal
                isOpen={store.modal.isViolationModalVisible}
                toggle={toggle}
                size="lg"
            >
                {/* 
                        // @ts-ignore */}
                <ModalHeader toggle={toggle}>{t(mode === "location" ? "violationLocation" : "violationChart")} {store.ui.mapFocusedAction && <span className="small">{store.ui.getDriverName(store.ui.mapFocusedAction.driverId)}</span>}</ModalHeader>
                <ModalBody style={{ minWidth: "50vw" }}>
                    {store.ui.mapFocusedAction &&
                        (mode === "location" ?
                            <div style={{ height: "70vh" }}>
                                <Map focusedAction={store.ui.mapFocusedAction} />
                            </div>
                            :
                            <DataChart action={store.ui.mapFocusedAction!} />)
                    }
                </ModalBody>

            </Modal >

        )
    }
}

export default inject("store")(withTranslation("main")(observer(ViolationModal)))