/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface DriverPerformanceDTO
 */
export interface DriverPerformanceDTO {
    /**
     * 
     * @type {number}
     * @memberof DriverPerformanceDTO
     */
    day?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverPerformanceDTO
     */
    avgSafetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverPerformanceDTO
     */
    avgEcoGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverPerformanceDTO
     */
    avgTotalGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverPerformanceDTO
     */
    runingTime?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverPerformanceDTO
     */
    totalIdleTime?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverPerformanceDTO
     */
    totalTime?: number;
    /**
     * 
     * @type {Date}
     * @memberof DriverPerformanceDTO
     */
    firstIgniton?: moment.Moment;
    /**
     * 
     * @type {Date}
     * @memberof DriverPerformanceDTO
     */
    lastIgniton?: moment.Moment;
    /**
     * 
     * @type {number}
     * @memberof DriverPerformanceDTO
     */
    maxSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverPerformanceDTO
     */
    totalDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverPerformanceDTO
     */
    avgSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverPerformanceDTO
     */
    activityCount?: number;
}

export function DriverPerformanceDTOFromJSON(json: any): DriverPerformanceDTO {
    return DriverPerformanceDTOFromJSONTyped(json, false);
}

export function DriverPerformanceDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverPerformanceDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'day': !exists(json, 'day') ? undefined : json['day'],
        'avgSafetyGrade': !exists(json, 'avgSafetyGrade') ? undefined : json['avgSafetyGrade'],
        'avgEcoGrade': !exists(json, 'avgEcoGrade') ? undefined : json['avgEcoGrade'],
        'avgTotalGrade': !exists(json, 'avgTotalGrade') ? undefined : json['avgTotalGrade'],
        'runingTime': !exists(json, 'runingTime') ? undefined : json['runingTime'],
        'totalIdleTime': !exists(json, 'totalIdleTime') ? undefined : json['totalIdleTime'],
        'totalTime': !exists(json, 'totalTime') ? undefined : json['totalTime'],
        'firstIgniton': !exists(json, 'firstIgniton') ? undefined : moment(json['firstIgniton']),
        'lastIgniton': !exists(json, 'lastIgniton') ? undefined : moment(json['lastIgniton']),
        'maxSpeed': !exists(json, 'maxSpeed') ? undefined : json['maxSpeed'],
        'totalDistance': !exists(json, 'totalDistance') ? undefined : json['totalDistance'],
        'avgSpeed': !exists(json, 'avgSpeed') ? undefined : json['avgSpeed'],
        'activityCount': !exists(json, 'activityCount') ? undefined : json['activityCount'],
    };
}

export function DriverPerformanceDTOToJSON(value?: DriverPerformanceDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'day': value.day,
        'avgSafetyGrade': value.avgSafetyGrade,
        'avgEcoGrade': value.avgEcoGrade,
        'avgTotalGrade': value.avgTotalGrade,
        'runingTime': value.runingTime,
        'totalIdleTime': value.totalIdleTime,
        'totalTime': value.totalTime,
        'firstIgniton': value.firstIgniton === undefined ? undefined : (value.firstIgniton.toISOString()),
        'lastIgniton': value.lastIgniton === undefined ? undefined : (value.lastIgniton.toISOString()),
        'maxSpeed': value.maxSpeed,
        'totalDistance': value.totalDistance,
        'avgSpeed': value.avgSpeed,
        'activityCount': value.activityCount,
    };
}


