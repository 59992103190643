import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import {
    Row,
    Button,
    Col
} from 'reactstrap'
import { IBaseProps } from "../../types"


class DriverName extends Component<IBaseProps> {
    constructor(props: any) {
        super(props)
    }




    dropDate() {
        const t = this.props.t!
        const store = this.props.store!


        return (
            <React.Fragment>
                {
                    store.routing.routerState.routeName === "dashboard" ||
                        store.routing.routerState.routeName === "summary" ||
                        store.routing.routerState.routeName === "bestdrivers" ?
                        <Row className="mr-1 p-2 align-items-center" style={{ height: "55px" }}>
                            <Col md="auto">
                                <span className="drivername mb-0" > {store.ui.datePicker.format}<i className="fa fa-calendar ml-1 mr-1" aria-hidden="true" /></span>
                            </Col>
                        </Row>
                        :

                        <Row className="mr-1 p-2 align-items-center clickable" style={{ height: "55px" }} onClick={store.ui.toggleDriverPicker}>

                            {store.ui.currentDriverIds.length > 0 ?
                                store.routing.routerState.routeName === "driver" ?
                                    <React.Fragment>
                                        <Col md="auto">
                                            <span className="drivername mb-0" >{store.report.currentDriver!.name} <i className="fa fa-user mr-1 ml-1" aria-hidden="true" /> {store.ui.datePicker.format}<i className="fa fa-calendar ml-1 mr-1" aria-hidden="true" /></span>
                                        </Col>
                                        {store.ui.currentDriverIds.length > 1 &&
                                            <Col>
                                                <Button outline className="ml-1 mr-1" onClick={(e) => { e.stopPropagation(); store.report.changeDriver("prev") }}><i className="fa fa-chevron-left" aria-hidden="true"></i></Button>
                                                <Button outline className="ml-1" onClick={(e) => { e.stopPropagation(); store.report.changeDriver("next") }}><i className="fa fa-chevron-right" aria-hidden="true"></i></Button>
                                            </Col>
                                        }
                                    </React.Fragment>

                                    :
                                    <span className="drivername mb-0" >{
                                        store.ui.currentDriverIds.length > 1 ?
                                            <React.Fragment>{t("multipleDriver") + " (" + store.ui.currentDriverIds.length + ")"} <i className="fa fa-users ml-1 mr-1" aria-hidden="true" /></React.Fragment>
                                            :
                                            <React.Fragment>{store.ui.getDriver(store.ui.currentDriverIds[0])!.name} <i className="fa fa-user mr-1 ml-1" aria-hidden="true" /></React.Fragment>
                                    } {store.ui.datePicker.format}  <i className="fa fa-calendar ml-1 mr-1" aria-hidden="true" /></span>

                                :

                                <span className="drivername mb-0">{t("chooseDrivers")}</span>
                            }
                        </Row>


                }
            </React.Fragment>

        )
    }

    render() {
        return (
            this.dropDate()
        )
    }
}


export default inject("store")(withTranslation("main")(observer(DriverName)))
