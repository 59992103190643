/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    RiskDegree,
    RiskDegreeFromJSON,
    RiskDegreeFromJSONTyped,
    RiskDegreeToJSON,
} from './';

/**
 * 
 * @export
 * @interface RiskDegreeTrendDTO
 */
export interface RiskDegreeTrendDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof RiskDegreeTrendDTO
     */
    driverId?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof RiskDegreeTrendDTO
     */
    gradeType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskDegreeTrendDTO
     */
    interval?: string | null;
    /**
     * 
     * @type {Array<RiskDegree>}
     * @memberof RiskDegreeTrendDTO
     */
    riskDegreeTrendList?: Array<RiskDegree> | null;
}

export function RiskDegreeTrendDTOFromJSON(json: any): RiskDegreeTrendDTO {
    return RiskDegreeTrendDTOFromJSONTyped(json, false);
}

export function RiskDegreeTrendDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskDegreeTrendDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'gradeType': !exists(json, 'gradeType') ? undefined : json['gradeType'],
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
        'riskDegreeTrendList': !exists(json, 'riskDegreeTrendList') ? undefined : (json['riskDegreeTrendList'] === null ? null : (json['riskDegreeTrendList'] as Array<any>).map(RiskDegreeFromJSON)),
    };
}

export function RiskDegreeTrendDTOToJSON(value?: RiskDegreeTrendDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driverId': value.driverId,
        'gradeType': value.gradeType,
        'interval': value.interval,
        'riskDegreeTrendList': value.riskDegreeTrendList === undefined ? undefined : (value.riskDegreeTrendList === null ? null : (value.riskDegreeTrendList as Array<any>).map(RiskDegreeToJSON)),
    };
}


