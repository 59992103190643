import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { IBaseProps } from "../../types"
import {
    Card,
    CardHeader,
    Table
} from 'reactstrap'
import { observable, action } from 'mobx'
import { Actioncount } from '../../api'
import Loading from '../Loading'
import actionTypeToString from '../../utils/actionTypeToString'


class ActionCounts extends Component<IBaseProps & { driverID?: number, allDrivers?: boolean }> {

    @observable report: Actioncount[] | null = null
    @observable reportReady: boolean = false

    @action getReport = () => {
        this.reportReady = false
        const store = this.props.store!
        const driverID = this.props.driverID

        let start
        let end
        let drivers = driverID ? [driverID] : store.ui.currentDriverIds
        if (store.ui.datePicker) {
            start = store.ui.datePicker.start.utc().format("YYYY-MM-DD HH:mm:ss")
            end = store.ui.datePicker.end.utc().format("YYYY-MM-DD HH:mm:ss")

        }
        if (this.props.allDrivers) {
            drivers = store.ui.allDriverIds
        }

        store.api.getActioncounts(drivers, start, end).then(action("getActioncounts",
            (data: Actioncount[]) => {
                this.report = data
                this.reportReady = true
            })).catch(response => { store.check401(response) }).catch(response => console.error(response))


    }

    componentDidMount() {
        this.getReport()

    }
    componentDidUpdate(prevProps) {
        if (prevProps.driverID !== this.props.driverID) {
            this.getReport()
        }
    }

    renderTable = () => {
        const t = this.props.t!
        return (
            <Table hover className="mb-0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th >{t("violationType")}</th>
                        <th>{t("count")}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.report!.map((d, i) =>
                        <tr key={"actionCounts-" + i}>
                            <th scope="row">{i + 1}</th>
                            <td>{t(actionTypeToString(d.type))}</td>
                            <td>{d.count}</td>
                        </tr>
                    )}

                </tbody>
            </Table>
        )

    }

    render() {
        const t = this.props.t!



        return (
            <Card>
                <CardHeader><span className="h5">{t("actionCounts")}</span>
                    <div className="float-right">
                        {/* 
                        <HelpPopover id={gradetype + "-" + type + "bestHelp"}>
                            <p>Seçilen sürücüler arasında {t(gradetype)} en {type === "best" ? (gradetype === "tiredTime" ? "düşük" : "yüksek") : (gradetype === "tiredTime" ? "yüksek" : "düşük")} 5 sürücünün listesidir.</p>
                        </HelpPopover> */}
                    </div>
                </CardHeader>
                <Loading ready={this.reportReady} icon="fa-table" list={this.report as any[]} object={this.report} emptyPromt="noDataInDateInterval" height="30vh" renderFunc={this.renderTable} />
            </Card>
        )
    }

}
export default inject("store")(withTranslation("main")(observer(ActionCounts)))