import {
    observable, action, computed,
} from 'mobx'
import RootStore from "./RootStore"
import { TimeSeries, TimeRange } from "pondjs"
import moment from "../utils/moment"



class ChartStore {
    rootStore: RootStore
    @observable tracker: Date | undefined = undefined
    @observable timerange: TimeRange = null
    @observable minTime: Date | undefined = undefined
    @observable maxTime: Date | undefined = undefined
    @observable minDuration: number = 60000
    @observable maxDuration: number = 300000


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action reset = () => {
        this.tracker = undefined
        this.minTime = undefined
        this.maxTime = undefined
        this.timerange = undefined
    }

    @action updateTracker = (t: Date) => {
        this.tracker = t
    }

    @action setTracker = (t: Date) => {
        if (this.timerange) {
            let duration = this.timerange.duration()
            let start = moment(t).subtract(10, "s").toDate()
            let end = moment(start).add(duration, "ms").toDate()
            let range = this.timerange.setBegin(start)
            range = range.setEnd(end)
            this.timerange = range
            this.tracker = t
        }

    }

    @action updateMinTime = (t: Date) => {
        this.minTime = t
    }

    @action updateMaxTime = (t: Date) => {
        this.maxTime = t
    }

    @action updateTimeRange = (tr) => {
        this.timerange = tr
    }

    @action updateRange = (range: TimeSeries) => {
        if (range.duration() > this.maxDuration) {
            let newRange = range.setEnd(moment(range.begin()).add(this.maxDuration, "milliseconds").toDate())
            this.updateTimeRange(newRange)
        } else {
            this.updateTimeRange(range)
        }
        this.updateMaxTime(range.end())
        this.updateMinTime(range.begin())
    }

    @computed get channels() {
        if (this.rootStore && this.rootStore.ui.chartReady) {
            let object: any = {
                speed: { units: "km/s", label: "speed", format: ",.0f", series: this.rootStore.ui.speeds, show: true, axis: "speed_axis" },
            }
            if (this.rootStore.ui.isSpeedListCalibrated) {
                object = {
                    speed: { units: "km/s", label: "speed", format: ",.0f", series: this.rootStore.ui.speeds, show: true, axis: "speed_axis" },
                    gx: { units: "g", label: "gx", format: ",.4f", series: this.rootStore.ui.gxs, show: true, axis: "g_axis" },
                    gy: { units: "g", label: "gy", format: ",.4f", series: this.rootStore.ui.gys, show: true, axis: "g_axis" },
                    gz: { units: "g", label: "gz", format: ",.4f", series: this.rootStore.ui.gzs, show: true, axis: "g_axis" }
                }
            }

            return object
        }
        return {}
    }

    @computed get axises() {
        if (this.rootStore && this.rootStore.ui.chartReady) {
            let object = {
                speed: { units: "km/s", label: "speed", format: ",.0f", max: this.rootStore.ui.speeds.max("speed"), visible: true },
                g: { units: "g", label: "gForce", format: ",.1f", max: 1.1, min: -1, right: true, visible: this.rootStore.ui.isSpeedListCalibrated }
            }

            return object
        }
        return {}
    }

}

export default ChartStore