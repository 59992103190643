/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface GetSpeedByActivityIdDTO
 */
export interface GetSpeedByActivityIdDTO {
    /**
     * 
     * @type {Date}
     * @memberof GetSpeedByActivityIdDTO
     */
    gsensorDate?: moment.Moment;
    /**
     * 
     * @type {number}
     * @memberof GetSpeedByActivityIdDTO
     */
    speed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetSpeedByActivityIdDTO
     */
    x?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetSpeedByActivityIdDTO
     */
    y?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetSpeedByActivityIdDTO
     */
    z?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetSpeedByActivityIdDTO
     */
    activityId?: number | null;
}

export function GetSpeedByActivityIdDTOFromJSON(json: any): GetSpeedByActivityIdDTO {
    return GetSpeedByActivityIdDTOFromJSONTyped(json, false);
}

export function GetSpeedByActivityIdDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSpeedByActivityIdDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gsensorDate': !exists(json, 'gsensorDate') ? undefined : moment(json['gsensorDate']),
        'speed': !exists(json, 'speed') ? undefined : json['speed'],
        'x': !exists(json, 'x') ? undefined : json['x'],
        'y': !exists(json, 'y') ? undefined : json['y'],
        'z': !exists(json, 'z') ? undefined : json['z'],
        'activityId': !exists(json, 'activityId') ? undefined : json['activityId'],
    };
}

export function GetSpeedByActivityIdDTOToJSON(value?: GetSpeedByActivityIdDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gsensorDate': value.gsensorDate === undefined ? undefined : (value.gsensorDate.toISOString()),
        'speed': value.speed,
        'x': value.x,
        'y': value.y,
        'z': value.z,
        'activityId': value.activityId,
    };
}


