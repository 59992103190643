/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    DriverInfoDTO,
    DriverInfoDTOFromJSON,
    DriverInfoDTOFromJSONTyped,
    DriverInfoDTOToJSON,
    DriverPerformanceDTO,
    DriverPerformanceDTOFromJSON,
    DriverPerformanceDTOFromJSONTyped,
    DriverPerformanceDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface DriverPerformanceResponseDTO
 */
export interface DriverPerformanceResponseDTO {
    /**
     * 
     * @type {DriverInfoDTO}
     * @memberof DriverPerformanceResponseDTO
     */
    driverInfo?: DriverInfoDTO;
    /**
     * 
     * @type {Array<DriverPerformanceDTO>}
     * @memberof DriverPerformanceResponseDTO
     */
    driverPerformanceList?: Array<DriverPerformanceDTO> | null;
}

export function DriverPerformanceResponseDTOFromJSON(json: any): DriverPerformanceResponseDTO {
    return DriverPerformanceResponseDTOFromJSONTyped(json, false);
}

export function DriverPerformanceResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverPerformanceResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driverInfo': !exists(json, 'driverInfo') ? undefined : DriverInfoDTOFromJSON(json['driverInfo']),
        'driverPerformanceList': !exists(json, 'driverPerformanceList') ? undefined : (json['driverPerformanceList'] === null ? null : (json['driverPerformanceList'] as Array<any>).map(DriverPerformanceDTOFromJSON)),
    };
}

export function DriverPerformanceResponseDTOToJSON(value?: DriverPerformanceResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driverInfo': DriverInfoDTOToJSON(value.driverInfo),
        'driverPerformanceList': value.driverPerformanceList === undefined ? undefined : (value.driverPerformanceList === null ? null : (value.driverPerformanceList as Array<any>).map(DriverPerformanceDTOToJSON)),
    };
}


