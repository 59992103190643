/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface BestDriversDTO
 */
export interface BestDriversDTO {
    /**
     * 
     * @type {string}
     * @memberof BestDriversDTO
     */
    driverName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BestDriversDTO
     */
    grade?: number;
    /**
     * 
     * @type {number}
     * @memberof BestDriversDTO
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof BestDriversDTO
     */
    driverDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof BestDriversDTO
     */
    driverDuration?: number;
}

export function BestDriversDTOFromJSON(json: any): BestDriversDTO {
    return BestDriversDTOFromJSONTyped(json, false);
}

export function BestDriversDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BestDriversDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'grade': !exists(json, 'grade') ? undefined : json['grade'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'driverDistance': !exists(json, 'driverDistance') ? undefined : json['driverDistance'],
        'driverDuration': !exists(json, 'driverDuration') ? undefined : json['driverDuration'],
    };
}

export function BestDriversDTOToJSON(value?: BestDriversDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driverName': value.driverName,
        'grade': value.grade,
        'count': value.count,
        'driverDistance': value.driverDistance,
        'driverDuration': value.driverDuration,
    };
}


