import React from 'react'
import { withTranslation } from 'react-i18next'
import {
    Container, Row, Col, Button,
} from 'reactstrap'
import * as Sentry from '@sentry/react'
import { IBaseProps } from './types'

class ErrorBoundary extends React.Component<IBaseProps, { hasError: boolean, eventId: any }> {
    constructor(props) {
        super(props)
        this.state = { hasError: false, eventId: null }
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true })
        // You can also log the error to an error reporting service
        console.log(error, info)
        Sentry.withScope(scope => {
            scope.setExtras(info)
            const eventId = Sentry.captureException(error)
            this.setState({ eventId })
        })
    }

    render() {
        const t = this.props.t!
        if (this.state.hasError) {
            return (
                <div className="app flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="6">
                                <span className="clearfix">

                                    <h4 className="pt-3">{t('somethingBadHappened')}</h4>
                                    <Button
                                        color="link"
                                        className="float-left"
                                        onClick={() => {
                                            window.location.href = '/'
                                        }}
                                    >
                                        {t('goHomePage')}
                                    </Button>
                                    <Button
                                        onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId, lang: "tr" })}
                                    >
                                        Hatayı bildir
                  </Button>
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }
        return this.props.children
    }
}

export default withTranslation("main")(ErrorBoundary)
