/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface GradeCategoryByDriverDTO
 */
export interface GradeCategoryByDriverDTO {
    /**
     * 
     * @type {number}
     * @memberof GradeCategoryByDriverDTO
     */
    low?: number;
    /**
     * 
     * @type {number}
     * @memberof GradeCategoryByDriverDTO
     */
    med?: number;
    /**
     * 
     * @type {number}
     * @memberof GradeCategoryByDriverDTO
     */
    med2?: number;
    /**
     * 
     * @type {number}
     * @memberof GradeCategoryByDriverDTO
     */
    high?: number;
}

export function GradeCategoryByDriverDTOFromJSON(json: any): GradeCategoryByDriverDTO {
    return GradeCategoryByDriverDTOFromJSONTyped(json, false);
}

export function GradeCategoryByDriverDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GradeCategoryByDriverDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'low': !exists(json, 'low') ? undefined : json['low'],
        'med': !exists(json, 'med') ? undefined : json['med'],
        'med2': !exists(json, 'med2') ? undefined : json['med2'],
        'high': !exists(json, 'high') ? undefined : json['high'],
    };
}

export function GradeCategoryByDriverDTOToJSON(value?: GradeCategoryByDriverDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'low': value.low,
        'med': value.med,
        'med2': value.med2,
        'high': value.high,
    };
}


