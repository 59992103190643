import React from 'react'
import Loadable from 'react-loadable'
import logger from './Logger'
import Spinner from '../components/Spinner/Spinner'

const Loading = (props: any) => {
    if (props.error) {
        logger.error(props.error)
        return <div>Page Load Error!</div>
    }
    if (props.pastDelay) {
        return <Spinner height={"70vh"} />
    }
    return null
}

export default function Loader(opts: any) {
    return Loadable(Object.assign({
        loading: Loading,
        delay: 300,
        timeout: 10,
    }, opts))
}

