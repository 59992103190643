/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface GradeDTO
 */
export interface GradeDTO {
    /**
     * 
     * @type {number}
     * @memberof GradeDTO
     */
    gradeStart?: number;
    /**
     * 
     * @type {number}
     * @memberof GradeDTO
     */
    gradeEnd?: number;
    /**
     * 
     * @type {number}
     * @memberof GradeDTO
     */
    count?: number;
}

export function GradeDTOFromJSON(json: any): GradeDTO {
    return GradeDTOFromJSONTyped(json, false);
}

export function GradeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GradeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gradeStart': !exists(json, 'gradeStart') ? undefined : json['gradeStart'],
        'gradeEnd': !exists(json, 'gradeEnd') ? undefined : json['gradeEnd'],
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function GradeDTOToJSON(value?: GradeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gradeStart': value.gradeStart,
        'gradeEnd': value.gradeEnd,
        'count': value.count,
    };
}


