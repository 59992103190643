/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface Driver
 */
export interface Driver {
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    msisdn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    customerId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    departmentId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    status?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Driver
     */
    myiugo?: boolean | null;
}

export function DriverFromJSON(json: any): Driver {
    return DriverFromJSONTyped(json, false);
}

export function DriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): Driver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'msisdn': !exists(json, 'msisdn') ? undefined : json['msisdn'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'myiugo': !exists(json, 'myiugo') ? undefined : json['myiugo'],
    };
}

export function DriverToJSON(value?: Driver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'msisdn': value.msisdn,
        'name': value.name,
        'email': value.email,
        'customerId': value.customerId,
        'departmentId': value.departmentId,
        'status': value.status,
        'myiugo': value.myiugo,
    };
}


