import {
    observable, action, toJS,
} from 'mobx'
import RootStore from "./RootStore"
import { ReportSchedule, Report } from "../api/api"
import ReportScheduleModel from './Models/ReportScheduleModel'
import saveAs from 'file-saver'


class ReportSettingsStore {
    rootStore: RootStore

    @observable reportList: ReportScheduleModel[] = []
    @observable selectedReport: ReportSchedule

    createdReportKey: number = 1
    @observable createdReportList: Report[] = []
    @observable createdReportListReady = false


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action toggleTypes = (id: string) => {
        let index = this.selectedReport.types.indexOf(id)
        if (index === -1) {
            if (id === "DriverReport") {
                this.selectedReport.format = "pdf"
            } else if (id === "DriverPerf") {
                this.selectedReport.interval = "monthly"
            }
            this.selectedReport.types.push(id)
        } else {
            this.selectedReport.types.splice(index, 1)
        }
    }

    @action toggleCustomer = (id: number) => {
        let index = this.selectedReport.customerIds.indexOf(id)
        if (index === -1) {
            this.selectedReport.customerIds.push(id)
        } else {
            this.selectedReport.customerIds.splice(index, 1)
        }
    }

    @action toggleDepartment = (id: number) => {
        let index = this.selectedReport.departmentIds.indexOf(id)
        if (index === -1) {
            this.selectedReport.departmentIds.push(id)
        } else {
            this.selectedReport.departmentIds.splice(index, 1)
        }
    }

    @action deleteReport(obj: ReportScheduleModel) {
        if (obj.id > 0) {
            this.rootStore.api.deleteReportschedule(obj.id).then(action("deleteReportschedule", (data: boolean) => {
                if (data) {
                    if (this.selectedReport === obj) {
                        // @ts-ignore
                        this.selectedReport = undefined
                    }
                    let index = this.reportList.indexOf(obj)
                    if (index !== -1) {
                        this.reportList.splice(index, 1)
                    }
                }
            })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
        }
    }

    @action getScheduleList() {
        this.rootStore.api.getReportschedule().then(action("getReportSchedule", (data: ReportSchedule[]) => {

            if (data) {
                this.reportList = data.map(e => new ReportScheduleModel(e))
            }
        })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
    }

    @action getCreatedReportList(id) {
        this.createdReportKey++
        let key = this.createdReportKey
        this.createdReportListReady = false
        this.createdReportList = []
        this.rootStore.api.getReportscheduleReports(id).then(action("getReportscheduleReports", (data: Report[]) => {
            if (key === this.createdReportKey) {
                if (data) {
                    this.createdReportList = data
                }
                this.createdReportListReady = true
            }
        })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
    }

    @action selectReport(obj: ReportScheduleModel) {
        if (this.selectedReport !== obj && obj.id > 0) {
            this.getCreatedReportList(obj.id)
        }
        this.selectedReport = obj

    }

    @action newReport() {
        this.selectedReport = new ReportScheduleModel()
    }

    @action saveReport() {
        let report: ReportSchedule = toJS(this.selectedReport)
        this.rootStore.api.postReportschedule(report.id, JSON.stringify(report)).then(data => {
            if (report.id === -1) {
                // @ts-ignore
                this.selectedReport = undefined
                this.getScheduleList()
            }
        }).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
    }

    @action downloadReport(reportId: string, format: string) {
        this.rootStore.api.reportDownload(reportId).then((data) => {
            if (data) {
                saveAs(data, reportId + format)
            }


        }).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
    }

}

export default ReportSettingsStore