/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface ViolationDTO
 */
export interface ViolationDTO {
    /**
     * 
     * @type {string}
     * @memberof ViolationDTO
     */
    driverName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationDTO
     */
    driverId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationDTO
     */
    type?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationDTO
     */
    totalTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationDTO
     */
    count?: number | null;
}

export function ViolationDTOFromJSON(json: any): ViolationDTO {
    return ViolationDTOFromJSONTyped(json, false);
}

export function ViolationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViolationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'totalTime': !exists(json, 'totalTime') ? undefined : json['totalTime'],
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function ViolationDTOToJSON(value?: ViolationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driverName': value.driverName,
        'driverId': value.driverId,
        'type': value.type,
        'totalTime': value.totalTime,
        'count': value.count,
    };
}


