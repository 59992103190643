import React, { Component } from 'react'
import { InputGroup, Input, Button } from 'reactstrap'
import { observer, inject } from 'mobx-react'
import { IBaseProps } from '../../../types'
import config from '../../../config'
import * as url from "url"
import * as portableFetch from "portable-fetch"
import { observable } from 'mobx'

/*http://sds.devtakip.com/?token=921153c3-0db0-4e81-a514-24cf3be98d56*/
class LoginForm extends Component<IBaseProps> {
    @observable User = ""
    @observable Pass = ""
    handleSubmit = () => {
        const store = this.props.store!
        const path = `https://apis.devtakip.com/auth?email=aa&password=aa&theme=devtakip&expireOldSession=true`
        let urlPath = url.parse(path, true)
        delete urlPath.search
        urlPath.query.password = this.Pass
        urlPath.query.email = this.User

        portableFetch(url.format(urlPath), {
            method: 'POST'
        }).then((response) => {
            return response.json()
        }).then((json) => {
            store.login(json.token).then(() => store.routing.goTo(config.homepage))
        }).catch((ex) => {
            console.log('parsing failed', ex)
        })
    }
    render() {
        return (
            <React.Fragment>
                <p className="text-muted">Hesabınıza giriş yapın</p>
                <InputGroup className="mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className="fa fa-user"></i>
                        </span>
                    </div>
                    <Input id="username" type="text" placeholder="KullanıcıAdı" onChange={(event) => { this.User = event.target.value }} />
                </InputGroup>
                <InputGroup className="mb-4">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className="fa fa-lock"></i>
                        </span>
                    </div>
                    <Input id="password" type="password" placeholder="Şifre" onChange={(event) => { this.Pass = event.target.value }} />
                </InputGroup>

                <Button color="primary" className="px-4" onClick={this.handleSubmit}>Giriş</Button>

            </React.Fragment>
        )
    }
}

export default inject("store")(observer(LoginForm))
