import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import { IBaseProps } from "../../types"

import {
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    InputGroup,
    InputGroupAddon,
    ModalFooter,
    Button
} from 'reactstrap'
import { observable, action } from 'mobx'

class PdfReportModal extends Component<IBaseProps & { reportType: string }> {
    @observable email: string

    handleSubmit = () => {
        const store = this.props.store!
        store.api.reportmailLeague(this.props.reportType, "pdf", this.email, store.ui.currentDriverIds, store.ui.datePicker.asJson.start, store.ui.datePicker.asJson.end, store.ui.datePicker.asJson.unit)
        store.modal.togglePdfReportModal()
    }
    render() {
        const t = this.props.t!
        const store = this.props.store!
        const toggle = store.modal.togglePdfReportModal


        return (
            <Modal
                isOpen={store.modal.isPdfReportModalVisible}
                toggle={toggle}
                size="lg"
            >
                <ModalHeader toggle={toggle}>{t("pdfMail")}</ModalHeader>
                <ModalBody>
                    <span>{t("pdfMailDetail")}</span>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">E-Posta</InputGroupAddon>
                        <Input name="mail" placeholder="E-Posta" value={this.email} onChange={action("emailChange", (e: any) => { this.email = e.target.value })} />
                    </InputGroup>
                </ModalBody>
                <ModalFooter className="driver-picker-footer">
                    <Button color="primary" onClick={this.handleSubmit}>{t("choose")}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>{t("cancel")}</Button>
                </ModalFooter>
            </Modal >
        )
    }
}

export default inject("store")(withTranslation("main")(observer(PdfReportModal)))