import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, action } from "mobx"
import RSpinner from "react-spinkit"

interface IProps {
    delay: number,
    height?: string | number
}

class Spinner extends Component<IProps> {
    static defaultProps = {
        delay: 500
    }

    timer: NodeJS.Timer

    @observable display = false

    constructor(props: IProps) {
        super(props)
        this.enableMessage = this.enableMessage.bind(this)
        this.timer = setTimeout(this.enableMessage, props.delay)
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    @action enableMessage = () => {
        this.display = true
    }

    render() {
        return this.display ?
            <div style={{ height: this.props.height || undefined }} className="d-flex justify-content-center align-items-center">
                <RSpinner name="line-scale-pulse-out" className="text-blue" fadeIn="none" />
            </div>
            : null
    }
}

export default observer(Spinner)