/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface FleetTrendDTO
 */
export interface FleetTrendDTO {
    /**
     * 
     * @type {number}
     * @memberof FleetTrendDTO
     */
    avgEcoGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetTrendDTO
     */
    avgSafetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetTrendDTO
     */
    avgTotalGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetTrendDTO
     */
    count?: number;
    /**
     * 
     * @type {Date}
     * @memberof FleetTrendDTO
     */
    startDateTime?: moment.Moment;
}

export function FleetTrendDTOFromJSON(json: any): FleetTrendDTO {
    return FleetTrendDTOFromJSONTyped(json, false);
}

export function FleetTrendDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FleetTrendDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avgEcoGrade': !exists(json, 'avgEcoGrade') ? undefined : json['avgEcoGrade'],
        'avgSafetyGrade': !exists(json, 'avgSafetyGrade') ? undefined : json['avgSafetyGrade'],
        'avgTotalGrade': !exists(json, 'avgTotalGrade') ? undefined : json['avgTotalGrade'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : moment(json['startDateTime']),
    };
}

export function FleetTrendDTOToJSON(value?: FleetTrendDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avgEcoGrade': value.avgEcoGrade,
        'avgSafetyGrade': value.avgSafetyGrade,
        'avgTotalGrade': value.avgTotalGrade,
        'count': value.count,
        'startDateTime': value.startDateTime === undefined ? undefined : (value.startDateTime.toISOString()),
    };
}


