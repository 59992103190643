import {
    observable, action, computed,
} from 'mobx'
import RootStore from "./RootStore"
import moment from "../utils/moment"
import { Activity, Location, Speed, Action, Driver, Violation, Department, CustomerTree } from "../api"
import { TimeSeries, } from "pondjs"
import { DatePickerData } from "./Models/DatePickerData"
import isequal from "lodash.isequal"
import { LatLngBounds, polyline } from 'leaflet'
import CustomerModel from './Models/CustomerModel'
import DepartmentModel from './Models/DepartmentModel'
import DriverModel from './Models/DriverModel'

class UiStore {
    rootStore: RootStore
    speedKey: number = 1
    routeKey: number = 1
    actionKey: number = 1
    activityKey: number = 1
    treeKey: number = 1


    @observable rootCustomer: CustomerModel
    @observable treeReady: boolean = false

    @observable customers: CustomerModel[] = []
    @observable departments: DepartmentModel[] = []
    @observable drivers: DriverModel[] = []
    @observable driverSearchText = ""

    @observable isDriverPickerVisible: boolean = false

    @observable datePicker: DatePickerData = new DatePickerData("day", moment().subtract(1, 'days').endOf('day'), moment().subtract(1, 'days').startOf('day'), "yesterday")

    @observable tempDatePicker: DatePickerData

    @observable currentViolation?: Violation

    @observable mapFocusCircle: [number, number] | undefined = undefined
    @observable currentDriverIds: number[] = []
    @observable currentActivity: Activity | undefined = undefined
    @observable mapReady: boolean = false
    @observable mapZoom: number = 12
    @observable mapFocusedAction?: Action = undefined
    @observable locationList: Location[] = []
    @observable chartReady: boolean = false
    @observable speedList: Speed[] = []
    @observable actionList: Action[] = []
    tempActivityId: number


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action toggleDriverPicker = () => {
        this.driverSearchText = ""
        this.isDriverPickerVisible = !this.isDriverPickerVisible
    }

    @computed get allDriverIds() {
        return this.drivers.map(d => d.id)
    }


    parseCustomer = (response: CustomerTree, customer: CustomerModel) => {
        let childs = response.customers.filter(d => d.parentId === customer.customerId)
        // this.customers.push(customer)
        if (childs && childs.length > 0) {
            childs.forEach(c => {
                let cus = new CustomerModel(c, this.rootStore)
                this.parseCustomer(response, cus)
                customer.childs.push(cus)
            })
        }
        let departments = response.departments.filter(d => d.customerId === customer.customerId.toString())
        if (departments && departments.length > 0) {
            departments.forEach(de => {
                let dep = new DepartmentModel(de, this.rootStore)
                // this.departments.push(dep)
                let drivers = response.drivers.filter(d => d.customerId === customer.customerId && d.departmentId === dep.departmentId)
                drivers.forEach(d => {
                    const dM = new DriverModel(d, this.rootStore)
                    this.drivers.push(dM)
                    dep.drivers.push(dM)
                })
                dep.drivers = dep.drivers.sort((a, b) => a.name.localeCompare(b.name))
                if (dep.drivers && dep.drivers.length > 0) {
                    customer.departments.push(dep)
                }
                this.departments.push(dep)
            }
            )
        }
        let departmentLessDrivers = response.drivers.filter(d => d.customerId === customer.customerId && !d.departmentId)
        if (departmentLessDrivers && departmentLessDrivers.length > 0) {
            let dep = new DepartmentModel({ customerId: customer.customerId as unknown as string, departmentId: "-1", name: "others" } as Department, this.rootStore)
            // this.departments.push(dep)
            departmentLessDrivers.forEach(d => {
                const dM = new DriverModel(d, this.rootStore)
                this.drivers.push(dM)
                dep.drivers.push(dM)
            })
            dep.drivers = dep.drivers.sort((a, b) => a.name.localeCompare(b.name))
            customer.departments.push(dep)
        }
        customer.departments = customer.departments.sort((a, b) => a.name.localeCompare(b.name))
        customer.childs = customer.childs.sort((a, b) => a.customerName.localeCompare(b.customerName))
        this.customers.push(customer)
    }

    @action getDepartmentDrivers = () => {
        this.treeReady = false
        this.treeKey++
        let key = this.treeKey
        console.log(key)

        this.rootStore.api.getCustomerTree().then(action("setTree", (response: CustomerTree) => {
            if (key === this.treeKey && response) {
                console.log(key)
                this.treeReady = true
                this.drivers = []
                this.departments = []
                this.customers = []
                this.rootCustomer = new CustomerModel(response.customers[0], this.rootStore)
                this.parseCustomer(response, this.rootCustomer)
            }
        })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
    }

    @action selectDatePicker = (datePicker: DatePickerData) => {
        this.datePicker = datePicker
        /*
        // update url if datepicker has value
        if (datePicker) {
          this.rootStore.routing.history.push({
            pathname: this.rootStore.routing.history.location.pathname,
            search: `?${qs.stringify({ date: toJS(datePicker.asJson) })}`,
          })
        }
        */
        /*
         if (this.currentDriverIds.length > 0) {
           this.rootStore.report.getActionList()
           this.getActivityList({ driverIds: this.currentDriverIds })
         }
         */
    }


    @action getRouteList(activityId: number, callback?: () => void) {
        this.routeKey++
        let key = this.routeKey
        this.rootStore.api.getRouteByActivityId(activityId).then(action("getRouteByActivityId",
            (data: Location[]) => {
                if (key === this.routeKey) {
                    // @ts-ignore
                    this.locationList.clear()
                    this.locationList = data ? data : []// .sort((a: Location, b: Location) => moment.utc(a.dataDate!).getTime() - moment.utc(b.dataDate!).getTime())
                    this.mapReady = true
                    if (callback) {
                        callback()
                    }
                }
            })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
    }

    @action getSpeedList(activityId: number, callback?: () => void) {
        this.chartReady = false
        this.speedKey++
        let key = this.speedKey
        this.rootStore.api.getSpeedByActivityId(activityId).then(action("getSpeedByActivityId",
            (data: Speed[]) => {
                if (key === this.speedKey) {
                    // @ts-ignore
                    this.speedList.clear()
                    this.speedList = data ? data : []// .sort((a: Speed, b: Speed) => moment.utc(a.gsensorDate!).getTime() - moment.utc(b.gsensorDate!).getTime())
                    this.chartReady = true
                    if (callback) {
                        callback()
                    }

                }
            })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
    }

    @action getActionList(activityId: number, callback?: () => void) {
        this.actionList = []
        this.actionKey++
        let key = this.actionKey
        this.rootStore.api.listActionByActivityId(activityId).then(action("listActionByActivityId",
            (data: Action[]) => {
                if (key === this.actionKey) {
                    // @ts-ignore
                    this.actionList.clear()
                    this.actionList = data ? data : []
                    if (callback) {
                        callback()
                    }
                }
            })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
    }

    @action resetActivity = () => {
        this.mapReady = false
        this.chartReady = false
        this.actionList = []
        this.locationList = []
        this.currentActivity = undefined
        this.clearMapFocus()
    }

    @action clearActivity = () => {
        this.mapReady = false
        this.chartReady = false
        this.actionList = []
        this.locationList = []
        this.currentActivity = undefined
        this.clearMapFocus()
    }

    @action resetDriver = () => {
        this.currentDriverIds = []
        this.resetActivity()
    }

    @action setDrivers(driverIds: number[]) {
        if (driverIds && !isequal(driverIds, this.currentDriverIds.slice())) {
            if (driverIds.length > 0) {
                // this.resetActivity()
                this.currentDriverIds = driverIds.slice()
                driverIds.forEach(d => {
                    const driverModel = this.drivers.find(dM => dM.id === d)
                    if (driverModel && !driverModel.selected) {
                        driverModel.setSelected(true)
                    }
                })
                // this.getActivityList({ driverIds })
            } else {
                this.resetDriver()
            }
        }
    }

    @action setActivity(index: Activity) {
        if (this.currentActivity !== index) {
            this.clearActivity()
            this.currentActivity = index
            this.tempActivityId = index.activityId!
            this.getRouteList(index.activityId!)
            this.getSpeedList(index.activityId!)
            this.getActionList(index.activityId!)
        }

    }

    lerp = (a, b, n) => {
        return (1 - n) * a + n * b
    }

    @action setMapFocus(index: number) {
        let locStart = this.locationList[index]
        /*
            if (index + 1 < this.locationList.length) {
               if (moment(this.rootStore.chart.tracker) < moment.utc(locStart.dataDate)) {
              this.mapFocusCircle = [locStart.lat!, locStart.lon!]
              } else {
                let locEnd = this.locationList[index + 1]
        
                const duration1 = moment.duration(moment(this.rootStore.chart.tracker).diff(moment.utc(locStart.dataDate))).asSeconds()
                const range = moment.duration(moment.utc(locEnd.dataDate).diff(moment.utc(locStart.dataDate))).asSeconds()
                const n = duration1 / range
        
                const lat = this.lerp(locStart.lat, locEnd.lat, n)
                const lon = this.lerp(locStart.lon, locEnd.lon, n)
                this.mapFocusCircle = [lat, lon]
              }
            }
            else {
              this.mapFocusCircle = [locStart.lat!, locStart.lon!]
            }
        */
        // @ts-ignore
        this.mapFocusCircle = locStart
    }

    @action clearMapFocus() {
        this.mapFocusCircle = undefined
    }

    @computed get coordinates(): Array<[number, number]> {
        if (this.locationList && this.locationList.length > 0) {
            return this.locationList.map((r): [number, number] => [r.lat!, r.lon!])
        }
        else {
            return []
        }
    }

    @computed get coordinatesBounds(): LatLngBounds | undefined {
        if (this.coordinates && this.coordinates.length > 0) {
            let line = polyline(this.coordinates)
            let bounds = line.getBounds()
            let sw = bounds.getSouthWest()
            let ne = bounds.getNorthEast()
            if (bounds.isValid() && (!sw.equals(ne))) {
                return bounds
            }
            else {
                return undefined
            }
        }
        else {
            return undefined
        }
    }

    @computed get coordinateSeries(): TimeSeries {
        if (this.locationList && this.locationList.length > 0) {
            let data = this.locationList.map((r) => {
                return [
                    moment.utc(r.dataDate!).valueOf(),
                    1.2
                ]
            })
            let ts = new TimeSeries({
                name: "coordinates",
                columns: ["time", "lat"],
                points: data
            })
            return ts
        }
        else {
            return new TimeSeries()
        }
    }

    @computed get isSpeedListCalibrated(): boolean | null {
        if (this.speedList && this.speedList.length > 0) {
            if (this.speedList[0].activityId) {
                return true
            }
            else {
                return false
            }
        }
        else {
            return null
        }
    }

    @computed get speeds(): TimeSeries {
        if (this.speedList && this.speedList.length > 0) {
            let data = this.speedList.map((r) => {
                return [
                    moment.utc(r.gsensorDate!).valueOf(),
                    r.speed!
                ]
            })
            let ts = new TimeSeries({
                name: "speed",
                columns: ["time", "speed"],
                points: data
            })
            return ts
        }
        else {
            return new TimeSeries()
        }
    }

    @computed get gxs(): TimeSeries {
        if (this.speedList && this.speedList.length > 0) {
            return new TimeSeries({
                name: "gx",
                columns: ["time", "gx"],
                points: this.speedList.map((r) => {
                    return [
                        moment.utc(r.gsensorDate!).valueOf(),
                        r.x!
                    ]
                })
            })
        }
        else {
            return new TimeSeries()
        }
    }

    @computed get gys(): TimeSeries {
        if (this.speedList && this.speedList.length > 0) {
            return new TimeSeries({
                name: "gy",
                columns: ["time", "gy"],
                points: this.speedList.map((r) => {
                    return [
                        moment.utc(r.gsensorDate!).valueOf(),
                        r.y!
                    ]
                })
            })
        }
        else {
            return new TimeSeries()
        }
    }

    @computed get gzs(): TimeSeries {
        if (this.speedList && this.speedList.length > 0) {
            return new TimeSeries({
                name: "gz",
                columns: ["time", "gz"],
                points: this.speedList.map((r) => {
                    return [
                        moment.utc(r.gsensorDate!).valueOf(),
                        r.z!
                    ]
                })
            })
        }
        else {
            return new TimeSeries()
        }
    }

    @computed get selectedDriverModels(): DriverModel[] {
        if (this.drivers && this.drivers.length > 0) {
            // @ts-ignore
            return this.drivers.filter(d => d.selected)
        }
        else {
            return []
        }
    }

    @computed get selectedDrivers(): Driver[] {
        if (this.drivers && this.drivers.length > 0) {
            // @ts-ignore
            return this.drivers.filter(d => this.currentDriverIds.includes(d.id))
        }
        else {
            return []
        }
    }

    @action getDriver(id): Driver | null {

        let result = this.drivers.filter(d => d.id === id)
        return result.length === 1 ? result[0] : null
    }

    @action getCustomer(id): CustomerModel | null {

        let result = this.customers.filter(d => d.customerId === id)
        return result.length === 1 ? result[0] : null
    }

    @action getDepartment(id): DepartmentModel | null {

        let result = this.departments.filter(d => d.departmentId === id)
        return result.length === 1 ? result[0] : null
    }

    @action getDriverName(id): string {

        let result = this.getDriver(id)
        return result ? result.name : "Bilinmeyen"
    }

    @action getDriverCustomer(id): CustomerModel | null {

        let result = this.getDriver(id)
        if (!result || !result.customerId) {
            return null
        }
        let customer = this.getCustomer(result.customerId)
        if (!customer) {
            return null
        }
        return customer
    }

    @action getDriverDepartment(id): DepartmentModel | null {

        let result = this.getDriver(id)
        if (!result || !result.departmentId) {
            return null
        }
        let customer = this.getDepartment(result.departmentId)
        if (!customer) {
            return null
        }
        return customer
    }

    @computed get driverCount() {
        return this.drivers.filter(d => d.selected).length
    }
}


export default UiStore
