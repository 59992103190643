import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { IBaseProps } from "../../types"
import moment from "../../utils/moment"
import ReactTable from "react-table"
import "react-table/react-table.css"
import actionTypeToString from "../../utils/actionTypeToString"
import { withTranslation } from 'react-i18next'
import pagination from '../../components/pagination'
import durationToString from "../../utils/durationToString"
import round from "lodash.round"
import { reaction } from 'mobx'
import Loading from '../../components/Loading'
import LaddaButton, { ZOOM_IN } from 'react-ladda'
import { Button, Alert } from 'reactstrap'
import compose from '../../utils/compose'
import PdfReportModal from '../../components/PdfReportModal/PdfReportModal'
import { ReportFormats } from '../../api2'




class ActionList extends Component<IBaseProps & { disableReaction: boolean, driverId: number[] | undefined }> {

    static defaultProps = {
        disableReaction: false
    }
    reaction

    constructor(props: any) {
        super(props)
        const store = this.props.store!
        if (!this.props.disableReaction) {
            this.reaction = reaction(
                () => [store.ui.datePicker.asJson, store.ui.currentDriverIds],
                () => {
                    this.props.store!.report.getActionList()
                }, { fireImmediately: true, delay: 100 }
            )
        }
    }
    componentWillUnmount() {
        if (!this.props.disableReaction) {
            this.reaction()
        }
    }

    handleLocationClick = (rowInfo) => {
        const store = this.props.store!
        const violation = rowInfo.original
        const activityId = violation.activityId


        store.ui.mapZoom = 24
        store.modal.toggleViolationModal("location", activityId, violation)

    }

    handleDataClick = (rowInfo) => {
        const store = this.props.store!
        const violation = rowInfo.original
        const activityId = violation.activityId

        store.ui.mapZoom = 24
        store.modal.toggleViolationModal("chart", activityId, violation)

    }

    renderList() {
        const t = this.props.t!
        const store = this.props.store!
        return <ReactTable
            data={store.report.actionList}
            columns={[
                {
                    Header: "id",
                    accessor: "driverId",
                    filterable: false,
                    show: false
                },
                {
                    Header: t("driver"),
                    accessor: "driverName",
                    sortMethod: (a: string, b: string) => {
                        return a.localeCompare(b)
                    },
                    filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                            return true
                        }
                        return row.driverId === parseInt(filter.value, 0)
                    },
                    Filter: ({ filter, onChange }) =>
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                        >
                            <option value="all">Tümü</option>
                            {Array.from(new Set(store.report.actionList.map(obj => obj.driverId))).map(a => <option key={a} value={a}>{store.ui.getDriverName(a)}</option>)}


                        </select>
                },
                {
                    Header: t("date"),
                    id: "startDateTime",
                    accessor: "startDateTime",
                    className: "text-center",
                    Cell: ({ value }) => moment.utc(value).local().format("LL LTS"),
                    filterable: false
                },

                {
                    Header: t("violationPenalty"),
                    id: "penalty",
                    accessor: (a) => a.penalty,
                    Cell: ({ value }) => round(value, 2),
                    className: "text-center",
                    filterable: false
                },
                {
                    Header: t("violationSpeed"),
                    id: "description",
                    accessor: (a) => a.description,
                    className: "text-center",
                    filterable: false
                },
                {
                    Header: t("duration"),
                    id: "duration",
                    accessor: (a) => moment(a.endDateTime!).diff(moment(a.startDateTime!)),
                    className: "text-center",
                    Cell: ({ value }) => durationToString(value),

                    filterable: false
                },
                {
                    Header: t("violationType"),
                    accessor: "type",
                    Cell: ({ value }) => t(actionTypeToString(value)),
                    className: "text-center",
                    id: "type",
                    filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                            return true
                        }
                        return row[filter.id] === parseInt(filter.value, 0)
                    },
                    Filter: ({ filter, onChange }) =>
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                        >
                            <option value="all">Tümü</option>
                            <option value="1">{t(actionTypeToString(1))}</option>
                            <option value="2">{t(actionTypeToString(2))}</option>
                            <option value="3">{t(actionTypeToString(3))}</option>
                            <option value="4">{t(actionTypeToString(4))}</option>
                            <option value="5">{t(actionTypeToString(5))}</option>

                        </select>

                },
                {
                    Header: t("location"),
                    id: "location",
                    accessor: (a) => [a.lat, a.lon],
                    maxWidth: 60,
                    Cell: (value) => <div className="d-flex justify-content-center"><Button style={{ padding: "1px 6px" }} onClick={() => this.handleLocationClick(value)} disabled={!value.value[0] || !value.value[1]}><i className="fa fa-map-marker" aria-hidden="true" /></Button></div>,
                    filterable: false
                },
                {
                    Header: t("data"),
                    id: "data",
                    accessor: "actionId",
                    maxWidth: 60,
                    Cell: (value) => value.row.type !== 5 && <div className="d-flex justify-content-center"><Button style={{ padding: "1px 6px" }} onClick={() => this.handleDataClick(value)}><i className="fa fa-bar-chart" aria-hidden="true" /></Button></div>,
                    filterable: false
                },

            ]}
            filterable
            defaultSorted={
                [
                    { id: "startDateTime", desc: false },
                ]}
            defaultPageSize={15}
            className="-striped -highlight"
            PaginationComponent={pagination}

        />
    }

    render() {
        const t = this.props.t!
        const store = this.props.store!
        return <Loading ready={store.report.actionListReady} icon="fa-table" list={store.report.actionList} emptyPromt="noDataInDateInterval" height="70vh">
            <div className="col-md-10  col-sm-12 mb-2">
                <Alert color="primary">
                    {t("violationDesc")}
                </Alert>
            </div>
            <div className="col-md-2  col-sm-12 mb-2">
                <Button
                    className="float-right"
                    onClick={
                        () => {
                            if (store.report.actionList.length > 500) {
                                store.modal.togglePdfReportModal()
                            } else {
                                store.report.downloadReport2("violationDetailReport", ReportFormats.PDF, this.props.driverId ?? store.ui.currentDriverIds, store.ui.datePicker.start, store.ui.datePicker.end)
                            }
                        }
                    }><i className="fa fa-file-pdf-o" aria-hidden="true" /></Button>

                <LaddaButton
                    loading={store.report.excelLoading}
                    className="btn btn-secondary float-right mr-2"
                    data-style={ZOOM_IN}
                    data-spinner-color="#151b1e"
                    onClick={() => {
                        store.report.downloadReport2("violationDetailReport", ReportFormats.EXCEL, this.props.driverId ?? store.ui.currentDriverIds, store.ui.datePicker.start, store.ui.datePicker.end)
                    }}><i className="fa fa-file-excel-o" aria-hidden="true" /></LaddaButton>
            </div>
            <div className="col-12 mb-2">
                {this.renderList()}
            </div>
            {store.report.vtab === "details" && <PdfReportModal key="violationDetailsReport" reportType="violationDetailsReport" />}
        </Loading>
    }



}

export default compose(
    inject("store"),
    withTranslation("main"),
    observer,
)(ActionList)