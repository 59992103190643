import React, { Component } from 'react'

class SidebarFooter extends Component {

    render() {
        return (
            <div className="sidebar-footer d-flex flex-column ml-auto mr-auto" style={{ background: "none" }}>
                <span><div className="footer-logo" /></span>
                <span className="text-center">Versiyon: {process.env.REACT_APP_VERSION}/{process.env.REACT_APP_SV}</span>
            </div>
        )
    }
}

export default SidebarFooter
