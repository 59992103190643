/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface ActionListDTO
 */
export interface ActionListDTO {
    /**
     * 
     * @type {number}
     * @memberof ActionListDTO
     */
    actionId?: number;
    /**
     * 
     * @type {number}
     * @memberof ActionListDTO
     */
    activityId?: number;
    /**
     * 
     * @type {Date}
     * @memberof ActionListDTO
     */
    createdAt?: moment.Moment;
    /**
     * 
     * @type {string}
     * @memberof ActionListDTO
     */
    descriptionI?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActionListDTO
     */
    driverId?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionListDTO
     */
    driverName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ActionListDTO
     */
    startDateTime?: moment.Moment;
    /**
     * 
     * @type {Date}
     * @memberof ActionListDTO
     */
    endDateTime?: moment.Moment;
    /**
     * 
     * @type {number}
     * @memberof ActionListDTO
     */
    lat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionListDTO
     */
    lon?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionListDTO
     */
    penalty?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionListDTO
     */
    magnitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionListDTO
     */
    type?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionListDTO
     */
    threshold?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionListDTO
     */
    unitId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionListDTO
     */
    violationValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionListDTO
     */
    time?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionListDTO
     */
    readonly description?: string | null;
}

export function ActionListDTOFromJSON(json: any): ActionListDTO {
    return ActionListDTOFromJSONTyped(json, false);
}

export function ActionListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionListDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionId': !exists(json, 'actionId') ? undefined : json['actionId'],
        'activityId': !exists(json, 'activityId') ? undefined : json['activityId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : moment(json['createdAt']),
        'descriptionI': !exists(json, '_DescriptionI') ? undefined : json['_DescriptionI'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : moment(json['startDateTime']),
        'endDateTime': !exists(json, 'endDateTime') ? undefined : moment(json['endDateTime']),
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'penalty': !exists(json, 'penalty') ? undefined : json['penalty'],
        'magnitude': !exists(json, 'magnitude') ? undefined : json['magnitude'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'threshold': !exists(json, 'threshold') ? undefined : json['threshold'],
        'unitId': !exists(json, 'unitId') ? undefined : json['unitId'],
        'violationValue': !exists(json, 'violationValue') ? undefined : json['violationValue'],
        'time': !exists(json, 'time') ? undefined : json['time'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ActionListDTOToJSON(value?: ActionListDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionId': value.actionId,
        'activityId': value.activityId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        '_DescriptionI': value.descriptionI,
        'driverId': value.driverId,
        'driverName': value.driverName,
        'startDateTime': value.startDateTime === undefined ? undefined : (value.startDateTime.toISOString()),
        'endDateTime': value.endDateTime === undefined ? undefined : (value.endDateTime.toISOString()),
        'lat': value.lat,
        'lon': value.lon,
        'penalty': value.penalty,
        'magnitude': value.magnitude,
        'type': value.type,
        'threshold': value.threshold,
        'unitId': value.unitId,
        'violationValue': value.violationValue,
        'time': value.time,
    };
}


