/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface WorstDriverLeagueGeneralDTO
 */
export interface WorstDriverLeagueGeneralDTO {
    /**
     * 
     * @type {number}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    ecoGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    safetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    totalGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    distance?: number;
    /**
     * 
     * @type {number}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    activityId?: number;
    /**
     * 
     * @type {string}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    ecoGradeTrendRate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    totalGradeTrendRate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    safetyGradeTrendRate?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    startDateTime?: moment.Moment;
    /**
     * 
     * @type {string}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    driverName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    customerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    deparmentName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    customerId?: number;
    /**
     * 
     * @type {number}
     * @memberof WorstDriverLeagueGeneralDTO
     */
    deparmentId?: number | null;
}

export function WorstDriverLeagueGeneralDTOFromJSON(json: any): WorstDriverLeagueGeneralDTO {
    return WorstDriverLeagueGeneralDTOFromJSONTyped(json, false);
}

export function WorstDriverLeagueGeneralDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorstDriverLeagueGeneralDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ecoGrade': !exists(json, 'ecoGrade') ? undefined : json['ecoGrade'],
        'safetyGrade': !exists(json, 'safetyGrade') ? undefined : json['safetyGrade'],
        'totalGrade': !exists(json, 'totalGrade') ? undefined : json['totalGrade'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'activityId': !exists(json, 'activityId') ? undefined : json['activityId'],
        'ecoGradeTrendRate': !exists(json, 'ecoGradeTrendRate') ? undefined : json['ecoGradeTrendRate'],
        'totalGradeTrendRate': !exists(json, 'totalGradeTrendRate') ? undefined : json['totalGradeTrendRate'],
        'safetyGradeTrendRate': !exists(json, 'safetyGradeTrendRate') ? undefined : json['safetyGradeTrendRate'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : moment(json['startDateTime']),
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'deparmentName': !exists(json, 'deparmentName') ? undefined : json['deparmentName'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'deparmentId': !exists(json, 'deparmentId') ? undefined : json['deparmentId'],
    };
}

export function WorstDriverLeagueGeneralDTOToJSON(value?: WorstDriverLeagueGeneralDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ecoGrade': value.ecoGrade,
        'safetyGrade': value.safetyGrade,
        'totalGrade': value.totalGrade,
        'distance': value.distance,
        'duration': value.duration,
        'driverId': value.driverId,
        'activityId': value.activityId,
        'ecoGradeTrendRate': value.ecoGradeTrendRate,
        'totalGradeTrendRate': value.totalGradeTrendRate,
        'safetyGradeTrendRate': value.safetyGradeTrendRate,
        'startDateTime': value.startDateTime === undefined ? undefined : (value.startDateTime.toISOString()),
        'driverName': value.driverName,
        'customerName': value.customerName,
        'deparmentName': value.deparmentName,
        'customerId': value.customerId,
        'deparmentId': value.deparmentId,
    };
}


