import DriverModel from "./DriverModel"
import { computed, action, observable } from "mobx"
import { Department } from "../../api"
import RootStore from "../RootStore"

class DepartmentModel {
    customerId: number
    departmentId: number
    name: string
    drivers: DriverModel[] = []
    @observable isOpen = false
    rootStore: RootStore

    constructor(customer: Department, rootStore: RootStore) {
        this.rootStore = rootStore
        this.customerId = parseInt(customer.customerId!, 10)
        this.departmentId = parseInt(customer.departmentId!, 10)
        this.name = customer.name!
    }

    @computed get isFullySelected() {
        if (!this.drivers || this.drivers.length === 0) {
            return false
        }
        else {
            return this.drivers.every((driver) => {
                if (driver.selected) {
                    return true
                }
                return false
            })
        }
    }

    @computed get isPartiallySelected() {
        if (!this.drivers || this.drivers.length === 0) {
            return false
        }
        else {
            return this.drivers.some((driver) => {
                if (driver.selected) {
                    return true
                }
                return false
            })
        }
    }

    @computed get visiable() {
        if (!this.drivers || this.drivers.length === 0) {
            return false
        }
        else {
            return this.drivers.some((driver) => {
                if (driver.visiable) {
                    return true
                }
                return false
            })
        }
    }


    @action toggleSelected = () => {
        const val = !this.isFullySelected
        this.drivers.forEach(d => { if (d.visiable) { d.setSelected(val) } })
    }

    @action _setSelected = (val) => {
        this.drivers.forEach(d => { if (d.visiable) { d.setSelected(val) } })
    }
    @action toggleOpen = () => {
        this.isOpen = !this.isOpen
    }
}

export default DepartmentModel