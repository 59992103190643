import React, { Component } from 'react'
import { Badge, Nav, NavItem, NavLink } from 'reactstrap'
import classNames from 'classnames'
import nav from './_nav'
import SidebarFooter from './../SidebarFooter/SidebarFooter'
import SidebarForm from './../SidebarForm/SidebarForm'
import SidebarMinimizer from './../SidebarMinimizer/SidebarMinimizer'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { IBaseProps } from "../../types"
import { Link, RouterState } from 'mobx-state-router'

interface IBadge {
    variant: 'info',
    text: string,
    class: string

}

class Sidebar extends Component<IBaseProps, any> {

    constructor(props: IBaseProps) {
        super(props)

        this.handleClick = this.handleClick.bind(this)
        this.activeRoute = this.activeRoute.bind(this)
        this.hideMobile = this.hideMobile.bind(this)
    }


    handleClick(e) {
        e.preventDefault()
        e.target.parentElement.classList.toggle('open')
    }

    activeRoute(routeName: string, props: any) {
        // return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
        return props.store.routing.routerState.routeName.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown'

    }

    activeRoute2(routeName: string, props: any) {
        // return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
        return props.store.routing.routerState.routeName === routeName ? 'active' : ''

    }

    hideMobile() {
        if (document.body.classList.contains('sidebar-mobile-show')) {
            document.body.classList.toggle('sidebar-mobile-show')
        }
    }

    // todo Sidebar nav secondLevel
    // secondLevelActive(routeName) {
    //   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
    // }

    trans(text: string) {
        if (text[0] === "$") {
            const t = this.props.t!
            return t(text.substring(1))
        }
        else {
            return text
        }
    }

    render() {
        const store = this.props.store!
        const props = this.props

        // badge addon to NavItem
        const badge = (badge: IBadge) => {
            if (badge) {
                const classes = classNames(badge.class)
                return (<Badge className={classes} color={badge.variant}>{this.trans(badge.text)}</Badge>)
            }
            else { return "" }
        }

        // simple wrapper for nav-title item
        const wrapper = item => (item.wrapper && item.wrapper.element ? (React.createElement(item.wrapper.element, item.wrapper.attributes, this.trans(item.name))) : this.trans(item.name))

        // nav list section title
        const title = (title, key) => {
            const classes = classNames('nav-title', title.class)
            return (<li key={key} className={classes}>{wrapper(title)} </li>)
        }

        // nav list divider
        const divider = (divider, key) => {
            const classes = classNames('divider', divider.class)
            return (<li key={key} className={classes}></li>)
        }

        // nav item with nav link
        const navItem = (item, key) => {
            const classes = {
                item: classNames(item.class),
                link: classNames('nav-link', item.variant ? `nav-link-${item.variant}` : ''),
                icon: classNames(item.icon)
            }
            return (
                navLink(item, key, classes)
            )
        }

        // nav link
        const navLink = (item, key, classes) => {
            const url = item.url ? item.url : ''
            return (
                <NavItem key={key} className={classes.item}>
                    {isExternal(url) ?
                        <NavLink href={url} className={classes.link} active>
                            <i className={classes.icon}></i>{item.name}{badge(item.badge)}
                        </NavLink>
                        :
                        <Link routerStore={store.routing} className={"nav-link " + classes.link + " " + this.activeRoute2(item.routeName, props)} toState={new RouterState(item.routeName, item.params, item.queryParams)} activeClassName="active" >
                            <i className={classes.icon}></i>{item.name}{badge(item.badge)}
                        </Link>
                    }
                </NavItem>
            )
        }

        // nav dropdown
        const navDropdown = (item, key) => {
            return (
                <li key={key} className={this.activeRoute(item.routeName, props)}>
                    <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick}><i className={item.icon}></i>{this.trans(item.name)}</a>
                    <ul className="nav-dropdown-items">
                        {navList(item.children)}
                    </ul>
                </li>)
        }

        // nav type
        const navType = (item, idx) => {
            if (item.superUser && !store.superUser) {
                return null
            }
            if (item.crm && !store.crm) {
                return null
            } else {
                return item.title ? title(item, idx) :
                    item.divider ? divider(item, idx) :
                        item.children ? navDropdown(item, idx)
                            : navItem(item, idx)
            }

        }

        // nav list
        const navList = (items) => {
            return items.map((item, index) => navType(item, index))
        }

        const isExternal = (url: string) => {
            const link = url ? url.substring(0, 4) : ''
            return link === 'http'
        }

        const itemsWithParams = nav.items.filter(t => t !== undefined).map((item) => {
            return Object.assign(item, {
                queryParams: {
                    /*d: store.ui.currentDriverIds,*/
                    date: JSON.stringify(store.ui.datePicker.asJson)
                }
            })
        })

        // sidebar-nav root
        return (
            <div className="sidebar">

                <SidebarForm />
                <nav className="sidebar-nav">
                    <Nav>
                        {navList(itemsWithParams)}
                    </Nav>
                </nav>
                <SidebarFooter />
                <SidebarMinimizer />
            </div>
        )
    }
}

export default inject("store")(withTranslation("main")(observer(Sidebar)))
