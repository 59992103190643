import i18n from 'i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import main_tr from "./locales/tr/main.json"

i18n
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            tr: {
                main: main_tr
            }
        },
        fallbackLng: 'tr',
        // have a common namespace used around the full app
        ns: ['main'],
        defaultNS: 'main',
        whitelist: ['tr', 'en'],
        debug: process.env.NODE_ENV !== 'production',
        lng: "tr",
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        returnObjects: true,
        load: 'currentOnly',
    })

export default i18n
