/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface GetRouteByActivityIdDTO
 */
export interface GetRouteByActivityIdDTO {
    /**
     * 
     * @type {number}
     * @memberof GetRouteByActivityIdDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRouteByActivityIdDTO
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRouteByActivityIdDTO
     */
    lon?: number;
    /**
     * 
     * @type {Date}
     * @memberof GetRouteByActivityIdDTO
     */
    dataDate?: moment.Moment;
    /**
     * 
     * @type {number}
     * @memberof GetRouteByActivityIdDTO
     */
    speed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetRouteByActivityIdDTO
     */
    unitId?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRouteByActivityIdDTO
     */
    driverId?: number | null;
}

export function GetRouteByActivityIdDTOFromJSON(json: any): GetRouteByActivityIdDTO {
    return GetRouteByActivityIdDTOFromJSONTyped(json, false);
}

export function GetRouteByActivityIdDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRouteByActivityIdDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'dataDate': !exists(json, 'dataDate') ? undefined : moment(json['dataDate']),
        'speed': !exists(json, 'speed') ? undefined : json['speed'],
        'unitId': !exists(json, 'unitId') ? undefined : json['unitId'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
    };
}

export function GetRouteByActivityIdDTOToJSON(value?: GetRouteByActivityIdDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lat': value.lat,
        'lon': value.lon,
        'dataDate': value.dataDate === undefined ? undefined : (value.dataDate.toISOString()),
        'speed': value.speed,
        'unitId': value.unitId,
        'driverId': value.driverId,
    };
}


