/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface DriverRiskLeagueEcoDTO
 */
export interface DriverRiskLeagueEcoDTO {
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueEcoDTO
     */
    ecoGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueEcoDTO
     */
    accelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueEcoDTO
     */
    brakingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueEcoDTO
     */
    idlingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueEcoDTO
     */
    speedGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueEcoDTO
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueEcoDTO
     */
    activityId?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverRiskLeagueEcoDTO
     */
    ecoGradeTrendRate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueEcoDTO
     */
    distance?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueEcoDTO
     */
    duration?: number;
    /**
     * 
     * @type {Date}
     * @memberof DriverRiskLeagueEcoDTO
     */
    startDateTime?: moment.Moment;
    /**
     * 
     * @type {string}
     * @memberof DriverRiskLeagueEcoDTO
     */
    driverName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueEcoDTO
     */
    totalDistance?: number;
}

export function DriverRiskLeagueEcoDTOFromJSON(json: any): DriverRiskLeagueEcoDTO {
    return DriverRiskLeagueEcoDTOFromJSONTyped(json, false);
}

export function DriverRiskLeagueEcoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverRiskLeagueEcoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ecoGrade': !exists(json, 'ecoGrade') ? undefined : json['ecoGrade'],
        'accelGrade': !exists(json, 'accelGrade') ? undefined : json['accelGrade'],
        'brakingGrade': !exists(json, 'brakingGrade') ? undefined : json['brakingGrade'],
        'idlingGrade': !exists(json, 'idlingGrade') ? undefined : json['idlingGrade'],
        'speedGrade': !exists(json, 'speedGrade') ? undefined : json['speedGrade'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'activityId': !exists(json, 'activityId') ? undefined : json['activityId'],
        'ecoGradeTrendRate': !exists(json, 'ecoGradeTrendRate') ? undefined : json['ecoGradeTrendRate'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : moment(json['startDateTime']),
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'totalDistance': !exists(json, 'totalDistance') ? undefined : json['totalDistance'],
    };
}

export function DriverRiskLeagueEcoDTOToJSON(value?: DriverRiskLeagueEcoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ecoGrade': value.ecoGrade,
        'accelGrade': value.accelGrade,
        'brakingGrade': value.brakingGrade,
        'idlingGrade': value.idlingGrade,
        'speedGrade': value.speedGrade,
        'driverId': value.driverId,
        'activityId': value.activityId,
        'ecoGradeTrendRate': value.ecoGradeTrendRate,
        'distance': value.distance,
        'duration': value.duration,
        'startDateTime': value.startDateTime === undefined ? undefined : (value.startDateTime.toISOString()),
        'driverName': value.driverName,
        'totalDistance': value.totalDistance,
    };
}


