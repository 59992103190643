/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface DriverLeagueSafetyDTO
 */
export interface DriverLeagueSafetyDTO {
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueSafetyDTO
     */
    safetyGradeTrendRate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyDTO
     */
    safetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyDTO
     */
    suddenAccelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyDTO
     */
    suddenBrakeGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyDTO
     */
    speedingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyDTO
     */
    drowsinessGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyDTO
     */
    hardTurnGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyDTO
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyDTO
     */
    totalDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyDTO
     */
    totalDistance?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueSafetyDTO
     */
    driverName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DriverLeagueSafetyDTO
     */
    unitId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DriverLeagueSafetyDTO
     */
    isSingleUnit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DriverLeagueSafetyDTO
     */
    unitPlate?: string | null;
}

export function DriverLeagueSafetyDTOFromJSON(json: any): DriverLeagueSafetyDTO {
    return DriverLeagueSafetyDTOFromJSONTyped(json, false);
}

export function DriverLeagueSafetyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverLeagueSafetyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'safetyGradeTrendRate': !exists(json, 'safetyGradeTrendRate') ? undefined : json['safetyGradeTrendRate'],
        'safetyGrade': !exists(json, 'safetyGrade') ? undefined : json['safetyGrade'],
        'suddenAccelGrade': !exists(json, 'suddenAccelGrade') ? undefined : json['suddenAccelGrade'],
        'suddenBrakeGrade': !exists(json, 'suddenBrakeGrade') ? undefined : json['suddenBrakeGrade'],
        'speedingGrade': !exists(json, 'speedingGrade') ? undefined : json['speedingGrade'],
        'drowsinessGrade': !exists(json, 'drowsinessGrade') ? undefined : json['drowsinessGrade'],
        'hardTurnGrade': !exists(json, 'hardTurnGrade') ? undefined : json['hardTurnGrade'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'totalDuration': !exists(json, 'totalDuration') ? undefined : json['totalDuration'],
        'totalDistance': !exists(json, 'totalDistance') ? undefined : json['totalDistance'],
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'unitId': !exists(json, 'unitId') ? undefined : json['unitId'],
        'isSingleUnit': !exists(json, 'isSingleUnit') ? undefined : json['isSingleUnit'],
        'unitPlate': !exists(json, 'unitPlate') ? undefined : json['unitPlate'],
    };
}

export function DriverLeagueSafetyDTOToJSON(value?: DriverLeagueSafetyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'safetyGradeTrendRate': value.safetyGradeTrendRate,
        'safetyGrade': value.safetyGrade,
        'suddenAccelGrade': value.suddenAccelGrade,
        'suddenBrakeGrade': value.suddenBrakeGrade,
        'speedingGrade': value.speedingGrade,
        'drowsinessGrade': value.drowsinessGrade,
        'hardTurnGrade': value.hardTurnGrade,
        'driverId': value.driverId,
        'totalDuration': value.totalDuration,
        'totalDistance': value.totalDistance,
        'driverName': value.driverName,
        'unitId': value.unitId,
        'isSingleUnit': value.isSingleUnit,
        'unitPlate': value.unitPlate,
    };
}


