import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { IBaseProps } from '../../../types'
import config from '../../../config'
import { observable } from 'mobx'

class LoginRedirect extends Component<IBaseProps> {
    @observable time = 11
    timer: any
    componentDidMount() {
        this.countdown()
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }


    countdown = () => {
        this.time--
        if (this.time > 0) {
            this.timer = setTimeout(this.countdown, 1000)
        } else {
            // @ts-ignore
            window.location = config.login_redirect
        }

    }


    render() {
        return (<>
            <h1>{this.time} </h1><small>sn sonra otomatik yönlendirileceksiniz</small>
        </>
        )
    }
}

export default inject("store")(observer(LoginRedirect))
