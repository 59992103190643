import {
    observable, action,
} from 'mobx'
import RootStore from "./RootStore"
import { Action } from "../api"


class ModalStore {
    rootStore: RootStore

    @observable isDatePickerModalVisible: boolean = false

    @observable isGradeModalVisible: boolean = false

    @observable isActionListModalVisible: boolean = false
    @observable actionListModalDriverId: number | null = null


    @observable isViolationModalVisible: boolean = false

    @observable isPdfReportModalVisible: boolean = false

    @observable isExcelReportModalVisible: boolean = false


    @observable violationModalMode: "location" | "chart" = "location"


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action toggleDatePickerModal = () => {
        this.isDatePickerModalVisible = !this.isDatePickerModalVisible
    }

    @action toggleGradeModal = () => {
        this.isGradeModalVisible = !this.isGradeModalVisible
    }

    @action togglePdfReportModal = () => {
        this.isPdfReportModalVisible = !this.isPdfReportModalVisible
    }

    @action toggleExcelReportModal = () => {
        this.isExcelReportModalVisible = !this.isExcelReportModalVisible
    }

    @action toggleActionListModal = () => {
        this.isActionListModalVisible = !this.isActionListModalVisible

    }

    @action toggleViolationModal = (mode?: "location" | "chart", activityId?: number, action?: Action) => {
        this.isViolationModalVisible = !this.isViolationModalVisible
        if (mode && action) {
            if (this.rootStore.ui.tempActivityId !== activityId) {
                // this.rootStore.ui.clearActivity()
                this.rootStore.ui.getRouteList(activityId!)
                this.rootStore.ui.getSpeedList(activityId!)
                this.rootStore.ui.tempActivityId = activityId!
            }
            this.rootStore.ui.mapZoom = 24
            this.rootStore.ui.mapFocusedAction = action!
            this.violationModalMode = mode
        }
    }


}

export default ModalStore