

export default function actionTypeToString(type) {

    if (type === 1) {
        return "suddenAcceleration"
    } else if (type === 2) {
        return "suddenBrake"
    } else if (type === 3) {
        return "hardLeft"
    } else if (type === 4) {
        return "hardRight"
    } else if (type === 5) {
        return "speeding"
    } else if (type === 6) {
        return "idling"
    } else {
        return "unkown"
    }

}