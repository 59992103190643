/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    FleetTrendDTO,
    FleetTrendDTOFromJSON,
    FleetTrendDTOFromJSONTyped,
    FleetTrendDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface FleetTrendResponseDTO
 */
export interface FleetTrendResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof FleetTrendResponseDTO
     */
    ecoGradeTrendRate?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetTrendResponseDTO
     */
    safetyGradeTrendRate?: number;
    /**
     * 
     * @type {number}
     * @memberof FleetTrendResponseDTO
     */
    totalGradeTrendRate?: number;
    /**
     * 
     * @type {string}
     * @memberof FleetTrendResponseDTO
     */
    interval?: string | null;
    /**
     * 
     * @type {Array<FleetTrendDTO>}
     * @memberof FleetTrendResponseDTO
     */
    trendList?: Array<FleetTrendDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof FleetTrendResponseDTO
     */
    driverCount?: number;
}

export function FleetTrendResponseDTOFromJSON(json: any): FleetTrendResponseDTO {
    return FleetTrendResponseDTOFromJSONTyped(json, false);
}

export function FleetTrendResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FleetTrendResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ecoGradeTrendRate': !exists(json, 'ecoGradeTrendRate') ? undefined : json['ecoGradeTrendRate'],
        'safetyGradeTrendRate': !exists(json, 'safetyGradeTrendRate') ? undefined : json['safetyGradeTrendRate'],
        'totalGradeTrendRate': !exists(json, 'totalGradeTrendRate') ? undefined : json['totalGradeTrendRate'],
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
        'trendList': !exists(json, 'trendList') ? undefined : (json['trendList'] === null ? null : (json['trendList'] as Array<any>).map(FleetTrendDTOFromJSON)),
        'driverCount': !exists(json, 'driverCount') ? undefined : json['driverCount'],
    };
}

export function FleetTrendResponseDTOToJSON(value?: FleetTrendResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ecoGradeTrendRate': value.ecoGradeTrendRate,
        'safetyGradeTrendRate': value.safetyGradeTrendRate,
        'totalGradeTrendRate': value.totalGradeTrendRate,
        'interval': value.interval,
        'trendList': value.trendList === undefined ? undefined : (value.trendList === null ? null : (value.trendList as Array<any>).map(FleetTrendDTOToJSON)),
        'driverCount': value.driverCount,
    };
}


