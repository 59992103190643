import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import { IBaseProps } from "../../types"

import {

    ModalHeader,
    ModalBody,
    Button,
} from 'reactstrap'
import DoughnutGrades from "../../components/Chart/DoughnutGrades"
import BarGrades from "../../components/Chart/BarGrades"
import Modal from '../Modal'

class GradeModal extends Component<IBaseProps> {
    goToGradeReport = (reportKey) => {
        const store = this.props.store!
        let routeName = ""
        let type: number[] = []
        switch (reportKey) {
            case "drowsinessGrade":
                routeName = "safetyGradeReport"
                break
            case "speedingGrade":
                routeName = ""
                type = [5]
                break
            case "suddenAccelGrade":
                routeName = ""
                type = [1]
                break
            case "suddenBrakeGrade":
                routeName = ""
                type = [2]
                break
            case "hardTurnGrade":
                routeName = ""
                type = [4, 3]
                break

            case "accelGrade":
            case "brakingGrade":
            case "idlingGrade":
            case "speedGrade":
                routeName = "ecoGradeReport"
                break
            case "ALL":
                routeName = ""
                type = [4, 3, 1, 2, 5]
                break
            default:
                return
        }
        if (routeName) {

            let queryParams = Object.assign({ tab: reportKey }, store.routing.routerState.queryParams) as any
            this.props.store!.routing.goTo(routeName, store.routing.routerState.params, queryParams)
            this.props.store!.modal.toggleGradeModal()
        } else if (type.length > 0) {
            store.report.openActionListModalByActivity(this.props.store!.ui.currentActivity!.activityId, type)
        }
    }

    render() {
        const t = this.props.t!
        const store = this.props.store!
        const toggle = store.modal.toggleGradeModal
        const activity = this.props.store!.ui.currentActivity!
        return (

            <Modal
                isOpen={store.modal.isGradeModalVisible}
                toggle={toggle}
                size="xl"
            >


                <ModalHeader toggle={toggle}>
                    {t("score")}
                </ModalHeader>
                <ModalBody>
                    {activity &&
                        <div className="row align-items-center m-3">
                            <div className="col-12 mb-3">
                                <div className="float-right">

                                    <Button
                                        className="mr-2"
                                        onClick={
                                            () => { this.goToGradeReport("ALL") }
                                        }
                                    >{t("violationReport")}</Button>
                                </div>
                            </div>
                            <div className="col">
                                <DoughnutGrades avgGrade={(activity.safetyGrade! + activity.ecoGrade!) / 2} avgSafetyGrade={activity.safetyGrade!} avgEcoGrade={activity.ecoGrade!} type="general" />
                            </div>
                            <div className="col">
                                <BarGrades
                                    avgDrowsinessGrade={activity.drowsinessGrade!}
                                    avgSpeedingGrade={activity.speedingGrade!}
                                    avgSuddenAccelGrade={activity.suddenAccelGrade!}
                                    avgSuddenBrakeGrade={activity.suddenBrakeGrade!}
                                    avgHardTurnGrade={activity.hardTurnGrade!}
                                    avgAccelGrade={activity.accelGrade!}
                                    avgBrakingGrade={activity.brakingGrade!}
                                    avgIdlingGrade={activity.idlingGrade!}
                                    avgSpeedGrade={activity.speedGrade!}
                                    goToGradeReport={this.goToGradeReport}
                                />
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal >
        )
    }
}

export default inject("store")(withTranslation("main")(observer(GradeModal)))