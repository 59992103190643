import React from 'react'
import moment from '../../utils/moment'
import omit from 'lodash/omit'
import { DateRangePicker, DateRangePickerPhrases } from 'react-dates'
import { START_DATE, END_DATE, HORIZONTAL_ORIENTATION, ANCHOR_LEFT } from 'react-dates/constants'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

interface IProps {
    autoFocus: boolean,
    autoFocusEndDate: boolean,
    stateDateWrapper: (date: moment.Moment) => moment.Moment,
    onChange: (start: moment.Moment, end: moment.Moment) => void
    initialStartDate: moment.Moment,
    initialEndDate: moment.Moment,
}

class DateRangePickerWrapper extends React.Component<IProps, any> {
    static defaultProps = {
        // example props for the demo
        autoFocus: false,
        autoFocusEndDate: false,
        initialStartDate: null,
        initialEndDate: null,

        // input related props
        startDateId: START_DATE,
        startDatePlaceholderText: 'Start Date',
        endDateId: END_DATE,
        endDatePlaceholderText: 'End Date',
        disabled: false,
        required: false,
        screenReaderInputMessage: '',
        showClearDates: false,
        showDefaultInputIcon: false,
        customInputIcon: null,
        customArrowIcon: null,
        customCloseIcon: null,
        block: false,
        small: false,
        regular: false,

        // calendar presentation and interaction related props
        renderMonthText: null,
        orientation: HORIZONTAL_ORIENTATION,
        anchorDirection: ANCHOR_LEFT,
        horizontalMargin: 0,
        withPortal: false,
        withFullScreenPortal: false,
        initialVisibleMonth: null,
        numberOfMonths: 2,
        keepOpenOnDateSelect: false,
        reopenPickerOnClearDates: false,
        isRTL: false,

        // navigation related props
        navPrev: null,
        navNext: null,
        onPrevMonthClick() { /* */ },
        onNextMonthClick() { /* */ },
        onClose() { /* */ },

        // day presentation and interaction related props
        renderCalendarDay: undefined,
        renderDayContents: null,
        minimumNights: 0,
        enableOutsideDays: false,
        isDayBlocked: () => false,
        isOutsideRange: () => false,
        isDayHighlighted: () => false,

        // internationalization
        displayFormat: () => moment.localeData().longDateFormat('L'),
        monthFormat: 'MMMM YYYY',
        phrases: DateRangePickerPhrases,
        onChange: () => null,
        stateDateWrapper: (date) => date,
    }

    constructor(props) {
        super(props)

        let focusedInput = null
        if (props.autoFocus) {
            focusedInput = START_DATE
        } else if (props.autoFocusEndDate) {
            focusedInput = END_DATE
        }

        this.state = {
            focusedInput,
            startDate: props.initialStartDate.add(6, 'hour'),
            endDate: props.initialEndDate,
        }

        this.onDatesChange = this.onDatesChange.bind(this)
        this.onFocusChange = this.onFocusChange.bind(this)
    }

    onDatesChange({ startDate, endDate }) {
        const { stateDateWrapper, onChange } = this.props
        try {
            onChange(stateDateWrapper(moment(startDate).local().startOf('day')), stateDateWrapper(moment(endDate).local().endOf('day')))
        }
        catch{
            /* */
        }
        this.setState({
            startDate: startDate && stateDateWrapper(startDate),
            endDate: endDate && stateDateWrapper(endDate),
        })
    }

    onFocusChange(focusedInput) {
        this.setState({ focusedInput })
    }

    render() {
        const { focusedInput, startDate, endDate } = this.state

        // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
        // example wrapper but are not props on the SingleDatePicker itself and
        // thus, have to be omitted.
        const props = omit(this.props, [
            'autoFocus',
            'autoFocusEndDate',
            'initialStartDate',
            'initialEndDate',
            'stateDateWrapper',
            'onChange'
        ])

        return (
            <div>
                <DateRangePicker
                    {...props}
                    onDatesChange={this.onDatesChange}
                    onFocusChange={this.onFocusChange}
                    focusedInput={focusedInput}
                    startDate={startDate}
                    endDate={endDate}
                />
            </div>
        )
    }
}


export default DateRangePickerWrapper