/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface GradeTotalDTO
 */
export interface GradeTotalDTO {
    /**
     * 
     * @type {string}
     * @memberof GradeTotalDTO
     */
    customerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GradeTotalDTO
     */
    departmentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GradeTotalDTO
     */
    driverName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GradeTotalDTO
     */
    avgGrade?: number;
}

export function GradeTotalDTOFromJSON(json: any): GradeTotalDTO {
    return GradeTotalDTOFromJSONTyped(json, false);
}

export function GradeTotalDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GradeTotalDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'departmentName': !exists(json, 'departmentName') ? undefined : json['departmentName'],
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'avgGrade': !exists(json, 'avgGrade') ? undefined : json['avgGrade'],
    };
}

export function GradeTotalDTOToJSON(value?: GradeTotalDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerName': value.customerName,
        'departmentName': value.departmentName,
        'driverName': value.driverName,
        'avgGrade': value.avgGrade,
    };
}


