import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { IBaseProps } from "../../types"

import {
    Modal,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap'
import { action } from 'mobx'
import { RouterState } from 'mobx-state-router'
import Datepicker from "../Header/Datepicker"

class DatePickerModal extends Component<IBaseProps> {



    @action submit = () => {
        const store = this.props.store!
        let oldParams = Object.assign({}, store.routing.routerState.queryParams)
        // @ts-ignore
        oldParams.date = JSON.stringify(store.ui.tempDatePicker.asJson)

        let toState: RouterState = new RouterState(store.routing.getCurrentRoute().name, store.routing.routerState.params, oldParams)
        store.routing.goTo(toState)

        store.modal.toggleDatePickerModal()
    }



    render() {
        const t = this.props.t!
        const store = this.props.store!
        const toggle = store.modal.toggleDatePickerModal
        return (
            <Modal
                isOpen={store.modal.isDatePickerModalVisible}
                toggle={toggle}
                size="md"
                className="mr-5"
            >
                <ModalBody >
                    <div className="col-12">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <span className="h5">Tarih Seçimi</span>
                            <Datepicker />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="driver-picker-footer">
                    <Button color="primary" onClick={this.submit}>{t("choose")}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>{t("cancel")}</Button>
                </ModalFooter>
            </Modal >
        )
    }
}

export default inject("store")(withTranslation("main")(observer(DatePickerModal)))