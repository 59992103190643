/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface DriverRiskLeagueSafetyDTO
 */
export interface DriverRiskLeagueSafetyDTO {
    /**
     * 
     * @type {string}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    safetyGradeTrendRate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    safetyGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    suddenAccelGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    suddenBrakeGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    speedingGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    drowsinessGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    hardTurnGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    activityId?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    distance?: number;
    /**
     * 
     * @type {Date}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    startDateTime?: moment.Moment;
    /**
     * 
     * @type {string}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    driverName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DriverRiskLeagueSafetyDTO
     */
    totalDistance?: number;
}

export function DriverRiskLeagueSafetyDTOFromJSON(json: any): DriverRiskLeagueSafetyDTO {
    return DriverRiskLeagueSafetyDTOFromJSONTyped(json, false);
}

export function DriverRiskLeagueSafetyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverRiskLeagueSafetyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'safetyGradeTrendRate': !exists(json, 'safetyGradeTrendRate') ? undefined : json['safetyGradeTrendRate'],
        'safetyGrade': !exists(json, 'safetyGrade') ? undefined : json['safetyGrade'],
        'suddenAccelGrade': !exists(json, 'suddenAccelGrade') ? undefined : json['suddenAccelGrade'],
        'suddenBrakeGrade': !exists(json, 'suddenBrakeGrade') ? undefined : json['suddenBrakeGrade'],
        'speedingGrade': !exists(json, 'speedingGrade') ? undefined : json['speedingGrade'],
        'drowsinessGrade': !exists(json, 'drowsinessGrade') ? undefined : json['drowsinessGrade'],
        'hardTurnGrade': !exists(json, 'hardTurnGrade') ? undefined : json['hardTurnGrade'],
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'activityId': !exists(json, 'activityId') ? undefined : json['activityId'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : moment(json['startDateTime']),
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'totalDistance': !exists(json, 'totalDistance') ? undefined : json['totalDistance'],
    };
}

export function DriverRiskLeagueSafetyDTOToJSON(value?: DriverRiskLeagueSafetyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'safetyGradeTrendRate': value.safetyGradeTrendRate,
        'safetyGrade': value.safetyGrade,
        'suddenAccelGrade': value.suddenAccelGrade,
        'suddenBrakeGrade': value.suddenBrakeGrade,
        'speedingGrade': value.speedingGrade,
        'drowsinessGrade': value.drowsinessGrade,
        'hardTurnGrade': value.hardTurnGrade,
        'driverId': value.driverId,
        'activityId': value.activityId,
        'duration': value.duration,
        'distance': value.distance,
        'startDateTime': value.startDateTime === undefined ? undefined : (value.startDateTime.toISOString()),
        'driverName': value.driverName,
        'totalDistance': value.totalDistance,
    };
}


