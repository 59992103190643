import {
    observable, action, computed,
} from 'mobx'
import RootStore from "./RootStore"
import { Activity, ActivitySummary, CalibrateResult } from "../api"


class ActivityStore {
    rootStore: RootStore
    activityKey: number = 1
    activitySumKey: number = 1
    activityCalibrateKey: number = 1

    @observable activityCalibrateLoading: boolean = false


    @observable activityListReady: boolean = false
    @observable activityList: Activity[] = []

    @observable activitySumListReady: boolean = false
    @observable _activitySumList: ActivitySummary[] = []

    @observable focusedDriver: ActivitySummary

    @observable sortBy = { driver: 1, count: 0, distance: 0, time: 0 } // 0 not 1 asc 2 desc

    @observable driverSearch = ""

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action setSearchText = (e: any) => { this.driverSearch = e.target.value }

    @computed get activitySumList(): ActivitySummary[] {
        return this._activitySumList.filter(a => a.driverName!.toLocaleLowerCase().includes(this.driverSearch.toLocaleLowerCase()))
    }

    @action setSortBy = (key) => {
        let temp = { driver: 0, count: 0, distance: 0, time: 0 }
        temp[key] = this.sortBy[key] === 1 ? 2 : 1
        this.sortBy = temp

        if (key === "driver") {
            if (this.sortBy[key] === 1) {
                this._activitySumList = this.activitySumList.sort((a, b) => {
                    return a.driverName!.localeCompare(b.driverName!)
                })
            } else if (this.sortBy[key] === 2) {
                this._activitySumList = this.activitySumList.sort((a, b) => {
                    return b.driverName!.localeCompare(a.driverName!)
                })
            }
        }

        if (key === "count") {
            if (this.sortBy[key] === 1) {
                this._activitySumList = this.activitySumList.sort((a, b) => {
                    return a.totalActivity! - b.totalActivity!
                })
            } else if (this.sortBy[key] === 2) {
                this._activitySumList = this.activitySumList.sort((a, b) => {
                    return b.totalActivity! - a.totalActivity!
                })
            }
        }

        if (key === "distance") {
            if (this.sortBy[key] === 1) {
                this._activitySumList = this.activitySumList.sort((a, b) => {
                    return a.totalDistance! - b.totalDistance!
                })
            } else if (this.sortBy[key] === 2) {
                this._activitySumList = this.activitySumList.sort((a, b) => {
                    return b.totalDistance! - a.totalDistance!
                })
            }
        }

        if (key === "time") {
            if (this.sortBy[key] === 1) {
                this._activitySumList = this.activitySumList.sort((a, b) => {
                    return a.totalTime! - b.totalTime!
                })
            } else if (this.sortBy[key] === 2) {
                this._activitySumList = this.activitySumList.sort((a, b) => {
                    return b.totalTime! - a.totalTime!
                })
            }
        }
    }

    @action setFocusDriver = (index: ActivitySummary) => {
        if (this.focusedDriver !== index) {
            this.focusedDriver = index

            this.getActivityList({ driverIds: [index.driverId!] })
        }

    }


    @action setFocusActivity = (activityId: number) => {

        this.rootStore.api2.getActivity({ id: activityId }).then((activity) => {
            // @ts-ignore
            this.rootStore.ui.setActivity(activity)
        })
            .catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))
    }


    @action clearFocusDriver = () => {
        // @ts-ignore
        this.activityList.clear()
        // @ts-ignore
        this.focusedDriver = undefined
        this.rootStore.ui.clearActivity()
    }

    @action getActivityList = (params: { unitId?: number, driverIds: number[] }) => {
        this.activityKey++
        let key = this.activityKey
        let start
        let end
        if (this.rootStore.ui.datePicker) {
            start = this.rootStore.ui.datePicker.start.utc().format("YYYY-MM-DD HH:mm:ss")
            end = this.rootStore.ui.datePicker.end.utc().format("YYYY-MM-DD HH:mm:ss")

        }
        this.rootStore.api.listActivity(params.unitId, params.driverIds, start, end).then(action("listActivity", (data: Activity[]) => {
            if (key === this.activityKey) {
                // @ts-ignore
                this.activityList.clear()
                this.activityList = data ? data : []// .sort((a: Location, b: Location) => moment.utc(a.dataDate!).getTime() - moment.utc(b.dataDate!).getTime())
                this.activityListReady = true
            }
        })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))

    }

    @action getActivitySumList = () => {
        this.activitySumKey++
        let key = this.activitySumKey
        let start
        let end
        if (this.rootStore.ui.datePicker) {
            start = this.rootStore.ui.datePicker.start.utc().format("YYYY-MM-DD HH:mm:ss")
            end = this.rootStore.ui.datePicker.end.utc().format("YYYY-MM-DD HH:mm:ss")

        }

        this.rootStore.api.listActivitySummary(undefined, this.rootStore.ui.currentDriverIds, start, end).then(action("listActivitySummary", (data: ActivitySummary[]) => {
            if (key === this.activitySumKey) {
                // @ts-ignore
                this._activitySumList.clear()
                this._activitySumList = data ? data : []
                this.activitySumListReady = true
            }
        })).catch(response => { this.rootStore.check401(response) }).catch(response => console.error(response))




    }

    @action calibrateActivity = (activityId) => {
        this.activityCalibrateKey++
        let key = this.activityCalibrateKey
        this.activityCalibrateLoading = true
        this.rootStore.api.calibrate(activityId).then(action("calibrateActivity", (response: CalibrateResult) => {
            if (key === this.activityCalibrateKey && response) {
                this.rootStore.ui.getSpeedList(activityId)
            }
            this.activityCalibrateLoading = false
        })).catch(response => { this.activityCalibrateLoading = false; this.rootStore.check401(response) }).catch(response => console.error(response))
    }


}

export default ActivityStore