import moment from "./moment"


const durationToString = (diffVar) => {
    // parse diffVar if it's a string.
    const diff = Number.parseFloat(diffVar)
    if (Number.isNaN(diff)) {
        return "-"
    }

    const duration = moment.duration(diff)
    const hours = duration.asHours()
    const hStr = Math.floor(hours) > 9 ? "" + Math.floor(hours) : "0" + Math.floor(hours)
    const minutes = duration.minutes()
    const mStr = minutes > 9 ? "" + minutes : "0" + minutes
    const seconds = duration.seconds()
    const sStr = seconds > 9 ? "" + seconds : "0" + seconds
    return hStr + ":" + mStr + ":" + sStr
}
export default durationToString