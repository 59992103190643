/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    DriverTrendDTO,
    DriverTrendDTOFromJSON,
    DriverTrendDTOFromJSONTyped,
    DriverTrendDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface DriverTrendResponseDTO
 */
export interface DriverTrendResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof DriverTrendResponseDTO
     */
    ecoGradeTrendRate?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverTrendResponseDTO
     */
    safetyGradeTrendRate?: number;
    /**
     * 
     * @type {number}
     * @memberof DriverTrendResponseDTO
     */
    totalGradeTrendRate?: number;
    /**
     * 
     * @type {string}
     * @memberof DriverTrendResponseDTO
     */
    interval?: string | null;
    /**
     * 
     * @type {Array<DriverTrendDTO>}
     * @memberof DriverTrendResponseDTO
     */
    trendList?: Array<DriverTrendDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof DriverTrendResponseDTO
     */
    driverId?: number;
}

export function DriverTrendResponseDTOFromJSON(json: any): DriverTrendResponseDTO {
    return DriverTrendResponseDTOFromJSONTyped(json, false);
}

export function DriverTrendResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverTrendResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ecoGradeTrendRate': !exists(json, 'ecoGradeTrendRate') ? undefined : json['ecoGradeTrendRate'],
        'safetyGradeTrendRate': !exists(json, 'safetyGradeTrendRate') ? undefined : json['safetyGradeTrendRate'],
        'totalGradeTrendRate': !exists(json, 'totalGradeTrendRate') ? undefined : json['totalGradeTrendRate'],
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
        'trendList': !exists(json, 'trendList') ? undefined : (json['trendList'] === null ? null : (json['trendList'] as Array<any>).map(DriverTrendDTOFromJSON)),
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
    };
}

export function DriverTrendResponseDTOToJSON(value?: DriverTrendResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ecoGradeTrendRate': value.ecoGradeTrendRate,
        'safetyGradeTrendRate': value.safetyGradeTrendRate,
        'totalGradeTrendRate': value.totalGradeTrendRate,
        'interval': value.interval,
        'trendList': value.trendList === undefined ? undefined : (value.trendList === null ? null : (value.trendList as Array<any>).map(DriverTrendDTOToJSON)),
        'driverId': value.driverId,
    };
}


