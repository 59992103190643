import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { IBaseProps } from "../../types"
import { Doughnut } from 'react-chartjs-2'
import {
    Row
} from 'reactstrap'
import colors from '../../utils/colors'

interface IDoughnutGradeProps extends IBaseProps {
    avgGrade: number,
    avgSafetyGrade: number,
    avgEcoGrade: number,
    goTo?: (string) => void,
    type: "general" | "eco" | "safety"
}

class DoughnutGrades extends Component<IDoughnutGradeProps> {



    render() {
        const t = this.props.t!
        const { avgGrade, avgSafetyGrade, avgEcoGrade, type } = this.props
        let goTo = this.props.goTo ? this.props.goTo : undefined


        let d1Data = [avgGrade, 10 - avgGrade]
        let d1Color = colors.blue
        let d1HoverText = avgGrade.toFixed(1)
        let d1Text = t("avgScore")

        let d2Data = [avgSafetyGrade, 10 - avgSafetyGrade]
        let d2Color = '#4dbd74'
        let d2HoverText = avgSafetyGrade.toFixed(1)
        let d2Text = t("safetyGrade")

        let d3Data = [avgEcoGrade, 10 - avgEcoGrade]
        let d3Color = '#ffc107'
        let d3HoverText = avgEcoGrade.toFixed(1)
        let d3Text = t("ecoGrade")

        if (type === "eco") {
            d3Data = [avgGrade, 10 - avgGrade]
            d3Color = colors.blue
            d3HoverText = avgGrade.toFixed(1)
            d3Text = t("avgScore")

            d1Data = [avgEcoGrade, 10 - avgEcoGrade]
            d1Color = '#ffc107'
            d1HoverText = avgEcoGrade.toFixed(1)
            d1Text = t("ecoGrade")
        }

        if (type === "safety") {
            d2Data = [avgGrade, 10 - avgGrade]
            d2Color = colors.blue
            d2HoverText = avgGrade.toFixed(1)
            d2Text = t("avgScore")

            d1Data = [avgSafetyGrade, 10 - avgSafetyGrade]
            d1Color = '#4dbd74'
            d1HoverText = avgSafetyGrade.toFixed(1)
            d1Text = t("safetyGrade")
        }
        return (
            <React.Fragment>
                <Row>
                    <div className={"col-12 d-flex flex-column align-items-center" + (goTo ? " clickable" : "")} onClick={goTo ? () => goTo!("speedGrade") : undefined}>
                        <Doughnut key={avgGrade + "avg"}
                            legend={{ display: false }}
                            data={{
                                datasets: [{
                                    data: d1Data,
                                    backgroundColor: [
                                        d1Color,
                                        'rgba(0, 0, 0, 0.1)',
                                    ],
                                    hoverBackgroundColor: [
                                        d1Color,
                                        'rgba(0, 0, 0, 0.1)',
                                    ]
                                }]
                            }}
                            options={{
                                tooltips: { enabled: false },
                                elements: {
                                    // @ts-ignore
                                    center: {
                                        text: d1HoverText,
                                        color: '#151b1e', // Default black
                                        fontStyle: 'Helvetica', // Default Arial
                                        sidePadding: 30 // Default 20 (as a percentage)
                                    }
                                }
                            }} />
                        <span style={{ textAlign: "center" }}>{d1Text}</span>
                    </div>
                </Row>
                <Row>
                    <div className="col-6 d-flex flex-column align-items-center p-0">
                        <Doughnut key={avgSafetyGrade + "safety"}
                            legend={{ display: false }}
                            data={{
                                datasets: [{
                                    data: d2Data,
                                    backgroundColor: [
                                        d2Color,
                                        'rgba(0, 0, 0, 0.1)',
                                    ],
                                    hoverBackgroundColor: [
                                        d2Color,
                                        'rgba(0, 0, 0, 0.1)',
                                    ]
                                }]
                            }}
                            options={{
                                tooltips: { enabled: false },
                                elements: {
                                    // @ts-ignore
                                    center: {
                                        text: d2HoverText,
                                        color: '#151b1e', // Default black
                                        fontStyle: 'Helvetica', // Default Arial
                                        sidePadding: 30 // Default 20 (as a percentage)
                                    }
                                }
                            }} />
                        <span style={{ textAlign: "center" }}>{d2Text}</span>
                    </div>
                    <div className="col-6 d-flex flex-column align-items-center p-0">
                        <Doughnut key={avgEcoGrade + "eco"}
                            legend={{ display: false }}
                            data={{
                                datasets: [{
                                    data: d3Data,
                                    backgroundColor: [
                                        d3Color,
                                        'rgba(0, 0, 0, 0.1)',
                                    ],
                                    hoverBackgroundColor: [
                                        d3Color,
                                        'rgba(0, 0, 0, 0.1)',
                                    ]
                                }]
                            }}
                            options={{
                                tooltips: { enabled: false },
                                elements: {
                                    // @ts-ignore
                                    center: {
                                        text: d3HoverText,
                                        color: '#151b1e', // Default black
                                        fontStyle: 'Helvetica', // Default Arial
                                        sidePadding: 30 // Default 20 (as a percentage)
                                    }
                                }
                            }} />
                        <span style={{ textAlign: "center" }}>{d3Text}</span>
                    </div>
                </Row>

            </React.Fragment>
        )
    }

}
export default withTranslation("main")(observer(DoughnutGrades))