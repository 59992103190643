import { ReportSchedule } from "../../api/api"
import { observable } from "mobx"



class ReportScheduleModel implements ReportSchedule {
    @observable id: number = -1
    @observable name: string = ""
    @observable title: string = ""
    @observable recipients: string = ""
    @observable interval: string = "monthly"
    @observable date: number = 1
    @observable format: string = "excel"
    @observable departmentIds: number[] = []
    @observable types: string[] = []
    @observable customerIds: number[] = []
    @observable customerId: number = -1
    @observable customerName: string = ""

    constructor(json?: any) {
        if (json) {
            if (json.customerId) {
                this.customerId = json.customerId
            }
            if (json.customerName) {
                this.customerName = json.customerName
            }
            if (json.id) {
                this.id = json.id
            }
            if (json.name) {
                this.name = json.name
            }
            if (json.title) {
                this.title = json.title
            }
            if (json.recipients) {
                this.recipients = json.recipients
            }
            if (json.interval) {
                this.interval = json.interval
            }
            if (json.date !== undefined) {
                this.date = json.date
            }
            if (json.format) {
                this.format = json.format
            }
            if (json.departmentIds) {
                this.departmentIds = json.departmentIds
            }
            if (json.types) {
                this.types = json.types
            }
            if (json.customerIds) {
                this.customerIds = json.customerIds
            }
        }
    }
}


export default ReportScheduleModel