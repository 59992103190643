/* tslint:disable */
/* eslint-disable */
/**
 * SDS-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface DriverRankingDTO
 */
export interface DriverRankingDTO {
    /**
     * 
     * @type {string}
     * @memberof DriverRankingDTO
     */
    driverName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DriverRankingDTO
     */
    criteria?: number;
}

export function DriverRankingDTOFromJSON(json: any): DriverRankingDTO {
    return DriverRankingDTOFromJSONTyped(json, false);
}

export function DriverRankingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverRankingDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driverName': !exists(json, 'driverName') ? undefined : json['driverName'],
        'criteria': !exists(json, 'criteria') ? undefined : json['criteria'],
    };
}

export function DriverRankingDTOToJSON(value?: DriverRankingDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driverName': value.driverName,
        'criteria': value.criteria,
    };
}


