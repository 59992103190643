import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { IBaseProps } from "../../types"
import {
    Progress
} from 'reactstrap'
import round from "lodash.round"

interface IBarGradeProps extends IBaseProps {
    avgDrowsinessGrade: number,
    avgSpeedingGrade: number,
    avgSuddenAccelGrade: number,
    avgSuddenBrakeGrade: number,
    avgHardTurnGrade: number,
    avgAccelGrade: number,
    avgBrakingGrade: number,
    avgIdlingGrade: number,
    avgSpeedGrade: number,

    goToGradeReport?: (string) => void
}

class BarGrades extends Component<IBarGradeProps> {

    goToGradeReport = (reportKey) => {
        let routeName = ""

        switch (reportKey) {
            case "drowsinessGrade":
            case "speedingGrade":
            case "suddenAccelGrade":
            case "suddenBrakeGrade":
            case "hardTurnGrade":
                routeName = "safetyGradeReport"
                break

            case "accelGrade":
            case "brakingGrade":
            case "idlingGrade":
            case "speedGrade":
                routeName = "ecoGradeReport"
                break

            default:
                return
        }
        const store = this.props.store!
        let queryParams = Object.assign({ tab: reportKey }, store.routing.routerState.queryParams) as any
        this.props.store!.routing.goTo(routeName, store.routing.routerState.params, queryParams)
    }

    render() {
        const t = this.props.t!
        const { avgDrowsinessGrade,
            avgSpeedingGrade,
            avgSuddenAccelGrade,
            avgSuddenBrakeGrade,
            avgHardTurnGrade,
            avgAccelGrade,
            avgBrakingGrade,
            avgIdlingGrade,
            avgSpeedGrade } = this.props

        let goToGradeReport = this.props.goToGradeReport ? this.props.goToGradeReport : this.goToGradeReport

        return (

            <div className="container">
                <div className="row ">
                    <div className="col-auto vertical-text">{t("safetyGrade")}</div>
                    <div className="col p-0">
                        <ul className="horizontal-bars type-2">
                            <li className={"clickable"} onClick={() => goToGradeReport("drowsinessGrade")}>
                                <i className="fa fa-fw fa-bed"></i>
                                <span className="title">{t("tiredness")}</span>
                                <span className="value">{round(avgDrowsinessGrade, 1)}</span>
                                <div className="bars">
                                    <Progress className="progress-xs" color="success" value={avgDrowsinessGrade * 10} />
                                </div>
                            </li>
                            <li className={"clickable"} onClick={() => goToGradeReport("speedingGrade")}>
                                <i className="fa fa-fw fa-exclamation-triangle" aria-hidden="true"></i>
                                <span className="title">{t("speedLimit")}</span>
                                <span className="value">{round(avgSpeedingGrade, 1)}</span>
                                <div className="bars">
                                    <Progress className="progress-xs" color="success" value={avgSpeedingGrade * 10} />
                                </div>
                            </li>
                            <li className={"clickable"} onClick={() => goToGradeReport("suddenAccelGrade")}>
                                <i className="fa fa-fw fa-rocket" aria-hidden="true"></i>
                                <span className="title">{t("suddenAcceleration")}</span>
                                <span className="value">{round(avgSuddenAccelGrade, 1)}</span>
                                <div className="bars">
                                    <Progress className="progress-xs" color="success" value={avgSuddenAccelGrade * 10} />
                                </div>
                            </li>
                            <li className={"clickable"} onClick={() => goToGradeReport("suddenBrakeGrade")}>
                                <i className="fa fa-fw fa-hand-paper-o" aria-hidden="true"></i>
                                <span className="title">{t("suddenBrake")}</span>
                                <span className="value">{round(avgSuddenBrakeGrade, 1)}</span>
                                <div className="bars">
                                    <Progress className="progress-xs" color="success" value={avgSuddenBrakeGrade * 10} />
                                </div>
                            </li>
                            <li className={"clickable"} onClick={() => goToGradeReport("hardTurnGrade")}>
                                <i className="fa fa-fw fa-refresh" aria-hidden="true"></i>
                                <span className="title">{t("hardTurn")}</span>
                                <span className="value">{round(avgHardTurnGrade, 1)}</span>
                                <div className="bars">
                                    <Progress className="progress-xs" color="success" value={avgHardTurnGrade * 10} />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row " style={{ marginBottom: 16 }}></div>
                <div className="row ">
                    <div className="col-auto vertical-text">{t("ecoGrade")}</div>
                    <div className="col p-0">

                        <ul className="horizontal-bars type-2">
                            <li className={"clickable"} onClick={() => goToGradeReport("accelGrade")}>
                                <i className="fa fa-fw fa-tachometer" aria-hidden="true"></i>
                                <span className="title">{t("acceleration")}</span>
                                <span className="value">{round(avgAccelGrade, 1)}</span>
                                <div className="bars">
                                    <Progress className="progress-xs" color="warning" value={avgAccelGrade * 10} />
                                </div>
                            </li>
                            <li className={"clickable"} onClick={() => goToGradeReport("brakingGrade")}>
                                <i className="fa fa-fw fa-hand-paper-o" aria-hidden="true"></i>
                                <span className="title">{t("braking")}</span>
                                <span className="value">{round(avgBrakingGrade, 1)}</span>
                                <div className="bars">
                                    <Progress className="progress-xs" color="warning" value={avgBrakingGrade * 10} />
                                </div>
                            </li>
                            <li className={"clickable"} onClick={() => goToGradeReport("idlingGrade")}>
                                <i className="fa fa-fw fa-hourglass-end" aria-hidden="true"></i>
                                <span className="title">{t("idling")}</span>
                                <span className="value">{round(avgIdlingGrade, 1)}</span>
                                <div className="bars">
                                    <Progress className="progress-xs" color="warning" value={avgIdlingGrade * 10} />
                                </div>
                            </li>
                            <li className={"clickable"} onClick={() => goToGradeReport("speedGrade")}>
                                <i className="fa fa-fw fa-tachometer" aria-hidden="true"></i>
                                <span className="title">{t("optSpeed")}</span>
                                <span className="value">{round(avgSpeedGrade, 1)}</span>
                                <div className="bars">
                                    <Progress className="progress-xs" color="warning" value={avgSpeedGrade * 10} />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        )
    }

}
export default inject("store")(withTranslation("main")(observer(BarGrades)))