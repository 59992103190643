import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { observable, action, computed } from "mobx"
import { Col, Row } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { IBaseProps } from "../../types"
import { Charts, ChartContainer, ChartRow, YAxis, LineChart, Resizable, styler, Legend, ScatterChart, TimeRangeMarker } from "react-timeseries-charts"
import { TimeSeries, TimeRange } from "pondjs"
import { format } from "d3-format"
import { timeFormatDefaultLocale } from "d3-time-format"
import locale from "d3-time-format/locale/tr-TR.json"
import moment from "../../utils/moment"
import { ActionData, Action } from '../../api'
import Loading from '../Loading'
import compose from '../../utils/compose'

//@ts-ignore
timeFormatDefaultLocale(locale)

interface IDataChartProps extends IBaseProps {
    action: Action
}

class DataChart extends Component<IDataChartProps, {}> {

    @observable timerange: TimeRange = null
    @observable minTime: Date | undefined = undefined
    @observable maxTime: Date | undefined = undefined
    @observable tracker: Date | undefined = undefined

    @observable dataList: ActionData[] = []
    @observable dataReady = false

    @action getDataList = () => {
        const store = this.props.store!
        store.api.getActionData(this.props.action.actionId as unknown as string).then(action("getActionData",
            (data: ActionData[]) => {
                this.dataList = data
                this.dataReady = true
            })).catch(response => { store.check401(response) }).catch(response => console.error(response))
    }

    @action updateTracker = (t: Date) => {
        this.tracker = t
    }
    componentDidMount() {
        this.getDataList()
    }

    componentWillUnmount() {
    }

    @action updateMinTime = (t: Date) => {
        this.minTime = t
    }

    @action updateMaxTime = (t: Date) => {
        this.maxTime = t
    }
    @action updateTimeRange = (tr) => {
        this.timerange = tr
    }

    @action updateRange = (range: TimeSeries) => {
        const store = this.props.store!

        if (range.duration() > store.chart.maxDuration) {
            let newRange = range.setEnd(moment(range.begin()).add(store.chart.maxDuration, "milliseconds").toDate())
            this.updateTimeRange(newRange)
        } else {
            this.updateTimeRange(range)
        }
        this.updateMaxTime(range.end())
        this.updateMinTime(range.begin())
    }


    render() {

        return (
            <Loading ready={this.dataReady} list={this.dataList} icon="fa-line-chart" emptyPromt="noSensorData" renderFunc={this.renderChart} />
        )
    }

    @computed get annotations() {

        const annotations: JSX.Element[] = []

        let action = this.props.action
        if (action.type === 5) { // speedlimit
            annotations.push(
                <TimeRangeMarker
                    id={action.actionId}
                    key={action.actionId}
                    timerange={new TimeRange(moment.utc(action.startDateTime!).valueOf(), moment.utc(action.endDateTime!).valueOf())}
                    style={{ fill: "red", opacity: "0.25" }}
                >
                </TimeRangeMarker>

            )
        } else if (action.type === 1) { // sudden acceleration
            annotations.push(
                <TimeRangeMarker
                    id={action.actionId}
                    key={action.actionId}
                    timerange={new TimeRange(moment.utc(action.startDateTime!).valueOf(), moment.utc(action.endDateTime!).valueOf())}
                    style={{ fill: "orange", opacity: "0.25" }}
                >
                </TimeRangeMarker>

            )
        } else if (action.type === 6) { // idle
            annotations.push(
                <TimeRangeMarker
                    id={action.actionId}
                    key={action.actionId}
                    timerange={new TimeRange(moment.utc(action.startDateTime!).valueOf(), moment.utc(action.endDateTime!).valueOf())}
                    style={{ fill: "black", opacity: "0.25" }}
                >
                </TimeRangeMarker>

            )
        } else if (action.type === 2) { // suddenBrake
            annotations.push(
                <TimeRangeMarker
                    id={action.actionId}
                    key={action.actionId}
                    timerange={new TimeRange(moment.utc(action.startDateTime!).valueOf(), moment.utc(action.endDateTime!).valueOf())}
                    style={{ fill: "black", opacity: "0.25" }}
                >
                </TimeRangeMarker>

            )
        } else if (action.type === 3) { // hardLeft
            annotations.push(
                <TimeRangeMarker
                    id={action.actionId}
                    key={action.actionId}
                    timerange={new TimeRange(moment.utc(action.startDateTime!).valueOf(), moment.utc(action.endDateTime!).valueOf())}
                    style={{ fill: "cyan", opacity: "0.25" }}
                >
                </TimeRangeMarker>

            )
        } else if (action.type === 4) { // hardRight
            annotations.push(
                <TimeRangeMarker
                    id={action.actionId}
                    key={action.actionId}
                    timerange={new TimeRange(moment.utc(action.startDateTime!).valueOf(), moment.utc(action.endDateTime!).valueOf())}
                    style={{ fill: "blue", opacity: "0.25" }}
                >
                </TimeRangeMarker>

            )
        }



        return annotations
    }

    renderChart = () => {
        const t = this.props.t!
        const store = this.props.store!
        const actionData = this.dataList
        let speed = new TimeSeries({
            name: "speed",
            columns: ["time", "speed"],
            points: actionData.map((r) => {
                return [
                    moment.utc(r.readingTime!).toDate(),
                    r.speed!
                ]
            })
        })

        let lateral = new TimeSeries({
            name: "gx",
            columns: ["time", "gx"],
            points: actionData.map((r) => {
                return [
                    moment.utc(r.readingTime!).toDate(),
                    r.lateralGForce!
                ]
            })
        })

        let longitudinal = new TimeSeries({
            name: "gz",
            columns: ["time", "gz"],
            points: actionData.map((r) => {
                return [
                    moment.utc(r.readingTime!).toDate(),
                    r.longitudinalGForce!
                ]
            })
        })
        let axises = {
            speed: { units: "km/s", label: "speed", format: ",.0f", max: speed.max("speed") },
            g: { units: "g", label: "gForce", format: ",.1f", max: 0.5, min: -0.5, right: true }
        }

        let channels = {
            speed: { units: "km/s", label: "speed", format: ",.0f", series: speed, show: true, axis: "speed_axis" },
            gx: { units: "g", label: "gx", format: ",.4f", series: lateral, show: true, axis: "g_axis" },
            gz: { units: "g", label: "gz", format: ",.4f", series: longitudinal, show: true, axis: "g_axis" }
        }
        if (!this.timerange) {
            this.updateRange(speed.range())
        }

        const legend = Object.keys(channels).map((c) => ({ key: c, label: t(c) }))
        /*legend.push({ key: "loc", label: t("loc") })*/



        const style = styler([
            { key: "speed", color: "green", width: 1 },
            { key: "gx", color: "red", width: 1 },
            { key: "gz", color: "blue", width: 1 },
        ])
        // Line charts
        let charts: JSX.Element[] = []
        for (let channelName in channels) {
            if (channels.hasOwnProperty(channelName)) {
                let series = channels[channelName].series
                charts.push(
                    <LineChart
                        key={`line-${channelName}`}
                        axis={channels[channelName].axis}
                        visible={channels[channelName].show}
                        series={series}
                        columns={[channelName]}
                        style={style}
                        breakLine
                    />
                )
                charts.push(
                    <ScatterChart
                        key={`scatter-${channelName}`}
                        axis={channels[channelName].axis}
                        series={series.clean(channelName)}
                        columns={[channelName]}
                        style={style}
                    />
                )
            }
        }
        // Tracker info box
        const trackerInfoValues = Object.keys(channels)
            .filter(channelName => channels[channelName].show)
            .map(channelName => {
                const fmt = format(channels[channelName].format)
                if (channels[channelName].series && channels[channelName].series.size() > 0) {

                    let v = "--"
                    if (this.tracker) {
                        let series = channels[channelName].series.crop(this.timerange)
                        const i = series.bisect(new Date(this.tracker!))

                        const vv = series.at(i) && series.at(i).get(channelName)
                        if (typeof vv === 'number') {
                            v = fmt(vv)
                        }
                    }

                    const label = t(channels[channelName].label)
                    const value = `${v} ${channels[channelName].units}`

                    return { label, value }
                } else {
                    return { label: "-", value: "-" }
                }
            })

        // Axis list
        const axisListleft: JSX.Element[] = []
        const axisListRight: JSX.Element[] = []

        for (let channelName in axises) {
            if (axises.hasOwnProperty(channelName)) {
                const label = axises[channelName].label
                const max = axises[channelName].max
                const min = axises[channelName].min || 0
                const right = axises[channelName].right || false
                const format = axises[channelName].format
                const id = `${channelName}_axis`
                if (right) {
                    axisListRight.push(
                        <YAxis

                            id={id}
                            key={id}
                            label={t(label)}
                            min={min}
                            max={max}
                            width={70}
                            type="linear"
                            format={format}
                        />
                    )
                }
                else {
                    axisListleft.push(
                        <YAxis

                            id={id}
                            key={id}
                            label={t(label)}
                            min={min}
                            max={max}
                            width={70}
                            type="linear"
                            format={format}
                        />
                    )
                }
            }
        }

        return (
            <React.Fragment>
                <Row className="mt-2">
                    <Col sm="11 chart-container" >

                        <Resizable >
                            <ChartContainer
                                timeRange={this.timerange}
                                trackerPosition={this.tracker}
                                onTrackerChanged={this.updateTracker}
                                trackerShowTime
                                enablePanZoom
                                onTimeRangeChanged={this.updateTimeRange}
                                maxTime={this.maxTime}
                                minTime={this.minTime}
                                minDuration={store.chart.minDuration}
                            >
                                <ChartRow
                                    height="270"
                                    trackerInfoValues={trackerInfoValues}
                                    trackerInfoHeight={10 + trackerInfoValues.length * 16}
                                    trackerInfoWidth={140}
                                >
                                    {axisListleft}
                                    <Charts>
                                        {charts}
                                        {this.annotations}
                                    </Charts>
                                    {axisListRight}
                                </ChartRow>

                            </ChartContainer>
                        </Resizable>
                    </Col>
                    <Col sm="1" className="pt-2">
                        <Legend
                            type="line"
                            style={style}
                            categories={legend}
                            stack
                        />
                    </Col>
                </Row>

            </React.Fragment>
        )
    }


}
export default compose(
    inject("store"),
    withTranslation("main"),
    observer,
)(DataChart)