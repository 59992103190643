import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { IBaseProps } from "../../types"
import {
    Card,
    CardHeader,
    Table,
    UncontrolledTooltip
} from 'reactstrap'
import { observable, action } from 'mobx'
import { GradeByDep } from '../../api'
import durationToString from "../../utils/durationToString"
import Loading from '../Loading'
import HelpPopover from '../Help/HelpPopover'


class BestWorstByDep extends Component<IBaseProps & { gradetype: string, type: "best" | "worst", allDrivers?: boolean }> {

    @observable report: GradeByDep[] | null = null
    @observable reportReady: boolean = false

    @action getReport = () => {
        const store = this.props.store!
        const gradetype = this.props.gradetype
        const type = this.props.type

        let start
        let end
        if (store.ui.datePicker) {
            start = store.ui.datePicker.start.utc().format("YYYY-MM-DD HH:mm:ss")
            end = store.ui.datePicker.end.utc().format("YYYY-MM-DD HH:mm:ss")

        }
        if (gradetype === "total") {

            store.api.getGradeTotalByDep(start, end, type).then(action("getGradeTotalByDep",
                (data: GradeByDep[]) => {
                    this.report = data
                    this.reportReady = true
                })).catch(response => { store.check401(response) }).catch(response => console.error(response))


        }

    }

    componentDidMount() {
        this.getReport()

    }

    renderTable = () => {
        const t = this.props.t!
        const store = this.props.store!
        const gradetype = this.props.gradetype
        return (
            <Table hover className="mb-0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th >{t("customer")}</th>
                        <th >{t("department")}</th>

                        <th>{gradetype === "tiredTime" ? t("time") : t("grade")}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.report!.map((d, i) =>
                        <tr key={d.departmentId}>
                            <th scope="row">{i + 1}</th>
                            <td id={"id" + d.departmentId + "customer"} className="text-truncate tableDriverId">{store.ui.getCustomer(d.customerId) ? store.ui.getCustomer(d.customerId)?.customerName : ""}</td>
                            <UncontrolledTooltip placement="bottom" target={"id" + d.departmentId + "customer"}>
                                {store.ui.getCustomer(d.customerId) ? store.ui.getCustomer(d.customerId)?.customerName : ""}
                            </UncontrolledTooltip>
                            <td id={"id" + d.departmentId + "dep"} className="text-truncate tableDriverId">{store.ui.getDepartment(d.departmentId) ? store.ui.getDepartment(d.departmentId)?.name : ""}</td>
                            <UncontrolledTooltip placement="bottom" target={"id" + d.departmentId + "dep"}>
                                {store.ui.getDepartment(d.departmentId) ? store.ui.getDepartment(d.departmentId)?.name : ""}
                            </UncontrolledTooltip>
                            <td>{gradetype === "tiredTime" ? durationToString(d.avgGrade! * 1000) : d.avgGrade}</td>
                        </tr>
                    )}

                </tbody>
            </Table>
        )

    }

    render() {
        const t = this.props.t!
        const type = this.props.type
        const gradetype = this.props.gradetype



        return (
            <Card>
                <CardHeader><span className="h5">{t(gradetype) + ": " + t(type + "departments")}</span>
                    <div className="float-right">

                        <HelpPopover id={gradetype + "-" + type + "bestHelp"}>
                            <p>Seçilen sürücüler arasında {t(gradetype)} en {type === "best" ? (gradetype === "tiredTime" ? "düşük" : "yüksek") : (gradetype === "tiredTime" ? "yüksek" : "düşük")} 5 sürücünün listesidir.</p>
                        </HelpPopover>
                    </div>
                </CardHeader>
                <Loading ready={this.reportReady} icon="fa-table" list={this.report as any[]} object={this.report} emptyPromt="noDataInDateInterval" height="30vh" renderFunc={this.renderTable} />
            </Card>
        )
    }

}
export default inject("store")(withTranslation("main")(observer(BestWorstByDep)))