import React, { Component } from 'react'
import { observer } from 'mobx-react'
import EmptyPrompt from './EmptyPrompt/EmptyPrompt'
import Spinner from './Spinner/Spinner'

class Loading extends Component<{ ready: boolean, list?: any[], object?: any, emptyPromt: string, icon: string, height?: number | string, renderFunc?: any }> {

    render() {
        const { children, ready, emptyPromt, height, icon, list, object, renderFunc } = this.props
        let empty = true
        if (list) {
            empty = list.length < 1
        } else if (object) {
            empty = false
        }
        return <>
            {
                ready
                    ?
                    empty
                        ?
                        <EmptyPrompt prompt={emptyPromt} iconName={icon} height={height} />

                        :
                        <>{renderFunc ? renderFunc() : children}</>

                    :
                    <Spinner height={height} />
            }
        </>
    }
}

export default observer(Loading)